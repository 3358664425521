import React from 'react';
import { LockIcon } from 'assets/svg-icon';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ERoutes } from 'enum/types';

const TablePlug = () => {
  const { t } = useTranslation();
  return (
    <div className='table-body table-body--plug'>
      <div className='plug'>
        <div className='plug__icon'>
          <LockIcon />
        </div>
        <div className='plug__text'>
          <p>
            <Link to={ERoutes.login}>{t('Login.sign_in')}</Link>{' '}
            {t('Global.or')}{' '}
            <Link to={ERoutes.registration}>{t('Registration.register')}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TablePlug;
