import classNames from 'classnames';
import { ERoutes, ETransactionType } from 'enum/types';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  IOrder,
  IUpdateTpSlValues,
} from 'store/redux-toolkit/order/order-types';
import RowChild from './RowChild';

interface IOpenOrderRowProps {
  items: IOrder[];
  handleUpdateTpSl: (order: IOrder, type: ETransactionType) => void;
  updateDateExpiration: (order: IOrder) => void;
}

const PendingOrderRow: FC<IOpenOrderRowProps> = ({
  items,
  handleUpdateTpSl,
  updateDateExpiration,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { asset } = items[0];

  const [openList, setOpenList] = useState(false);
  const handleToggleList = () => {
    setOpenList(!openList);
  };

  const value = items.reduce((total: number, order: IOrder) => {
    order.transaction_type === ETransactionType.buy
      ? (total += order.amount)
      : (total -= order.amount);
    return total;
  }, 0);

  return (
    <div className='tr-wrap'>
      {items.length > 1 && (
        <div
          className={classNames('tr', { active: openList })}
          onClick={handleToggleList}
        >
          <div className='td'>
            <div className='td-hidden-name'>{t('Table.order')}</div>
            <div className='position-info'>
              <p>
                {asset.code}{' '}
                <span className='symbol-info'>{asset.market.name}</span>
              </p>
              {location.pathname === ERoutes.trade && (
                <>
                  <div className='position-info__number'>
                    <p>{items.length}</p>
                  </div>
                </>
              )}
            </div>
            <span className='td-text-small'>
              {asset.name}{' '}
              {asset.market.name.toUpperCase() !== 'FX' && 'to American Dollar'}
            </span>
          </div>
          <div className='td' />
          <div className='td'>
            {/* <div className='td-hidden-name'>{t('Table.volume')}</div>
            <p>{String(value).replace('-', '')}</p> */}
          </div>
        </div>
      )}

      {items.map((el) => (
        <RowChild
          key={el.id}
          item={el}
          child={items.length > 1}
          handleUpdateTpSl={handleUpdateTpSl}
          open={openList}
          updateDateExpiration={updateDateExpiration}
        />
      ))}
    </div>
  );
};

export default PendingOrderRow;
