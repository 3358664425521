import { CloseTableIcon, PencilIcon, PlusIcon } from 'assets/svg-icon';
import classNames from 'classnames';
import { ETransactionType } from 'enum/types';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  cancelOrder,
  deleteTpSlOrder,
  updateDateExpirationOrder,
} from 'store/redux-toolkit/order/order-thunks';
import { IOrder } from 'store/redux-toolkit/order/order-types';
import { addZeros } from 'utils/integers';

interface IRowChild {
  item: IOrder;
  child?: boolean;
  handleUpdateTpSl: (order: IOrder, type: ETransactionType) => void;
  updateDateExpiration: (order: IOrder) => void;
  open: boolean;
}

const RowChild: FC<IRowChild> = ({
  item,
  child,
  handleUpdateTpSl,
  open,
  updateDateExpiration,
}) => {
  const { t } = useTranslation();
  const {
    id,
    asset,
    transaction_type,
    amount,
    trigger_price,
    stop_loss,
    take_profit,
    date_expiration,
  } = item;

  const dispatch = useAppDispatch();
  const { isDemo } = useAppSelector((state) => state.account);
  const { assets } = useAppSelector((state) => state.asset);
  const curAsset = assets?.find((el) => el.id === asset.id);

  const handleUpdateTp = () => {
    handleUpdateTpSl(item, ETransactionType.tp);
  };

  const handleUpdateSl = () => {
    handleUpdateTpSl(item, ETransactionType.sl);
  };

  const handleDeleteSlTp = (type: ETransactionType) => {
    dispatch(
      deleteTpSlOrder({
        is_demo: isDemo,
        main_order_id: id,
        is_delete_sl_order: type === ETransactionType.sl || undefined,
        is_delete_tp_order: type === ETransactionType.tp || undefined,
      })
    );
  };

  const handleCancelOrder = () => {
    dispatch(
      cancelOrder({
        is_demo: isDemo,
        order_id: id,
      })
    );
  };

  const handleUpdateDateExpiration = () => {
    updateDateExpiration(item);
  };

  const handleDeleteDateExpiration = () => {
    dispatch(
      updateDateExpirationOrder({
        is_demo: isDemo,
        main_order_id: id,
      })
    );
  };

  return (
    <div className={classNames('tr', { 'tr-child': child, active: open })}>
      {child ? (
        <div className='td'>
          <span className='td-text-small'>{addZeros(id)}</span>
        </div>
      ) : (
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.order')}</div>
          <p>
            {asset.code}{' '}
            <span className='symbol-info'> {asset.market.name}</span>
          </p>
          <span className='td-text-small'>
            {asset.name}{' '}
            {asset.market.name.toUpperCase() !== 'FX' && 'to American Dollar'}
          </span>
        </div>
      )}

      <div className='td'>
        <div className='td-hidden-name'>{t('Table.type')}</div>
        <p className={classNames(transaction_type)}>
          {t(`Order.${transaction_type}`)}
        </p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.volume')}</div>
        <p>{amount}</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.opening_price')}</div>
        <p>{trigger_price}</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>S/L</div>
        {stop_loss ? (
          <div className='flex flex-m'>
            <button
              type='button'
              className='color--inherit'
              onClick={handleUpdateSl}
            >
              {stop_loss}
            </button>
            <button
              type='button'
              onClick={() => handleDeleteSlTp(ETransactionType.sl)}
            >
              <CloseTableIcon />
            </button>
          </div>
        ) : (
          <button
            type='button'
            className='button button--type-icon'
            onClick={handleUpdateSl}
          >
            <PlusIcon />
          </button>
        )}
      </div>
      <div className='td'>
        <div className='td-hidden-name'>T/P</div>
        {take_profit ? (
          <div className='flex flex-m'>
            <button
              type='button'
              className='color--inherit'
              onClick={handleUpdateTp}
            >
              {take_profit}
            </button>
            <button
              type='button'
              onClick={() => handleDeleteSlTp(ETransactionType.tp)}
            >
              <CloseTableIcon />
            </button>
          </div>
        ) : (
          <button
            type='button'
            className='button button--type-icon'
            onClick={handleUpdateTp}
          >
            <PlusIcon />
          </button>
        )}
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.market_price')}</div>
        <p>
          {transaction_type === ETransactionType.buy
            ? curAsset?.ask
            : curAsset?.bid}
        </p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.expiration')}</div>
        {date_expiration ? (
          <div className='flex flex-m'>
            <div>
              <p>{date_expiration.split(' ')[0]}</p>
              <span className='td-text-small'>
                {date_expiration.split(' ')[1]}
              </span>
            </div>
            <button type='button' onClick={handleUpdateDateExpiration}>
              <PencilIcon />
            </button>
            <button type='button' onClick={handleDeleteDateExpiration}>
              <CloseTableIcon />
            </button>
          </div>
        ) : (
          <button
            type='button'
            className='button button--type-icon'
            onClick={handleUpdateDateExpiration}
          >
            <PlusIcon />
          </button>
        )}
      </div>
      <div className='td td--right'>
        <button
          type='button'
          className='green2 fw-700'
          onClick={handleCancelOrder}
        >
          {t('Button.cancel')}
        </button>
      </div>
    </div>
  );
};

export default RowChild;
