export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function trimRightSlash(string: string) {
  if (string[string.length - 1] !== '/') {
    return string;
  }
  return string.slice(0, -1);
}

export function trimBottomLine(string: string) {
  const newStr = string.replace(/_/g, ' ');
  return newStr;
}

export function shortNotation(
  string: string,
  fromStart: number,
  fromEnd = 0
): string {
  if (string.length <= fromStart + fromEnd) {
    return string;
  }
  const leftPart = string.slice(0, fromStart);
  const rightPart = fromEnd ? string.slice(-fromEnd) : '';

  return `${leftPart}...${rightPart}`;
}
