import React, { FC } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks/redux';
import { ITradeBalanceTransactionItem } from 'store/redux-toolkit/account/account-types';
import classNames from 'classnames';
import { moneyFormat } from 'utils/moneyFormat';

interface ICashTransactionRowProps {
  item: ITradeBalanceTransactionItem;
}

const CashTransactionRow: FC<ICashTransactionRowProps> = ({ item }) => {
  const { id, balance_id, asset_code, type, amount, comment, created_at } =
    item;
  const { t } = useTranslation();
  const { assets } = useAppSelector((state) => state.asset);
  const asset = assets?.find((el) => el.code === asset_code);
  return (
    <div className='tr'>
      <div className='td'>
        <div className='td-hidden-name'>ID</div>
        <p>{id}</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.type')}</div>
        <p>{t(`Select.${type}`)}</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.id_transaction')}</div>
        <p>{balance_id}</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.time')}</div>
        <p>{moment(created_at).format('DD-MM-YYYY')}</p>
        <span className='td-text-small'>
          {moment(created_at).format('HH:mm:ss')}
        </span>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.asset')}</div>
        <p>
          {asset?.code} <span className='symbol-info'>{asset?.market}</span>
        </p>
        <span className='td-text-small'>{asset?.name} to American Dollar</span>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.comment')}</div>
        <p>{comment}</p>
      </div>

      <div className='td td--right'>
        <div className='td-hidden-name'>{t('Table.amount')}</div>
        <p className={classNames({ red: amount < 0, green: amount > 0 })}>
          {moneyFormat(amount)}
        </p>
      </div>
    </div>
  );
};

export default CashTransactionRow;
