import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '../../layouts-element/Footer';
import Header from '../../layouts-element/Header';

const PublicLayout: FC = () => {
  return (
    <div className='wrapper'>
      <Header addClass='public-header' />
      <main>
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

export default PublicLayout;
