import { useEffect } from 'react';
import { isAfter } from 'date-fns';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';

import {
  confirmEmail,
  resetToken,
} from '../../../store/redux-toolkit/auth/auth-thunks';

import { BigCheckIcon } from '../../../assets/svg-icon';
import { ERoutes, LoadingStatus } from '../../../enum/types';
import openedImage from '../../../assets/images/opened.png';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const EmailConfirm = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { success, error, registerData, loadingState } = useAppSelector(
    (store) => store.auth
  );
  const isPending = loadingState === LoadingStatus.LOADING;

  console.log('registerData', registerData);

  const queryPar = queryString.parse(location.search);
  const tokenTime = new Date(Number(queryPar.timestamp) * 1000);
  const currentTime = new Date();

  const handleSendRepeat = () => {
    const { email = '' } = registerData?.user || {};
    dispatch(resetToken({ email }));
  };

  useEffect(() => {
    if (queryPar.token) {
      dispatch(
        confirmEmail({
          token: queryPar.token,
          timestamp: queryPar.timestamp,
        })
      );
    }
  }, [queryPar.token, queryPar.timestamp]);
  return (
    <div className='authorization-section'>
      <div className='authorization-section__container'>
        <div className='authorization'>
          {isPending ? (
            <p>Loading...</p>
          ) : (success && success === 'email-confirm') ||
            (error && error === 'email_already_confirmed') ? (
            <>
              <div className='form-icon form-icon--success'>
                <div className='form-icon__item'>
                  <BigCheckIcon />
                </div>
              </div>
              <p className='authorization__title authorization__title--mt-24 authorization__title--ta-center'>
                {t('Registration.completed')}
              </p>
              <div className='form-submit form-submit--center'>
                <Link
                  to={ERoutes.login}
                  className='button button--size2 button--type3 button--regular'
                >
                  {t('Login.btn')}
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className='email-sent-icon'>
                <img src={openedImage} alt='' />
              </div>
              <p className='email-sent-text'>
                {registerData?.user.email
                  ? t('Confirm.follow_this_link', {
                      email: registerData?.user.email,
                    })
                  : t('Confirm.link_invalid')}
              </p>
              <div className='email-sent-repeat'>
                <button
                  className='email-sent-repeat__button'
                  onClick={handleSendRepeat}
                >
                  Письмо не пришло, отправить еще раз!
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailConfirm;
