import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { ChangeEvent, FC, useCallback } from 'react';
import { moneyFormat } from 'utils/moneyFormat';

interface ICustomInputProps {
  value: string;
  onChange: (e: string) => void;
  label?: string | null;
  placeholder?: string | null;
  onClickMax?: (val: string) => void;
  number?: boolean;
  maxValue?: string;
  textAfter?: string;
  errorMsg?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  addlClass?: string;
  iconRight?: JSX.Element;
}

const CustomInput: FC<ICustomInputProps> = ({
  value: initValue,
  label,
  placeholder,
  onChange,
  number,
  maxValue,
  textAfter,
  errorMsg,
  onBlur,
  onFocus,
  addlClass,
  iconRight,
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = number
        ? e.target.value.replace(/[^\d.]/g, '').replace(/^([^.]*\.)|\./g, '$1')
        : e.target.value;
      onChange(newValue);
    },
    [onChange]
  );

  const handleMaxValue = () => {
    if (maxValue) onChange(Number(maxValue).toFixed(3).slice(0, -1));
  };

  return (
    <div className={`input ${addlClass}`}>
      {label && (
        <div className='input__name'>
          <p>{label}</p>
        </div>
      )}
      <div className='input-wrapper'>
        <input
          className='input-item'
          placeholder={placeholder || ''}
          value={initValue}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {(maxValue || textAfter) && (
          <div className='input-item-info flex flex--gap8 flex-m'>
            {maxValue && (
              <button
                className='button button--size2 button--type-text'
                onClick={handleMaxValue}
              >
                MAX
              </button>
            )}
            {textAfter && <p>USD</p>}
          </div>
        )}
        {iconRight && (
          <div className='input-icon input-icon--right'>{iconRight}</div>
        )}
      </div>
      {errorMsg && <p className='input-notification--error'>{errorMsg}</p>}
      {maxValue && (
        <div className='input__notification input__notification--type3'>
          <p>
            Ваш баланс <span>{moneyFormat(maxValue)} USD</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default CustomInput;
