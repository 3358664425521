import React from 'react';
import { Helmet } from 'react-helmet';
import Deposit from 'components/Deposit';
import { APP_NAME } from 'services/constants/env';

const DepositPage = () => {
  const appTitle = APP_NAME || '';
  return (
    <>
      <Helmet>
        <title>{`${appTitle} | Deposit`}</title>
      </Helmet>
      <Deposit />
    </>
  );
};

export default DepositPage;
