import { Field, Form, Formik } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowListIcon } from '../../../assets/svg-icon';
import { changePassShema } from '../../../form-validations/login-validation';
import PasswordField from '../../../ui/Formik/PasswordField';
import { IChangePassFormProps } from './types';

const ChangePassForm: FC<IChangePassFormProps> = ({ changePassSubmit }) => {
  const { t } = useTranslation();
  return (
    <>
      <p className='authorization__title '>{t('ForgotPassword.title_creat')}</p>
      <div className='authorization__details'>
        <p>{t('ForgotPassword.text_new_passvord')}</p>
      </div>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={changePassShema}
        onSubmit={(values, { setSubmitting }) => {
          changePassSubmit(values);
          setSubmitting(false);
        }}
        validateOnBlur
        enableReinitialize
      >
        {({ isSubmitting, isValid }) => {
          return (
            <Form className='authorization__form'>
              <Field
                name='password'
                component={PasswordField}
                placeholder={t('Forms.ForgotPassword.new_password')}
              />
              <Field
                name='confirmPassword'
                component={PasswordField}
                placeholder={t('Forms.ForgotPassword.confirm_password')}
              />
              <div className='form-submit'>
                <button
                  type='submit'
                  className='button button--size2'
                  disabled={!isValid || isSubmitting}
                >
                  {t('Forms.ForgotPassword.btn_1')}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
      <div className='authorization__password-info'>
        <p>
          <span>
            <ArrowListIcon />
          </span>
          {t('ForgotPassword.valid_list_1')}
        </p>
        <p>
          <span>
            <ArrowListIcon />
          </span>
          {t('ForgotPassword.valid_list_2')}
        </p>
        <p>
          <span>
            <ArrowListIcon />
          </span>
          {t('ForgotPassword.valid_list_4')}
        </p>
      </div>
    </>
  );
};

export default ChangePassForm;
