import React from 'react';
import { useTranslation } from 'react-i18next';
import OrdersHistoryTable from './OrdersHistoryTable';

const OrdersHistory = () => {
  const { t } = useTranslation();
  return (
    <div className='content'>
      <div className='content-inside'>
        <div className='content-title'>
          <p>{t('Page.TradeHistory.orders_history_title')}</p>
        </div>
        <OrdersHistoryTable />
      </div>
    </div>
  );
};

export default OrdersHistory;
