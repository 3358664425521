import { endpoint } from 'services/endpoint';
import requestService from 'services/http/interceptor/request-service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { showError } from 'utils/showError';
import { ISelectedAssetRequest } from './asset-types';
import { updateSelectedAssetState } from '../auth/auth-slice';

export const getMarkets = createAsyncThunk('asset/get-markets', async () => {
  try {
    const response = await requestService.get(
      endpoint.asset.GET_ACTIVE_MARKETS
    );
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const getAsset = createAsyncThunk('asset/get-asset', async () => {
  try {
    const response = await requestService.get(endpoint.asset.GET_ASSETS);
    return response.data;
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const setUserSelectedAsset = createAsyncThunk(
  'asset/set-asset',
  async (params: ISelectedAssetRequest, thunkApi) => {
    try {
      await requestService.put(endpoint.asset.SET_USER_SELECTED_ASSET, params);
      thunkApi.dispatch(updateSelectedAssetState(params.selected_asset));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
