import { ESocketEvent } from './../../../enum/types';
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { ISocketState } from './socket-types';

export const subscriptionsQueue = [];

const initialState: ISocketState = {
  isConnecting: false,
  subscriptionsQueue: [],
  reconnectCount: 0,
};

const socketsSlice = createSlice({
  name: 'sockets',
  initialState,
  reducers: {
    startConnecting: (state) => {
      state.reconnectCount = state.reconnectCount + 1;
    },
    socketSendMessage: (
      state,
      { payload }: PayloadAction<[number, ESocketEvent]>
    ) => {
      if (payload[0] === 5) {
        if (state.isConnecting) {
          state.subscriptionsQueue = [...state.subscriptionsQueue, payload[1]];
        }
        state.isConnecting = true;
      }
    },
    socketRemoveFromQueue: (state, { payload }: PayloadAction<string>) => {
      state.subscriptionsQueue = state.subscriptionsQueue.filter(
        (item) => item !== payload
      );
    },
    soketSubscribeFulfilled: (state) => {
      state.isConnecting = false;
    },
    socketsInitState: () => initialState,
  },
});

export const {
  socketsInitState,
  socketSendMessage,
  startConnecting,
  soketSubscribeFulfilled,
  socketRemoveFromQueue,
} = socketsSlice.actions;

export default socketsSlice.reducer;
