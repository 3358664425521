import {
  showOpenPositions,
  showChartPositions,
  showClosedPositions,
  deleteTpSlPosition,
  updateTpSlPosition,
} from './position-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PositionState,
  IGetClosePositionsResponse,
  IPosition,
  IOpenPositionResponse,
  IChartPositionResponse,
} from './position-types';

const initialState: PositionState = {
  openPositions: null,
  chartPositions: null,
  chartPositionsErr: null,
  chartPositionsStatus: false,
  closePositions: null,
};

export const positionSlice = createSlice({
  name: 'position',
  initialState,
  reducers: {
    updateOpenPositionsList: (
      state,
      { payload }: PayloadAction<IOpenPositionResponse>
    ) => {
      state.openPositions = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        showOpenPositions.fulfilled,
        (state, { payload }: PayloadAction<IOpenPositionResponse>) => {
          state.openPositions = payload;
        }
      )
      .addCase(showChartPositions.pending, (state) => {
        state.chartPositionsStatus = true;
      })

      .addCase(showChartPositions.rejected, (state, err: any) => {
        state.chartPositionsStatus = false;
        state.chartPositionsErr = err;
      })
      .addCase(
        showChartPositions.fulfilled,
        (state, { payload }: PayloadAction<IChartPositionResponse>) => {
          state.chartPositionsStatus = false;
          state.chartPositions = payload;
        }
      )
      .addCase(
        showClosedPositions.fulfilled,
        (state, { payload }: PayloadAction<IGetClosePositionsResponse>) => {
          state.closePositions = payload;
        }
      )
      .addCase(
        updateTpSlPosition.fulfilled,
        (state, { payload }: PayloadAction<IPosition>) => {
          const needState = state.openPositions;
          if (needState) {
            Object.entries(needState).map(([key, value]) => {
              const newArr = value.map((el) =>
                el.id === payload.id ? { ...el, ...payload } : el
              );
              needState[key] = newArr;
            });
          }
          state.openPositions = needState;
        }
      )
      .addCase(
        deleteTpSlPosition.fulfilled,
        (state, { payload }: PayloadAction<IPosition>) => {
          const needState = state.openPositions;
          if (needState) {
            Object.entries(needState).map(([key, value]) => {
              const newArr = value.map((el) =>
                el.id === payload.id ? { ...el, ...payload } : el
              );
              needState[key] = newArr;
            });
          }
          state.openPositions = needState;
        }
      );
  },
});

export const { updateOpenPositionsList } = positionSlice.actions;

export default positionSlice.reducer;
