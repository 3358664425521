import { ChangeEvent, useState } from 'react';
import { boolean } from 'yup';

export default function useInputNum(initialValue: string) {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(
      e.target.value.replace(/[^\d.]/g, '').replace(/^([^.]*\.)|\./g, '$1')
    );
  };

  const clear = () => {
    setValue('');
  };

  return {
    value,
    onChange,
  };
}
