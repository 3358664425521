import { logout } from './../auth/auth-thunks';
import {
  IChangeUserPasswordRequest,
  IToggleNotigicationRequest,
  IKYCRequest,
  IGetOtherSettingsRequest,
} from './settings-types';
import { endpoint } from './../../../services/endpoint';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import requestService from 'services/http/interceptor/request-service';
import { showError } from 'utils/showError';
import { NotificationContainer } from 'ui/notificationContainer';
import { resetAuthState } from '../auth/auth-slice';

export const sendCode = createAsyncThunk('settings/send-code', async () => {
  try {
    await requestService.get(endpoint.settings.SEND_EMAIL_CODE);
  } catch (error) {
    if (isAxiosError(error)) {
      showError(error);
    }
  }
});

export const changeUserPassword = createAsyncThunk(
  'settings/change-password',
  async (payload: IChangeUserPasswordRequest, thunkApi) => {
    try {
      await requestService.put(endpoint.settings.CHANGE_PASSWORD, payload);
      thunkApi.dispatch(logout());
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getNotifications = createAsyncThunk(
  'settings/get-notifications',
  async () => {
    try {
      const response = await requestService.get(
        endpoint.settings.GET_NOTIFICATIONS
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const toggleNotification = createAsyncThunk(
  'settigs/toggle-notification',
  async (params: IToggleNotigicationRequest) => {
    try {
      const response = await requestService.put(
        endpoint.settings.UPDATE_NOTIFICATIONS,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const lockUnlockAllNotifications = createAsyncThunk(
  'settings/lock-unlock-all-notifications',
  async () => {
    try {
      const response = await requestService.put(
        endpoint.settings.LOCK_UNLOCK_AII_NOTIFICATIOS
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const kyc = createAsyncThunk(
  'settings/kyc',
  async (params: FormData) => {
    try {
      const response = await requestService.post(
        endpoint.settings.KYC,
        params,
        true,
        'multipart/form-data'
      );
      NotificationContainer('KYC verified successfully', 'success');
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getSettingBySlug = createAsyncThunk(
  'settings/get-setting-by-slug',
  async (params: IGetOtherSettingsRequest) => {
    try {
      const response = await requestService.get(
        endpoint.settings.GET_SETTING_BY_SLUG,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
