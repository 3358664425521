import {
  getDepositsTransactions,
  getAccountData,
  getWithdrawalTransactions,
  getTradeBalanceTransactions,
  getTradeData,
} from './account-thuks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AccountState,
  IDepositTransactionsResponse,
  IAccountDataResponse,
  IWithdrawalListResponse,
  ITradeBalanceTransactionsResponse,
  ITradeDataResponse,
  IBalance,
} from './account-types';

const initialState: AccountState = {
  isDemo: 0,
  depositList: null,
  withdrawalList: null,
  tradeBalanceTransactions: null,
  accountData: null,
  tradeData: null,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setDemo: (state, { payload }: PayloadAction<0 | 1>) => {
      state.isDemo = payload;
    },
    updateTradeData: (
      state,
      { payload }: PayloadAction<ITradeDataResponse>
    ) => {
      state.tradeData = payload;
    },
    updateBalance: (state, { payload }: PayloadAction<IBalance>) => {
      if (state.accountData) {
        state.accountData.total_balance = payload.balance;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getDepositsTransactions.fulfilled,
        (state, { payload }: PayloadAction<IDepositTransactionsResponse>) => {
          state.depositList = payload;
        }
      )
      .addCase(
        getWithdrawalTransactions.fulfilled,
        (state, { payload }: PayloadAction<IWithdrawalListResponse>) => {
          state.withdrawalList = payload;
        }
      )
      .addCase(
        getAccountData.fulfilled,
        (state, { payload }: PayloadAction<IAccountDataResponse>) => {
          state.accountData = payload;
        }
      )
      .addCase(
        getTradeData.fulfilled,
        (state, { payload }: PayloadAction<ITradeDataResponse>) => {
          state.tradeData = payload;
        }
      )
      .addCase(
        getTradeBalanceTransactions.fulfilled,
        (
          state,
          { payload }: PayloadAction<ITradeBalanceTransactionsResponse>
        ) => {
          state.tradeBalanceTransactions = payload;
        }
      );
  },
});

export const { setDemo, updateTradeData, updateBalance } = accountSlice.actions;
export default accountSlice.reducer;
