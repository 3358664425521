import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ERoutes } from './../../../enum/types';
import {
  IChangePassRequest,
  IEmailConfirmRequest,
  IForgotPasswordRequest,
  IResetTokenRequest,
} from './auth-types';
import { t } from 'i18next';
import { showError } from '../../../utils/showError';

import { ILoginSubmitValue } from '../../../components/Forms/LoginForm/types';

import { IRegisterSubmitValue } from '../../../components/Forms/RegisterForm/types';
import { clearErrors, resetAuthState } from './auth-slice';
import RequestService from '../../../services/http/interceptor/request-service';
import { endpoint } from '../../../services/endpoint';
import { NotificationContainer } from 'ui/notificationContainer';

export const login = createAsyncThunk(
  'auth/login',
  async (payload: ILoginSubmitValue, thunkApi) => {
    try {
      const response = await RequestService.post(endpoint.auth.LOGIN, payload);
      NotificationContainer(
        `${t('Login.notif_success')} ${payload.email}`,
        'success'
      );
      thunkApi.dispatch(clearErrors());
      // thunkApi.dispatch(getSocketToken(response.data.socket_token));
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response?.data.errors[0] !== 'user_not_registered') {
          showError(error);
        }
        return thunkApi.rejectWithValue(error.response?.data.errors[0]);
      }
    }
  }
);

export const registration = createAsyncThunk(
  'auth/registration',
  async (payload: IRegisterSubmitValue, thunkApi) => {
    try {
      const response = await RequestService.post(
        endpoint.auth.REGISTRATION,
        payload
      );
      thunkApi.dispatch(clearErrors());
      window.location.replace(ERoutes.emailConfirm);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const confirmEmail = createAsyncThunk(
  'auth/confirm-email',
  async (payload: IEmailConfirmRequest, thunkApi) => {
    try {
      const response = await RequestService.put(
        endpoint.auth.EMAIL_CONFIRMATION,
        payload
      );
      thunkApi.dispatch(clearErrors());
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        return thunkApi.rejectWithValue(error.response?.data.errors[0]);
      }
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgot-password',
  async (payload: IForgotPasswordRequest) => {
    try {
      const response = await RequestService.post(
        endpoint.auth.PASSWORD_RESET,
        payload
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const changePassword = createAsyncThunk(
  'auth/change-password',
  async (payload: IChangePassRequest) => {
    try {
      await RequestService.put(endpoint.auth.PASSWORD_CHANGE, payload);
      NotificationContainer(
        String(t('ForgotPassword.forgot_completed')),
        'success'
      );
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const resetToken = createAsyncThunk(
  'auth/reset-token',
  async (payload: IResetTokenRequest) => {
    try {
      await RequestService.post(endpoint.auth.RESET_TOKEN, payload);
      NotificationContainer(
        String(t('Registration.confirmation_send_repeat')),
        'success'
      );
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_payload, thunkApi) => {
    try {
      await RequestService.post(endpoint.auth.LOGOUT, _payload, true);
      thunkApi.dispatch(resetAuthState());
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
