import React, { FC } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IPosition } from 'store/redux-toolkit/position/position-types';
import { addZeros } from 'utils/integers';
import { InfoCircleIcon } from 'assets/svg-icon';
import { useMoneyFormat } from 'hooks/useMoneyFormat';

interface ITradeHistoryRowProps {
  item: IPosition;
  showInfo: (position: IPosition) => void;
}

const TradeHistoryRow: FC<ITradeHistoryRowProps> = ({ item, showInfo }) => {
  const { t } = useTranslation();
  const moneyFormat = useMoneyFormat();
  const {
    id,
    asset,
    transaction_type,
    created_at,
    updated_at,
    open_price,
    close_price,
    profit,
    swap,
  } = item;

  const handleShowInfo = () => {
    showInfo(item);
  };

  return (
    <div className='tr-wrap'>
      <div className='tr'>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.simbol')}</div>
          <p>
            {asset?.code}{' '}
            <span className='symbol-info'>{asset?.market.name}</span>
          </p>
          <span className='td-text-small'>
            {asset?.name}{' '}
            {asset?.market.name.toUpperCase() !== 'FX' && 'to American Dollar'}
          </span>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.order')}</div>
          <p>{addZeros(id)}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.type')}</div>
          <p className={classNames(transaction_type)}>
            {t(`Order.${transaction_type}`)}
          </p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.time_open')}</div>
          <p>{moment(created_at).format('DD-MM-YYYY')}</p>
          <span className='td-text-small'>
            {moment(created_at).format('HH:mm:ss')}
          </span>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.opening_price')}</div>
          <p>{open_price}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.time_close')}</div>
          <p>{moment(updated_at).format('DD-MM-YYYY')}</p>
          <span className='td-text-small'>
            {moment(updated_at).format('HH:mm:ss')}
          </span>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.closed_price')}</div>
          <p>{close_price}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.profit')}</div>
          <p className={classNames({ red: profit < 0, green: profit > 0 })}>
            {moneyFormat(profit, 2, 2)}
          </p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.net')} P/L</div>
          <p className={classNames({ red: profit < 0, green: profit > 0 })}>
            {moneyFormat(profit - swap, 2, 2)}
          </p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.rollover')}</div>
          <p>{moneyFormat(swap, 2, 2)}</p>
        </div>
        <div className='td td--right'>
          <button type='button' onClick={handleShowInfo}>
            <InfoCircleIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TradeHistoryRow;
