import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getReferralBalance,
  getReferrals,
  getRewards,
  getTransferHistory,
  transferReferralToBalance,
} from './referral-thunks';
import {
  IReferralBalance,
  ReferralState,
  IReferralsResponse,
  IReferralCommissionResponse,
  ITransferHistoryResponse,
} from './referral-types';

const initialState: ReferralState = {
  balance: null,
  referrals: null,
  rewards: null,
  history: null,
};

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getReferralBalance.fulfilled,
        (state, { payload }: PayloadAction<IReferralBalance>) => {
          state.balance = payload;
        }
      )
      .addCase(
        transferReferralToBalance.fulfilled,
        (state, { payload }: PayloadAction<IReferralBalance>) => {
          state.balance = payload;
        }
      )
      .addCase(
        getReferrals.fulfilled,
        (state, { payload }: PayloadAction<IReferralsResponse>) => {
          state.referrals = payload;
        }
      )
      .addCase(
        getRewards.fulfilled,
        (state, { payload }: PayloadAction<IReferralCommissionResponse>) => {
          state.rewards = payload;
        }
      )
      .addCase(
        getTransferHistory.fulfilled,
        (state, { payload }: PayloadAction<ITransferHistoryResponse>) => {
          state.history = payload;
        }
      );
  },
});

export default referralSlice.reducer;
