import { ETransactionType } from 'enum/types';
import { FC, useState } from 'react';
import { IPosition } from 'store/redux-toolkit/position/position-types';
import RowChild from './RowChild';
import RowParent from './RowParent';

interface IOpenPositionRowProps {
  items: IPosition[];
  handleUpdateTpSl: (position: IPosition, type: ETransactionType) => void;
  showInfo: (position: IPosition) => void;
}

const OpenOrderRow: FC<IOpenPositionRowProps> = ({
  items,
  handleUpdateTpSl,
  showInfo,
}) => {
  const [openList, setOpenList] = useState(false);

  const handleToggleList = () => {
    setOpenList(!openList);
  };

  return (
    <div className='tr-wrap'>
      {items.length > 1 && (
        <RowParent
          items={items}
          toggleList={handleToggleList}
          open={openList}
        />
      )}
      {items.map((el) => (
        <RowChild
          key={el.id}
          item={el}
          childs={items.length}
          openList={openList}
          handleUpdateTpSl={handleUpdateTpSl}
          showInfo={showInfo}
        />
      ))}
    </div>
  );
};

export default OpenOrderRow;
