import { CloseIcon } from 'assets/svg-icon';
import classNames from 'classnames';
import { ERoutes } from 'enum/types';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IPosition } from 'store/redux-toolkit/position/position-types';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';
import { addZeros } from 'utils/integers';

interface IPositionInfoProps {
  position: IPosition;
}

const PositionInfoHistory: FC<IPopupProps & IPositionInfoProps> = ({
  position,
  openModal,
  setOpenModal,
}) => {
  const { t } = useTranslation();
  const moneyFormat = useMoneyFormat();
  const location = useLocation();
  const {
    id,
    asset,
    transaction_type,
    amount,
    open_price,
    close_price,
    swap,
    profit,
    order_id,
    created_at,
    updated_at,
  } = position;

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <ModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      customClass={`popup--width-440${
        location.pathname === ERoutes.trade ? ' popup-tool-information' : ''
      }`}
    >
      <div className='popup-header'>
        <p className='popup-header__title'>
          {asset.code}{' '}
          <span className='value-text-icon'>{asset.market.name}</span>
        </p>
        <div className='popup-text'>
          <p>{asset.name} to American Dollar</p>
        </div>
      </div>
      <div className='popup-body'>
        <div className='popup-position-information'>
          <div className='popup-position-information__item'>
            <div className='popup-position-information__title'>
              <p>{t('Table.type')}</p>
            </div>
            <div className='popup-position-information__text'>
              <p className={transaction_type}>
                {t(`Order.${transaction_type}`)}
              </p>
            </div>
          </div>
          <div className='popup-position-information__item'>
            <div className='popup-position-information__title'>
              <p>{t('Table.volume')}</p>
            </div>
            <div className='popup-position-information__text'>
              <p>{amount}</p>
            </div>
          </div>
          <div className='popup-position-information__item popup-position-information__item--ta-r'>
            <div className='popup-position-information__title'>
              <p>{t('Table.net')} P/L</p>
            </div>
            <div className='popup-position-information__text'>
              <p>
                <span
                  className={classNames({
                    red: profit < 0,
                    green: profit > 0,
                  })}
                >
                  {moneyFormat(profit - swap, 2, 2)}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className='popup-body-item'>
          <div className='withdrawal-info-pop withdrawal-info-pop--type3'>
            <div>
              <p>
                {t('Table.id_closed')} <br /> <span>{addZeros(order_id)}</span>
              </p>
              <p>
                {t('Table.order')} <br /> <span>{addZeros(id)}</span>
              </p>
            </div>

            <div>
              <p>
                {t('Table.time_open')} <br />{' '}
                <span>{moment(created_at).format('DD-MM-YYYY HH:mm:ss')}</span>
              </p>
              <p>
                {t('Table.opening_price')} <br /> <span>{open_price}</span>
              </p>
            </div>
            <div>
              <p>
                {t('Table.time_close')} <br />{' '}
                <span>{moment(updated_at).format('DDD-MM-YYYY HH:mm:ss')}</span>
              </p>
              <p>
                {t('Table.closed_price')} <br /> <span>{close_price}</span>
              </p>
            </div>

            <div>
              <p>
                {t('Table.rollover')}
                <br />{' '}
                <span>{swap > 0.01 ? moneyFormat(swap, 0, 2) : '-'}</span>
              </p>
              <p>
                {t('Table.profit')} <br />{' '}
                <span
                  className={classNames({
                    red: profit < 0,
                    green: profit > 0,
                  })}
                >
                  {moneyFormat(profit - swap, 2, 2)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default PositionInfoHistory;
