import { useAppSelector } from 'hooks/redux';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { UserCircleIcon } from '../../assets/svg-icon';
import { ERoutes } from '../../enum/types';
import classNames from 'classnames';

const Sidebar = () => {
  const { t } = useTranslation();
  const { isDemo } = useAppSelector((state) => state.account);
  const [openHistory, setOpenHistory] = useState(true);
  const [openSettings, setOpenSettings] = useState(true);
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  const handleOpenHistory = () => {
    setOpenHistory(!openHistory);
  };
  const handleOpenSettings = () => {
    setOpenSettings(!openSettings);
  };

  return (
    <div className='sidebar-nav '>
      <div className='sidebar-nav-item active'>
        <nav className='sidebar-nav-item__list'>
          <ul>
            <li>
              <NavLink to={ERoutes.account}>
                <span className='sidebar-nav-item__icon'>
                  <UserCircleIcon />
                </span>
                <div className='sidebar-nav-item__text'>
                  <p>{t('Page.Account.title')}</p>
                </div>
              </NavLink>
            </li>
            {!isDemo && (
              <>
                <li>
                  <NavLink to={ERoutes.deposit}>
                    <span className='sidebar-nav-item__icon'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M14.75 17C14.75 16.5858 15.0858 16.25 15.5 16.25H21.5C21.9142 16.25 22.25 16.5858 22.25 17C22.25 17.4142 21.9142 17.75 21.5 17.75H15.5C15.0858 17.75 14.75 17.4142 14.75 17Z'
                          fill='#65717D'
                        ></path>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M1.75 5C1.75 3.75736 2.75736 2.75 4 2.75H20C21.2427 2.75 22.25 3.75737 22.25 5V13C22.25 13.4142 21.9142 13.75 21.5 13.75C21.0858 13.75 20.75 13.4142 20.75 13V5C20.75 4.58578 20.4142 4.25 20 4.25H4C3.58579 4.25 3.25 4.58579 3.25 5V19C3.25 19.4142 3.58578 19.75 4 19.75H14.2353C14.6495 19.75 14.9853 20.0858 14.9853 20.5C14.9853 20.9142 14.6495 21.25 14.2353 21.25H4C2.75737 21.25 1.75 20.2427 1.75 19V5Z'
                          fill='#65717D'
                        ></path>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M18.5301 13.9696C18.823 14.2625 18.823 14.7374 18.5302 15.0303L16.5606 17L18.5303 18.9697C18.8232 19.2626 18.8232 19.7374 18.5303 20.0303C18.2374 20.3232 17.7626 20.3232 17.4697 20.0303L14.9697 17.5303C14.6768 17.2374 14.6768 16.7626 14.9696 16.4697L17.4694 13.9697C17.7623 13.6768 18.2372 13.6768 18.5301 13.9696Z'
                          fill='#65717D'
                        ></path>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M9.84977 5.65039C9.4565 5.65039 9.1377 5.9692 9.1377 6.36246V6.61591H8.88452C7.29143 6.61591 6 7.90734 6 9.50043C6 11.092 7.28899 12.3825 8.88001 12.3849L8.88466 12.385H9.1377V15.3057H7.19501C6.80178 15.3057 6.48291 15.6245 6.48291 16.0178C6.48291 16.4112 6.80197 16.7299 7.1951 16.7299H9.1377V16.9833C9.1377 17.3766 9.4565 17.6954 9.84977 17.6954C10.243 17.6954 10.5618 17.3766 10.5618 16.9833V16.7299H10.8157C12.4088 16.7299 13.7002 15.4384 13.7002 13.8453C13.7002 12.2523 12.4088 10.9608 10.8157 10.9608H10.5618V8.04005H12.5052C12.8985 8.04005 13.2173 7.72128 13.2173 7.32798C13.2173 6.93457 12.8983 6.61591 12.5052 6.61591H10.5618V6.36246C10.5618 5.9692 10.243 5.65039 9.84977 5.65039ZM7.42415 9.50043C7.42415 8.69388 8.07797 8.04005 8.88452 8.04005H9.1377V10.9608H8.88818L8.88452 10.9608C8.07797 10.9608 7.42415 10.307 7.42415 9.50043ZM10.5618 15.3057V12.385H10.8157C11.6223 12.385 12.2761 13.0388 12.2761 13.8453C12.2761 14.6519 11.6223 15.3057 10.8157 15.3057H10.5618Z'
                          fill='#65717D'
                        ></path>
                      </svg>
                    </span>
                    <div className='sidebar-nav-item__text'>
                      <p>{t('Page.Deposit.title')}</p>
                    </div>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={ERoutes.withdrawCrypto}>
                    <span className='sidebar-nav-item__icon'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M14.75 17C14.75 16.5858 15.0858 16.25 15.5 16.25H21.5C21.9142 16.25 22.25 16.5858 22.25 17C22.25 17.4142 21.9142 17.75 21.5 17.75H15.5C15.0858 17.75 14.75 17.4142 14.75 17Z'
                          fill='#65717D'
                        ></path>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M18.4699 13.9696C18.7628 13.6768 19.2377 13.6768 19.5306 13.9697L22.0304 16.4697C22.3232 16.7626 22.3232 17.2374 22.0303 17.5303L19.5303 20.0303C19.2374 20.3232 18.7626 20.3232 18.4697 20.0303C18.1768 19.7374 18.1768 19.2626 18.4697 18.9697L20.4394 17L18.4698 15.0303C18.177 14.7374 18.177 14.2625 18.4699 13.9696Z'
                          fill='#65717D'
                        ></path>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M1.75 5C1.75 3.75736 2.75736 2.75 4 2.75H20C21.2427 2.75 22.25 3.75737 22.25 5V13C22.25 13.4142 21.9142 13.75 21.5 13.75C21.0858 13.75 20.75 13.4142 20.75 13V5C20.75 4.58578 20.4142 4.25 20 4.25H4C3.58579 4.25 3.25 4.58579 3.25 5V19C3.25 19.4142 3.58578 19.75 4 19.75H14.2353C14.6495 19.75 14.9853 20.0858 14.9853 20.5C14.9853 20.9142 14.6495 21.25 14.2353 21.25H4C2.75737 21.25 1.75 20.2427 1.75 19V5Z'
                          fill='#65717D'
                        ></path>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M9.49967 5.65039C9.1064 5.65039 8.7876 5.9692 8.7876 6.36246V6.61591H8.53442C6.94134 6.61591 5.6499 7.90734 5.6499 9.50043C5.6499 11.092 6.9389 12.3825 8.52992 12.3849L8.53457 12.385H8.7876V15.3057H6.84491C6.45168 15.3057 6.13281 15.6245 6.13281 16.0178C6.13281 16.4112 6.45187 16.7299 6.845 16.7299H8.7876V16.9833C8.7876 17.3766 9.1064 17.6954 9.49967 17.6954C9.89294 17.6954 10.2117 17.3766 10.2117 16.9833V16.7299H10.4656C12.0587 16.7299 13.3501 15.4384 13.3501 13.8453C13.3501 12.2523 12.0587 10.9608 10.4656 10.9608H10.2117V8.04005H12.1551C12.5484 8.04005 12.8672 7.72128 12.8672 7.32798C12.8672 6.93457 12.5482 6.61591 12.1551 6.61591H10.2117V6.36246C10.2117 5.9692 9.89294 5.65039 9.49967 5.65039ZM7.07405 9.50043C7.07405 8.69388 7.72787 8.04005 8.53442 8.04005H8.7876V10.9608H8.53808L8.53442 10.9608C7.72787 10.9608 7.07405 10.307 7.07405 9.50043ZM10.2117 15.3057V12.385H10.4656C11.2722 12.385 11.926 13.0388 11.926 13.8453C11.926 14.6519 11.2722 15.3057 10.4656 15.3057H10.2117Z'
                          fill='#65717D'
                        ></path>
                      </svg>
                    </span>
                    <div className='sidebar-nav-item__text'>
                      <p>{t('Page.WithdrawCrypto.title')}</p>
                    </div>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
      <div
        className={classNames('sidebar-nav-item', {
          active: openHistory,
          'sidebar-nav-item--hide-mob': !showMoreMenu,
        })}
      >
        <button
          type='button'
          className='sidebar-nav-item__button'
          onClick={handleOpenHistory}
        >
          {t('Page.TradeHistory.title')}
          <span className='sidebar-nav-item__button-arrow'>
            <svg
              width='12'
              height='7'
              viewBox='0 0 12 7'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 6.5L10.8 6.5L12 6.5L6 0.499999L0 6.5Z'
                fill='#191C1F'
              ></path>
            </svg>
          </span>
        </button>
        <nav className='sidebar-nav-item__list'>
          <ul>
            <li>
              <NavLink to={`${ERoutes.tradeHistory}/${ERoutes.history}`}>
                <span className='sidebar-nav-item__icon'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M12.1768 9.70239C12.0791 9.60476 11.9209 9.60476 11.8232 9.70239L9.7019 11.8237C9.60427 11.9213 9.60427 12.0796 9.7019 12.1773L11.8232 14.2986C11.9209 14.3962 12.0791 14.3962 12.1768 14.2986L14.2981 12.1773C14.3957 12.0796 14.3957 11.9213 14.2981 11.8237L12.1768 9.70239ZM10.7626 8.64173C11.446 7.95832 12.554 7.95832 13.2374 8.64173L15.3588 10.7631C16.0422 11.4465 16.0422 12.5545 15.3588 13.2379L13.2374 15.3592C12.554 16.0427 11.446 16.0427 10.7626 15.3592L8.64124 13.2379C7.95783 12.5545 7.95783 11.4465 8.64124 10.7631L10.7626 8.64173Z'
                      fill='#65717D'
                    ></path>
                  </svg>
                </span>
                <div className='sidebar-nav-item__text'>
                  <p>{t('Page.TradeHistory.title')}</p>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${ERoutes.tradeHistory}/${ERoutes.openOrders}`}>
                <span className='sidebar-nav-item__icon'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M6 2.75C5.86192 2.75 5.75 2.86192 5.75 3V20.5426L8.06407 18.8897C8.32484 18.7034 8.67516 18.7034 8.93593 18.8897L12 21.0783L15.0641 18.8897C15.3248 18.7034 15.6752 18.7034 15.9359 18.8897L18.25 20.5426V3C18.25 2.86192 18.1381 2.75 18 2.75H6ZM4.25 3C4.25 2.03351 5.03348 1.25 6 1.25H18C18.9665 1.25 19.75 2.03351 19.75 3V22C19.75 22.2809 19.593 22.5383 19.3432 22.6669C19.0934 22.7954 18.7927 22.7736 18.5641 22.6103L15.5 20.4217L12.4359 22.6103C12.1752 22.7966 11.8248 22.7966 11.5641 22.6103L8.5 20.4217L5.43593 22.6103C5.20732 22.7736 4.90662 22.7954 4.65681 22.6669C4.40701 22.5383 4.25 22.2809 4.25 22V3Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M8.25 11C8.25 10.5858 8.58579 10.25 9 10.25H15C15.4142 10.25 15.75 10.5858 15.75 11C15.75 11.4142 15.4142 11.75 15 11.75H9C8.58579 11.75 8.25 11.4142 8.25 11Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M8.25 15C8.25 14.5858 8.58579 14.25 9 14.25H15C15.4142 14.25 15.75 14.5858 15.75 15C15.75 15.4142 15.4142 15.75 15 15.75H9C8.58579 15.75 8.25 15.4142 8.25 15Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M8.25 7C8.25 6.58579 8.58579 6.25 9 6.25H15C15.4142 6.25 15.75 6.58579 15.75 7C15.75 7.41421 15.4142 7.75 15 7.75H9C8.58579 7.75 8.25 7.41421 8.25 7Z'
                      fill='#65717D'
                    ></path>
                  </svg>
                </span>
                <div className='sidebar-nav-item__text'>
                  <p>{t('Page.TradeHistory.open_orders_title')}</p>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${ERoutes.tradeHistory}/${ERoutes.cashTransaction}`}
              >
                <span className='sidebar-nav-item__icon'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M4.25 3.5C4.25 3.08579 4.58579 2.75 5 2.75H19C19.4142 2.75 19.75 3.08579 19.75 3.5V7.5C19.75 7.91421 19.4142 8.25 19 8.25C18.5858 8.25 18.25 7.91421 18.25 7.5V4.25H5.75V7.5C5.75 7.91421 5.41421 8.25 5 8.25C4.58579 8.25 4.25 7.91421 4.25 7.5V3.5Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M1.75 7.5C1.75 7.08579 2.08579 6.75 2.5 6.75H21.5C21.9142 6.75 22.25 7.08579 22.25 7.5V13.5C22.25 13.9142 21.9142 14.25 21.5 14.25C21.0858 14.25 20.75 13.9142 20.75 13.5V8.25H3.25V19.75H21.5C21.9142 19.75 22.25 20.0858 22.25 20.5C22.25 20.9142 21.9142 21.25 21.5 21.25H2.5C2.08579 21.25 1.75 20.9142 1.75 20.5V7.5Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M14.9359 12.8898C15.273 13.1305 15.3511 13.5989 15.1103 13.936L13.4574 16.2501H21.5C21.9142 16.2501 22.25 16.5858 22.25 17.0001C22.25 17.4143 21.9142 17.7501 21.5 17.7501H12C11.7191 17.7501 11.4617 17.593 11.3331 17.3432C11.2046 17.0934 11.2264 16.7927 11.3897 16.5641L13.8897 13.0641C14.1305 12.7271 14.5989 12.649 14.9359 12.8898Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M10.25 10.5C10.25 10.0858 10.5858 9.75 11 9.75H13C13.4142 9.75 13.75 10.0858 13.75 10.5C13.75 10.9142 13.4142 11.25 13 11.25H11C10.5858 11.25 10.25 10.9142 10.25 10.5Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M5.25 10.5C5.25 10.0858 5.58579 9.75 6 9.75H8C8.41421 9.75 8.75 10.0858 8.75 10.5C8.75 10.9142 8.41421 11.25 8 11.25H6C5.58579 11.25 5.25 10.9142 5.25 10.5Z'
                      fill='#65717D'
                    ></path>
                  </svg>
                </span>
                <div className='sidebar-nav-item__text'>
                  <p>{t('Page.CashTransaction.title')}</p>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={`${ERoutes.tradeHistory}/${ERoutes.ordersHistory}`}>
                <span className='sidebar-nav-item__icon'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M3.25 3C3.25 2.0335 4.0335 1.25 5 1.25H15.5C15.7126 1.25 15.9152 1.34024 16.0575 1.49828L20.5575 6.49828C20.6814 6.63599 20.75 6.81472 20.75 7V11.5C20.75 11.9142 20.4142 12.25 20 12.25C19.5858 12.25 19.25 11.9142 19.25 11.5V7.2878L15.166 2.75H5C4.86193 2.75 4.75 2.86193 4.75 3V21C4.75 21.1381 4.86192 21.25 5 21.25H11C11.4142 21.25 11.75 21.5858 11.75 22C11.75 22.4142 11.4142 22.75 11 22.75H5C4.03351 22.75 3.25 21.9665 3.25 21V3Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M15.5 14.75C14.2574 14.75 13.25 15.7574 13.25 17C13.25 18.2426 14.2574 19.25 15.5 19.25C16.7426 19.25 17.75 18.2426 17.75 17C17.75 15.7574 16.7426 14.75 15.5 14.75ZM11.75 17C11.75 14.9289 13.4289 13.25 15.5 13.25C17.5711 13.25 19.25 14.9289 19.25 17C19.25 19.0711 17.5711 20.75 15.5 20.75C13.4289 20.75 11.75 19.0711 11.75 17Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M17.4144 18.5315C17.6731 18.2081 18.1451 18.1556 18.4685 18.4144L20.9685 20.4144C21.292 20.6731 21.3444 21.1451 21.0857 21.4685C20.8269 21.792 20.355 21.8444 20.0315 21.5857L17.5315 19.5857C17.2081 19.3269 17.1556 18.855 17.4144 18.5315Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M15 1.25C15.4142 1.25 15.75 1.58579 15.75 2V6.25H20C20.4142 6.25 20.75 6.58579 20.75 7C20.75 7.41421 20.4142 7.75 20 7.75H15C14.5858 7.75 14.25 7.41421 14.25 7V2C14.25 1.58579 14.5858 1.25 15 1.25Z'
                      fill='#65717D'
                    ></path>
                  </svg>
                </span>
                <div className='sidebar-nav-item__text'>
                  <p>{t('Page.TradeHistory.orders_history_title')}</p>
                </div>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div
        className={classNames('sidebar-nav-item', {
          active: openSettings,
          'sidebar-nav-item--hide-mob': !showMoreMenu,
        })}
      >
        <button
          type='button'
          className='sidebar-nav-item__button'
          onClick={handleOpenSettings}
        >
          {t('Page.General.settings_title')}
          <span className='sidebar-nav-item__button-arrow'>
            <svg
              width='12'
              height='7'
              viewBox='0 0 12 7'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0 6.5L10.8 6.5L12 6.5L6 0.499999L0 6.5Z'
                fill='#191C1F'
              ></path>
            </svg>
          </span>
        </button>
        <nav className='sidebar-nav-item__list'>
          <ul>
            <li>
              <NavLink to={ERoutes.generalSettings}>
                <span className='sidebar-nav-item__icon'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M17 5C17 4.58579 17.3358 4.25 17.75 4.25H20.75C21.1642 4.25 21.5 4.58579 21.5 5C21.5 5.41421 21.1642 5.75 20.75 5.75H17.75C17.3358 5.75 17 5.41421 17 5Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M13.75 2.25C14.1642 2.25 14.5 2.58579 14.5 3V7C14.5 7.41421 14.1642 7.75 13.75 7.75C13.3358 7.75 13 7.41421 13 7V3C13 2.58579 13.3358 2.25 13.75 2.25Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M2 5C2 4.58579 2.33579 4.25 2.75 4.25H13.75C14.1642 4.25 14.5 4.58579 14.5 5C14.5 5.41421 14.1642 5.75 13.75 5.75H2.75C2.33579 5.75 2 5.41421 2 5Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M2 12C2 11.5858 2.33579 11.25 2.75 11.25H6.75C7.16421 11.25 7.5 11.5858 7.5 12C7.5 12.4142 7.16421 12.75 6.75 12.75H2.75C2.33579 12.75 2 12.4142 2 12Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M10.75 9.25C11.1642 9.25 11.5 9.58579 11.5 10V14C11.5 14.4142 11.1642 14.75 10.75 14.75C10.3358 14.75 10 14.4142 10 14V10C10 9.58579 10.3358 9.25 10.75 9.25Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M10 12C10 11.5858 10.3358 11.25 10.75 11.25H21.75C22.1642 11.25 22.5 11.5858 22.5 12C22.5 12.4142 22.1642 12.75 21.75 12.75H10.75C10.3358 12.75 10 12.4142 10 12Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M17 19C17 18.5858 17.3358 18.25 17.75 18.25H20.75C21.1642 18.25 21.5 18.5858 21.5 19C21.5 19.4142 21.1642 19.75 20.75 19.75H17.75C17.3358 19.75 17 19.4142 17 19Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M13.75 16.25C14.1642 16.25 14.5 16.5858 14.5 17V21C14.5 21.4142 14.1642 21.75 13.75 21.75C13.3358 21.75 13 21.4142 13 21V17C13 16.5858 13.3358 16.25 13.75 16.25Z'
                      fill='#65717D'
                    ></path>
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M2 19C2 18.5858 2.33579 18.25 2.75 18.25H13.75C14.1642 18.25 14.5 18.5858 14.5 19C14.5 19.4142 14.1642 19.75 13.75 19.75H2.75C2.33579 19.75 2 19.4142 2 19Z'
                      fill='#65717D'
                    ></path>
                  </svg>
                </span>
                <div className='sidebar-nav-item__text'>
                  <p>{t('Page.General.title')}</p>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={ERoutes.notifications}>
                <span className='sidebar-nav-item__icon'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M12 2.75C15.4518 2.75 18.25 5.54821 18.25 9V18.25H5.75V9C5.75 5.54821 8.54821 2.75 12 2.75ZM19.75 18.25V9C19.75 4.71979 16.2802 1.25 12 1.25C7.71979 1.25 4.25 4.71979 4.25 9V18.25H2C1.58579 18.25 1.25 18.5858 1.25 19C1.25 19.4142 1.58579 19.75 2 19.75H22C22.4142 19.75 22.75 19.4142 22.75 19C22.75 18.5858 22.4142 18.25 22 18.25H19.75Z'
                      fill='#65717D'
                    ></path>
                    <path
                      d='M8.75 19C8.75 18.5858 9.08579 18.25 9.5 18.25H14.5C14.9142 18.25 15.25 18.5858 15.25 19V19.5C15.25 21.2949 13.7949 22.75 12 22.75C10.2051 22.75 8.75 21.2949 8.75 19.5V19ZM10.2677 19.75C10.389 20.5981 11.1184 21.25 12 21.25C12.8816 21.25 13.611 20.5981 13.7323 19.75H10.2677Z'
                      fill='#65717D'
                    ></path>
                  </svg>
                </span>
                <div className='sidebar-nav-item__text'>
                  <p>{t('Page.Notifications.title')}</p>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to={ERoutes.referrals}>
                <span className='sidebar-nav-item__icon'>
                  <svg
                    className='stroke'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M2 22C2 21.5556 2 20.0556 2 17.5C2 14.7386 3.88511 12.5 6.21055 12.5C7.33335 12.5 8.17545 12.5 8.73685 12.5C10.7793 12.5 12 14.5134 12 14.5134C11.9962 14.5134 13.2023 12.5 15.1829 12.5C15.751 12.5 16.6031 12.5 17.7393 12.5C20.0924 12.5 21.9999 14.7386 21.9999 17.5C21.9999 20.0556 21.9999 21.5556 21.9999 22M5.5 20H19M17.1477 18.1292L19.007 19.9999L17.1477 21.921M7.16475 18.1161L5.27595 19.9964L7.16475 21.9079M7 9C8.933 9 10.5 7.433 10.5 5.5C10.5 3.56701 8.933 2 7 2C5.067 2 3.5 3.56701 3.5 5.5C3.5 7.433 5.067 9 7 9ZM17 9C18.933 9 20.5 7.433 20.5 5.5C20.5 3.56701 18.933 2 17 2C15.067 2 13.5 3.56701 13.5 5.5C13.5 7.433 15.067 9 17 9Z'
                      stroke='#65717D'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    ></path>
                  </svg>
                </span>
                <div className='sidebar-nav-item__text'>
                  <p>{t('Page.Referral.title')}</p>
                </div>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className='sidebar-nav__more'>
        <button
          className='link link--fw-500'
          onClick={() => setShowMoreMenu((prev) => !prev)}
        >
          Показать {showMoreMenu ? 'меньше' : 'больше'}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
