import { ChangeEvent, FC } from 'react';

interface CustomInputProps {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
}

const CustomSwitch: FC<CustomInputProps> = ({ checked, onChange, name }) => {
  // const handleChack = () => {
  //   onChange(!checked);
  // };
  return (
    <div className='switch switch--type2'>
      <label className='switch__label'>
        <input
          type='checkbox'
          className='hidden'
          checked={checked}
          onChange={onChange}
          name={name}
        />
        <span className='switch__toggler'></span>
      </label>
    </div>
  );
};

export default CustomSwitch;
