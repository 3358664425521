import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { InputMinusIcon, InputPlusIcon } from 'assets/svg-icon';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { ERoutes } from 'enum/types';

interface CustomInputProps {
  title?: string | null;
  value: string;
  changeCount: Dispatch<SetStateAction<number>>;
  disabled?: boolean;
}

const InputNum: FC<CustomInputProps> = ({
  title,
  value,
  changeCount,
  disabled,
}) => {
  const location = useLocation();
  const [count, setCount] = useState<string>(value);
  const afterLength =
    String(value).split('.').length > 1
      ? String(value).split('.')[1].length
      : 0;

  const degree = 10 ** afterLength;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const result = e.target.value.replace(/[^\d.]/g, '');
    changeCount(Number(result));
    setCount(result);
  };

  const handlePlus = () => {
    const result = degree
      ? ((+count * degree + 1) / degree).toFixed(afterLength)
      : String(+count + 1);
    changeCount(Number(result));
    setCount(result);
  };

  const handleMinus = () => {
    const result = degree
      ? ((+count * degree - 1) / degree).toFixed(afterLength)
      : String(+count - 1);
    if (+result > 0) {
      changeCount(Number(result));
      setCount(result);
    }
  };

  /* ===============================
    DEV D COMMENT  
    useEffect(() => {
      setCount(value);
      changeCount(Number(value));
    }, [value]);
  =============================== */

  return (
    <div
      className={classNames('input input--size3 input--number input--mb-8', {
        'input--disabled': disabled,
        'input--black': location.pathname === ERoutes.trade,
      })}
    >
      {title && (
        <div className='input__name'>
          <p>{title}</p>
        </div>
      )}

      <div className='input-wrapper'>
        <input
          className='input-item'
          type='text'
          value={count}
          onChange={onChange}
          disabled={disabled}
        />
        <div className='input-icon'>
          <button type='button' onClick={handleMinus} disabled={disabled}>
            <InputMinusIcon />
          </button>
        </div>
        <div className='input-icon input-icon--right'>
          <button type='button' onClick={handlePlus} disabled={disabled}>
            <InputPlusIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default InputNum;
