import React, { FC, useState } from 'react';
import { FieldProps } from 'formik';
import { PasswordHideIcon, PasswordShowIcon } from '../../../assets/svg-icon';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const PasswordField: FC<FieldProps> = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const { t } = useTranslation();
  const [isShowPass, setIsShowPass] = useState(false);
  const handleShowPass = () => setIsShowPass(!isShowPass);
  return (
    <div
      className={classNames('input', {
        'input--error': touched[field.name] && errors[field.name],
      })}
    >
      <div className='input-wrapper'>
        <input
          {...field}
          {...props}
          type={isShowPass ? 'text' : 'password'}
          className='input-item'
        />
        <button type='button' className='show-pass' onClick={handleShowPass}>
          <PasswordHideIcon />
          <PasswordShowIcon />
        </button>
      </div>
      {touched[field.name] && errors[field.name] && (
        <p className='input-notification--error'>
          {t(String(errors[field.name]))}
        </p>
      )}
    </div>
  );
};

export default PasswordField;
