import Notifications from 'components/Notifications';
import React from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';

const NotificationsPage = () => {
  const appTitle = APP_NAME || '';

  return (
    <>
      <Helmet>
        <title>{`${appTitle} | Referrals`}</title>
      </Helmet>
      <Notifications />
    </>
  );
};

export default NotificationsPage;
