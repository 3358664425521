import { useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logoDark from '../../assets/images/logo_dark.png';
import logoWhite from '../../assets/images/logo_white.png';
import { resetAuthState } from '../../store/redux-toolkit/auth/auth-slice';
import { useTranslation } from 'react-i18next';
import HeaderDropdown from '../../ui/HeaderDropdown';
import { AboutList, GoodsList } from '../../ui/HeaderDropdown/menuLists';
import SettingDropdown from '../../ui/SettingDropdown';
import LanguageDropdown from '../../ui/LanguageDropdown';
import ModalWrapper from '../../ui/ModalWrapper';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { setDemo } from 'store/redux-toolkit/account/account-slice';
import { ERoutes } from 'enum/types';
import { getSettingBySlug } from 'store/redux-toolkit/settings/setting-thunks';

interface IHeaderClass {
  addClass?: string;
  hideDemo?: boolean;
}

const Header: FC<IHeaderClass> = ({ addClass, hideDemo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();
  const { success, isAuth } = useAppSelector((store) => store.auth);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);
  const { settingList } = useAppSelector((state) => state.settings);

  const { pathname } = useLocation();

  useEffect(() => {
    appDispatch(getSettingBySlug({ slug: 'demo-account' }));
  }, []);

  let showDemo = false;
  if (settingList) {
    try {
      showDemo = !!settingList[0]?.is_active;
    } catch (e) {
      showDemo = true;
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(resetAuthState());
  };

  const toggleMobileMenu = () => {
    setMobileActive((prev) => !prev);
  };

  const closeMobileMenu = () => {
    setMobileActive(false);
  };

  const handleTryDemo = () => {
    if (isAuth) {
      navigate(ERoutes.trade);
    } else {
      navigate(ERoutes.registration);
    }
    dispatch(setDemo(1));
    closeMobileMenu();
  };

  const handleTrade = () => {
    if (!isAuth) {
      navigate(ERoutes.registration);
      return;
    }
    if (pathname === ERoutes.trade) {
      return;
    }
    window.open(`${window.location.origin}${ERoutes.trade}`, '_blank');
    closeMobileMenu();
  };

  const handleRegistration = () => {
    navigate(ERoutes.registration);
    dispatch(setDemo(0));
    closeMobileMenu();
  };

  const handleLogin = () => {
    navigate(ERoutes.login);
    dispatch(setDemo(0));
    closeMobileMenu();
  };

  useEffect(() => {
    setOpenModal(success === 'logout-confirmed');
  }, [success]);

  useEffect(() => {
    if (mobileActive) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [mobileActive]);

  return (
    <header className={`header ${addClass}`}>
      <div className='header__left'>
        <Link to='/' className='logo'>
          <img className='logo--white' src={logoWhite} alt='' />
          <img className='logo--dark' src={logoDark} alt='' />
        </Link>
      </div>
      <div className={`nav-block ${mobileActive ? 'active' : ''}`}>
        <div className='nav-block__inner'>
          <nav className='main-nav'>
            <div className='nav-item'>
              <button
                type='button'
                className='nav-item__link'
                onClick={handleTrade}
              >
                {t('Header.menu_goods.trade')}
              </button>
            </div>
            {!hideDemo && showDemo && (
              <div className='nav-item'>
                <button
                  type='button'
                  className='nav-item__link'
                  onClick={handleTryDemo}
                >
                  {t('Header.try_demo')}
                </button>
              </div>
            )}
          </nav>
          <div className='nav-right'>
            {isAuth ? (
              <SettingDropdown
                closeMobileMenu={closeMobileMenu}
                hideDemo={hideDemo}
              />
            ) : (
              <div className='authorization-btns'>
                <button
                  type='button'
                  className='button button--text-type'
                  onClick={handleLogin}
                >
                  {t('Login.btn')}
                </button>
                <button
                  type='button'
                  className='button button--type2 button--width-auto'
                  onClick={handleRegistration}
                >
                  {t('Registration.btn')}
                </button>
              </div>
            )}
            <LanguageDropdown />
          </div>
        </div>
      </div>
      <button
        type='button'
        className={`nav-btn ${mobileActive ? 'active' : ''}`}
        onClick={toggleMobileMenu}
      >
        <svg
          className='nav-btn__open'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M3 18H21'
            stroke='#25252E'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M3 12H21'
            stroke='#25252E'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M3 6H21'
            stroke='#25252E'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
        </svg>
        <svg
          className='nav-btn__close'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z'
            fill='#25252E'
          ></path>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z'
            fill='#25252E'
          ></path>
        </svg>
      </button>
      <ModalWrapper
        openModal={openModal}
        setOpenModal={handleCloseModal}
        customClass='popup--small popup--padding-type2'
      >
        <div className='popup-header'>
          <p className='popup-header__title'>{t('Login.you_exit')}</p>
          <p className='popup-header__subtitle'>{t('Login.login_again')}</p>
        </div>

        <div className='popup-footer'>
          <div className='popup-submit popup-submit--sb'>
            <button
              type='button'
              className='button button--type3 button--size2 button--full-width'
              onClick={handleCloseModal}
            >
              {t('Button.ok')}
            </button>
          </div>
        </div>
      </ModalWrapper>
    </header>
  );
};

export default Header;
