import React from 'react';

import Referral from 'components/Referral';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';

const ReferralsPage = () => {
  const appTitle = APP_NAME || '';
  return (
    <>
      <Helmet>
        <title>{`${appTitle} | Referrals`}</title>
      </Helmet>
      <Referral />
    </>
  );
};

export default ReferralsPage;
