import { NotificationContainer } from '../../../ui/notificationContainer';
import { endpoint } from './../../../services/endpoint';
import requestService from 'services/http/interceptor/request-service';
import {
  ICheckWithdrawAddressRequest,
  ICreateWithdrawalRequest,
  IDepositInfo,
  IDepositRequest,
  IDepositResponse,
  IEstimateAmountRequest,
  IWithdrawalFeeRequest,
} from './depWith-type';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse, isAxiosError } from 'axios';
import { showError } from 'utils/showError';
import { t } from 'i18next';

export const depositRequest = createAsyncThunk(
  'deposit-withdrawal/deposit',
  async (params: IDepositRequest) => {
    try {
      const response: AxiosResponse<IDepositResponse> =
        await requestService.post(endpoint.DEPOSIT, params);
      window.open(response.data.invoice_url, '_blank');
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getAssetsToWithdrawal = createAsyncThunk(
  'deposit-withdrawal/assets',
  async () => {
    try {
      const response = await requestService.get(endpoint.withdrawal.GET_ASSETS);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getEstimateAmount = createAsyncThunk(
  'deposit-withdrawal/estimate-amount',
  async (params: IEstimateAmountRequest, thunkApi) => {
    try {
      const response = await requestService.get(
        endpoint.withdrawal.GET_ESTIMATED_AMOUNT,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        return thunkApi.rejectWithValue(error.response?.data.errors);
      }
    }
  }
);

export const checkValidAddress = createAsyncThunk(
  'deposit-withdrawal/check-address',
  async (params: ICheckWithdrawAddressRequest) => {
    try {
      const response = await requestService.get(
        endpoint.withdrawal.CHECK_VALID_ADDRESS,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const createWithdrawalRequest = createAsyncThunk(
  'deposit-withdrawal/create-withdrawal',
  async (params: ICreateWithdrawalRequest) => {
    try {
      const response = await requestService.post(
        endpoint.withdrawal.CREATE_WITHDRAWAL,
        params
      );
      NotificationContainer(t('Page.WithdrawCrypto.create_success'), 'info');
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getWithdrawalFee = createAsyncThunk(
  'account/withdrawal-fee',
  async (params: IWithdrawalFeeRequest) => {
    try {
      const response = await requestService.get(
        endpoint.account.GET_WITHDRAWAL_FEE,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getDepositInfo = createAsyncThunk(
  'deposit-info/deposit',
  async (payload, thunkAPI) => {
    try {
      const response: AxiosResponse<IDepositInfo> = await requestService.get(
        endpoint.GET_MANUAL_DEPOSIT
      );

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
      thunkAPI.rejectWithValue('No data found.');
    }
  }
);
