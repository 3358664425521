import { Middleware } from '@reduxjs/toolkit';
import { WEBSOCKET_URL } from 'services/endpoint';
import {
  socketSendMessage,
  startConnecting,
  soketSubscribeFulfilled,
} from './sockets/socket-slice';
import { getAssetsSuccess } from './asset/asset-slice';
import { updateOpenOrdersList } from './order/order-slice';
import { updateOpenPositionsList } from './position/position-slice';
import { updateBalance, updateTradeData } from './account/account-slice';

export const syncWithLocalStorage: Middleware =
  (store) => (next) => (action) => {
    const result = next(action);
    if (action.type === 'auth/login/fulfilled') {
      localStorage.setItem('token', store.getState().auth.token);
    }
    if (
      action.type === 'auth/logout/fulfilled' ||
      action.type === 'auth/resetAuthState'
    ) {
      localStorage.removeItem('token');
    }
    return result;
  };

export const socketsMiddleware: Middleware = (store) => {
  let socket: WebSocket;
  let newSocketToken: string | null = null;
  return (next) => (action) => {
    const socketToken = newSocketToken || store.getState().auth.socketToken;

    if (startConnecting.match(action)) {
      if (socketToken) {
        socket = new WebSocket(`${String(WEBSOCKET_URL)}?${socketToken}`, [
          'wamp',
        ]);
      } else {
        socket = new WebSocket(String(WEBSOCKET_URL), ['wamp']);
      }

      socket.onmessage = ({ data }) => {
        const response = JSON.parse(data);

        if (response[0] === 9) {
          store.dispatch(soketSubscribeFulfilled());
        }

        if (response[0] === 8) {
          switch (response[1]) {
            case 'markets':
              store.dispatch(getAssetsSuccess(response[2].data));
              break;
            case 'open-orders':
              store.dispatch(updateOpenOrdersList(response[2].data));
              break;
            case 'open-positions':
              store.dispatch(updateOpenPositionsList(response[2].data));
              break;
            case 'user-trade-data': {
              const state = store.getState();
              if (!!response[2].data.is_demo === !!state.account.isDemo) {
                store.dispatch(updateTradeData(response[2].data));
                store.dispatch(
                  updateBalance({ balance: response[2].data.balance })
                );
              }
              break;
            }
            default:
              break;
          }
        }
      };
    }

    if (action.type === 'auth/login/fulfilled') {
      const { socket_token } = action.payload;
      if (socket_token) {
        socket.close();
        newSocketToken = socket_token;
        store.dispatch(startConnecting());
      }
    }

    if (action.type === 'auth/logout/fulfilled') {
      socket.close();
      newSocketToken = null;
      store.dispatch(startConnecting());
    }

    if (socketSendMessage.match(action)) {
      const event = action.payload;

      if (!store.getState().sockets.isConnecting) {
        if (socket.readyState === 1) {
          socket.send(JSON.stringify(event));
        } else {
          socket.onopen = () => {
            socket.send(JSON.stringify(event));
          };
        }
      }
    }

    next(action);
  };
};
