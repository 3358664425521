import { useEffect, useState } from 'react';
import { ArrowBorderLeft, ArrowBorderRight } from 'assets/svg-icon';
import { ERoutes } from 'enum/types';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AssetsWithdrawSelect from 'ui/AssetsWithdrawSelect';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  checkValidAddress,
  createWithdrawalRequest,
  getAssetsToWithdrawal,
  getEstimateAmount,
  getWithdrawalFee,
} from 'store/redux-toolkit/depositWithdrawal/depWith-thunk';
import {
  IAssetWithdraw,
  INetwork,
} from 'store/redux-toolkit/depositWithdrawal/depWith-type';
import NetworkSelect from 'ui/NetworkSelect';
import CustomInput from 'ui/CustomInput';
import {
  setErrors,
  clearErrors,
  clearEstimateAmount,
  clearFee,
} from 'store/redux-toolkit/depositWithdrawal/depWith-slice';
import ConfirmWithdrawal from 'components/Modals/ConfirmWithdrawal';
import { moneyFormat } from 'utils/moneyFormat';

interface IErrors {
  amount?: string | null;
}

const WithdrawCrypto = () => {
  const dispatch = useAppDispatch();
  const { assets, estimateAmount, errors, withdrawalFee } = useAppSelector(
    (state) => state.depositWithdrawal
  );
  const { accountData } = useAppSelector((state) => state.account);
  const { t } = useTranslation();
  const [asset, setAsset] = useState<IAssetWithdraw>();
  const [network, setNetwork] = useState<INetwork | null>(null);
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [isValidate, setIsValidate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [hideNetwork, setHideNetwork] = useState(false);
  const newAddress = useDebounce(address, 1000);
  const newAmount = useDebounce(amount, 500);

  const checkAddress = () => {
    if (address && network) {
      dispatch(
        checkValidAddress({ address, currency_network: network?.network })
      );
      dispatch(setErrors({ ...errors, network: '' }));
    } else if (address) {
      dispatch(setErrors({ ...errors, network: t('Errors.network_required') }));
    }
  };

  const clearData = () => {
    setAddress('');
    setNetwork(null);
    dispatch(clearErrors());
  };

  const selectCurreny = (el: IAssetWithdraw) => {
    setAsset(el);
    if (errors.amount) {
      setAmount(String(accountData?.available_balance.toFixed(3).slice(0, -1)));
    }
    clearData();
  };

  const selectNetwork = (el: INetwork) => {
    setNetwork(el);
    dispatch(setErrors({ ...errors, network: '' }));
  };

  const handleCreateWithdrawal = () => {
    if (isValidate) {
      setOpenModal(true);
    }
  };

  const handleConfirmWithdrawal = () => {
    if (isValidate && asset && network) {
      dispatch(
        createWithdrawalRequest({
          address,
          currency: asset?.code,
          currency_network: network?.network,
          amount_usd: amount,
        })
      );
    }
    setOpenModal(false);
    clearData();
  };

  useEffect(() => {
    const isErrors = Object.values(errors).find((el) => el !== '');
    if (isErrors || !amount || amount === '0' || !network || !address) {
      setIsValidate(false);
    } else {
      setIsValidate(true);
    }
  }, [amount, network, errors, address]);

  useEffect(() => {
    if (!assets) {
      dispatch(getAssetsToWithdrawal());
    }
    if (!asset && assets) {
      setAsset(assets[0]);
    }
  }, [assets]);

  useEffect(() => {
    if (asset?.currency_networks && asset.currency_networks.length === 1) {
      setNetwork(asset.currency_networks[0]);
      setHideNetwork(true);
      return;
    }
    setHideNetwork(false);
  }, [asset]);

  useEffect(() => {
    if (!newAmount) {
      dispatch(clearEstimateAmount());
    }
    console.log(newAmount);
    if (Number(newAmount) >= 1 && asset) {
      dispatch(
        getEstimateAmount({ amount_usd: newAmount, currency: asset.code })
      );
      if (network) {
        dispatch(
          getWithdrawalFee({
            currency: asset.code,
            currency_network: network?.network,
            amount_usd: newAmount,
          })
        );
      } else {
        dispatch(clearFee());
      }
    }
  }, [newAmount, asset?.code, network]);

  useEffect(() => {
    if (newAddress.length > 25) {
      checkAddress();
    }
  }, [newAddress, address, network]);

  useEffect(() => {
    if (accountData && Number(amount) > accountData?.available_balance) {
      dispatch(setErrors({ ...errors, amount: t('Validation.not_money') }));
    } else {
      dispatch(setErrors({ ...errors, amount: '' }));
    }
  }, [amount]);

  return (
    <div className='deposit-content'>
      <div className='deposit-content-inside'>
        <div className='deposit-content-header'>
          <div className='deposit-content-header-left'>
            <Link to={ERoutes.account} className='deposit-content-header__back'>
              <ArrowBorderLeft />
            </Link>
            <p>{t('Page.WithdrawCrypto.title')}</p>
          </div>
          <div className='deposit-content-header-right'>
            <Link
              to={ERoutes.deposit}
              className='button button--type3 button--size4 button--width-auto'
            >
              {t('Page.Deposit.title')}
            </Link>
            <Link to={ERoutes.deposit} className='deposit-content-header__back'>
              <ArrowBorderRight />
            </Link>
          </div>
        </div>
        <div className='deposit-content-item'>
          <div className='deposit-content-item__title'>
            <p>{t('Page.WithdrawCrypto.change_coin')}</p>
          </div>
          <AssetsWithdrawSelect
            asset={asset}
            assets={assets}
            setElement={selectCurreny}
          />
        </div>
        <div className='deposit-content-item'>
          <div className='deposit-content-item__title'>
            <p>1. {t('Page.WithdrawCrypto.withdraw_to')}</p>
          </div>
          {!hideNetwork && (
            <NetworkSelect
              title={t('Page.WithdrawCrypto.network')}
              network={network}
              networks={asset?.currency_networks}
              setElement={selectNetwork}
              placeholder={t('Page.WithdrawCrypto.change_network')}
              errorMsg={errors.network}
            />
          )}
          <div className='withdraw-to'>
            <div className='withdraw-to__item'>
              <p>{t('Page.WithdrawCrypto.min_withdraw')}</p>
              <span>{network?.withdrawal_min || 1} USD</span>
            </div>
          </div>
          <CustomInput
            value={address}
            onChange={setAddress}
            label={t('Page.WithdrawCrypto.address_wallet')}
            placeholder={t('Page.WithdrawCrypto.enter_address')}
            errorMsg={
              (errors.valid_address && t(`Errors.${errors?.valid_address}`)) ||
              ''
            }
          />
        </div>
        <div className='deposit-content-item'>
          <div className='deposit-content-item__title'>
            <p>2. {t('Page.WithdrawCrypto.withdrawal_information')}</p>
          </div>
          <CustomInput
            value={amount}
            onChange={setAmount}
            label={t('Page.WithdrawCrypto.amount')}
            placeholder={t('Page.WithdrawCrypto.amount')}
            number
            textAfter='USD'
            maxValue={String(accountData?.available_balance)}
            errorMsg={
              errors?.amount ||
              (errors?.amount_usd && t(`Errors.${errors?.amount_usd}`)) ||
              ''
            }
          />
        </div>
        <div className='deposit-content-item'>
          <div className='deposit-content-item__title'>
            <p>3. {t('Page.WithdrawCrypto.get_the_amount')}</p>
          </div>
          <div className='receive-amount'>
            <p>
              {moneyFormat(amount, 0, 2)} USD{' '}
              <span>
                {moneyFormat(estimateAmount?.estimated_amount, 0, 8)}{' '}
                {asset?.code.toUpperCase()}
              </span>
            </p>
            {withdrawalFee && (
              <span>
                {moneyFormat(withdrawalFee?.withdrawal_fee)}{' '}
                {withdrawalFee?.currency.toUpperCase()}{' '}
                {t('Page.WithdrawCrypto.platform_fee')}
              </span>
            )}
          </div>
          <button
            className='button button--size2 button--width-330'
            disabled={!isValidate}
            onClick={handleCreateWithdrawal}
          >
            {t('Button.withdraw')}
          </button>
        </div>
      </div>
      <ConfirmWithdrawal
        openModal={openModal}
        setOpenModal={setOpenModal}
        confirm={handleConfirmWithdrawal}
        estimateAmount={
          moneyFormat(estimateAmount?.estimated_amount, 0, 8) || ''
        }
        fee='0'
        address={address}
        network={network}
        code={asset?.code.toUpperCase() || ''}
      />
    </div>
  );
};

export default WithdrawCrypto;
