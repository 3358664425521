import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { LoginForm } from '../../../components/Forms/LoginForm';
import { ILoginSubmitValue } from '../../../components/Forms/LoginForm/types';
import { login } from '../../../store/redux-toolkit/auth/auth-thunks';

import { ERoutes } from '../../../enum/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { COMPANY_NAME } from '../../../services/constants/env';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isAuth } = useAppSelector((state) => state.auth);
  const { isDemo } = useAppSelector((state) => state.account);
  const navigate = useNavigate();

  const pathname = location.state?.from?.pathname;

  const fromPage = (pathname !== '/' && pathname) || '/trade';

  const handleLoginSubmit = (
    values: ILoginSubmitValue,
    totp?: string
  ): void => {
    dispatch(login(values));
  };

  if (isAuth) return <Navigate to={fromPage} />;
  return (
    <div className='authorization-section'>
      <div className='authorization-section__container'>
        <div className='authorization'>
          <p className='authorization__title'>
            {t('Login.title', { demo: isDemo ? 'Demo' : '' })}
          </p>
          <div className='authorization__details'>
            <p>
              {t('Login.question', { company: COMPANY_NAME })}{' '}
              <Link to={ERoutes.registration} className='link'>
                {t('Registration.link')}
              </Link>
            </p>
          </div>
          <LoginForm loginSubmit={handleLoginSubmit} />
        </div>
      </div>
    </div>
  );
};

export default Login;
