import * as Yup from 'yup';

export const registerSchema = Yup.object().shape({
  email: Yup.string()
    .required('Validation.required_email')
    .email('Validation.email_format'),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
      'Validation.password_validation'
    )
    .required('Validation.password_required')
    .max(25, 'Validations.password_max_length'),
  acceptTerms: Yup.bool().oneOf([true], 'Validation.accept_terms'),
  cookiePolicy: Yup.bool().oneOf([true], 'Validation.cookie_policy'),
  captcha: Yup.string().required('Validation.captcha_required'),
});
