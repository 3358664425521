import { FC, useRef, useState } from 'react';

import { ArrowDropIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import { INetwork } from 'store/redux-toolkit/depositWithdrawal/depWith-type';
import classNames from 'classnames';

interface INetworkSelectProps {
  network: INetwork | null;
  networks: INetwork[] | undefined;
  setElement: (el: INetwork) => void;
  title?: string | null;
  placeholder?: string | null;
  errorMsg?: string;
}

const NetworkSelect: FC<INetworkSelectProps> = ({
  network,
  networks,
  setElement,
  title,
  placeholder,
  errorMsg,
}) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const handleChange = (el: INetwork) => {
    setElement(el);
    setOpen(false);
  };

  return (
    <div className='select-block'>
      {title && (
        <div className='select-block__name'>
          <p>{title}</p>
        </div>
      )}
      <div ref={ref} className={classNames('select', { active: open })}>
        <button type='button' className='select__current' onClick={handleClick}>
          <>
            {network ? (
              <span className='select-current-currensy select-current-currensy--type2'>
                <span className='select-current-currensy__name'>
                  {network.network.toUpperCase()}
                  <span className='select-current-currensy__name-text'>
                    {network.network_name}
                  </span>
                </span>
              </span>
            ) : (
              <span className='select__current--placeholder'>
                {placeholder}
              </span>
            )}
            <span className='select__current-arrow'>
              <ArrowDropIcon />
            </span>
          </>
        </button>

        {networks && (
          <div className='select__drop'>
            <div className='select__drop-scroll'>
              <div className='select__drop-item'>
                <ul>
                  {networks.map((el) => (
                    <li key={el.id}>
                      <button type='button' onClick={() => handleChange(el)}>
                        <span className='select-current-currensy select-current-currensy--type2'>
                          <span className='select-current-currensy__name'>
                            {el.network.toUpperCase()}
                            <span className='select-current-currensy__name-text'>
                              {el.network_name}
                            </span>
                          </span>
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      {errorMsg && <p className='input-notification--error'>{errorMsg}</p>}
    </div>
  );
};

export default NetworkSelect;
