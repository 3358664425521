import { lazy } from 'react';

import OpenOrders from 'components/TradingHistorys/OpenOrders';
import OrdersHistory from 'components/TradingHistorys/OrdersHistory';
import TradeHistory from 'components/TradingHistorys/TradeHistory';
import { ERoutes } from '../enum/types';
import AuthLayout from '../layouts/AuthLayout';
import CashTransactionPage from 'pages/CashTransactionPage';
import DepositPage from 'pages/DepositPage';
import NotificationsPage from 'pages/NotificationsPage';
import ReferralsPage from 'pages/ReferralsPage';
import TradingHistorysPage from 'pages/TradingHistorysPage';
import WithdrawCryptoPage from 'pages/WithdrawCryptoPage';

const GeneralSettingsPage = lazy(() => import('pages/GeneralSettingsPage'));
const AccountPage = lazy(() => import('pages/AccountPage'));

const PrivateRoutes = {
  path: ERoutes.home,
  element: <AuthLayout />,
  children: [
    {
      element: <AccountPage />,
      path: `${ERoutes.account}/:tab?`,
    },
    {
      element: <GeneralSettingsPage />,
      path: ERoutes.generalSettings,
    },
    {
      element: <DepositPage />,
      path: ERoutes.deposit,
    },
    {
      element: <WithdrawCryptoPage />,
      path: ERoutes.withdrawCrypto,
    },
    {
      element: <TradingHistorysPage />,
      path: ERoutes.tradeHistory,
      children: [
        {
          element: <TradeHistory />,
          path: ERoutes.history,
        },
        {
          element: <OpenOrders />,
          path: `${ERoutes.openOrders}/:tab?`,
        },
        {
          element: <CashTransactionPage />,
          path: ERoutes.cashTransaction,
        },

        {
          element: <OrdersHistory />,
          path: ERoutes.ordersHistory,
        },
      ],
    },
    {
      element: <ReferralsPage />,
      path: `${ERoutes.referrals}/:tab?`,
    },
    {
      element: <NotificationsPage />,
      path: ERoutes.notifications,
    },
  ],
};

export default PrivateRoutes;
