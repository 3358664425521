import { useParams } from 'react-router-dom';
import OpenPositionsContent from './OpenPositionsContent';
import PendingOrdersContent from './PendingOrdersContent';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useEffect } from 'react';
import { ESocketEvent } from 'enum/types';
import { socketSendMessage } from 'store/redux-toolkit/sockets/socket-slice';

const OpenOrders = () => {
  const { tab } = useParams();
  const dispatch = useAppDispatch();
  const { reconnectCount } = useAppSelector((state) => state.sockets);

  useEffect(() => {
    dispatch(socketSendMessage([5, ESocketEvent.markets]));

    return () => {
      dispatch(socketSendMessage([6, ESocketEvent.markets]));
    };
  }, [reconnectCount]);

  return (
    <div className='content'>
      <div className='content-inside'>
        {tab === 'pending' ? (
          <PendingOrdersContent />
        ) : (
          <OpenPositionsContent />
        )}
      </div>
    </div>
  );
};

export default OpenOrders;
