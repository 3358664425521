import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRewards } from 'store/redux-toolkit/referral/referral-thunks';
import Pagination from 'ui/Pagination';
import { addZeros } from 'utils/integers';

const RewardsTab = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { rewards } = useAppSelector((state) => state.referral);
  const [currentPage, setCurrentPage] = useState(1);
  const moneyFormat = useMoneyFormat();

  useEffect(() => {
    dispatch(
      getRewards({
        per_page: 8,
        current_page: currentPage,
      })
    );
  }, [currentPage]);

  return (
    <>
      <div className='table table--referral-my-rewards'>
        <div className='table-header'>
          <div className='tr'>
            <div className='td'>
              <p>ID</p>
            </div>
            <div className='td'>
              <p>{t('Table.date')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.asset')}</p>
            </div>
            <div className='td td--right'>
              <p>{t('Table.credit_amount')}</p>
            </div>
          </div>
        </div>
        <div className='table-body'>
          {rewards?.data.map((item) => (
            <div key={item.id} className='tr'>
              <div className='td'>
                <div className='td-hidden-name'>ID</div>
                <p>{addZeros(item.id)}</p>
              </div>
              <div className='td'>
                <div className='td-hidden-name'>{t('Table.date')}</div>
                <p>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
              </div>
              <div className='td'>
                <div className='td-hidden-name'>{t('Table.asset')}</div>
                <p>USD</p>
              </div>
              <div className='td td--right'>
                <div className='td-hidden-name'>{t('Table.amount')}</div>
                <p>{moneyFormat(item.amount, 0, 2)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {rewards && rewards.last_page > 1 && (
        <Pagination
          pageCount={rewards.last_page}
          forcePage={rewards.current_page}
          onPageChange={setCurrentPage}
          customClass='pagination-block--center'
        />
      )}
    </>
  );
};

export default RewardsTab;
