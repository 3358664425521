import classNames from 'classnames';
import { EOrderStatus } from 'enum/types';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IOrder } from 'store/redux-toolkit/order/order-types';
import { addZeros } from 'utils/integers';

const OrderHistoryRow: FC<IOrder> = ({
  id,
  created_at,
  updated_at,
  asset,
  status,
  amount,
  trigger_price,
  transaction_type,
  date_expiration,
  symbol_tp_sl,
  position_id,
  freeze_amount,
}) => {
  const { t } = useTranslation();
  const moneyFormat = useMoneyFormat();
  return (
    <div className='tr-wrap'>
      <div className='tr'>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.order')}</div>
          <p>{addZeros(id)}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.time')}</div>
          <p>{moment(created_at).format('DD-MM-YYYY')}</p>
          <span className='td-text-small'>
            {moment(created_at).format('HH:mm:ss')}
          </span>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.simbol')}</div>
          <p>
            {asset?.code}{' '}
            <span className='symbol-info'>{asset?.market.name}</span>
          </p>
          <span className='td-text-small'>
            {asset?.name}{' '}
            {asset?.market.name.toUpperCase() !== 'FX' && 'to American Dollar'}
          </span>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.status')}</div>
          <p className='green'>{t(`Order.${status}`)}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.volume')}</div>
          <p>{amount}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.closed_price')}</div>
          <p>{trigger_price}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.type')}</div>
          <p className={classNames(transaction_type)}>
            {t(`Order.${transaction_type}`)}
          </p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.margin')}</div>
          <p>{moneyFormat(freeze_amount)}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.sistem_comment')}</div>
          <p>{symbol_tp_sl}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.id_position')}</div>
          <p>{position_id && addZeros(position_id)}</p>
        </div>
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.time_complet')}</div>
          {status !== EOrderStatus.open ? (
            <>
              <p>{moment(updated_at).format('DD-MM-YYYY')}</p>
              <span className='td-text-small'>
                {moment(updated_at).format('HH:mm:ss')}
              </span>
            </>
          ) : (
            '-'
          )}
        </div>

        <div className='td td--right'>
          <div className='td-hidden-name'>{t('Table.expiration')}</div>
          {date_expiration ? (
            <>
              <p>{moment(date_expiration).format('DD-MM-YYYY')}</p>
              <span className='td-text-small'>
                {moment(date_expiration).format('HH:mm:ss')}
              </span>
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryRow;
