import { useTranslation } from 'react-i18next';
import { NotificationContainer } from '../../../ui/notificationContainer';
import { useEffect } from 'react';
import { getDepositInfo } from '../../../store/redux-toolkit/depositWithdrawal/depWith-thunk';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

const DepositInfo = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const depositInfo = useAppSelector(
    (state) => state.depositWithdrawal.depositInfo
  );
  const copyClipboard = () => {
    if (!depositInfo) return;
    navigator.clipboard.writeText(depositInfo[0].crypto_wallet);
    NotificationContainer(t('Page.Deposit.crypto_address_copied'), 'success');
  };

  useEffect(() => {
    dispatch(getDepositInfo());
  }, [dispatch]);

  return (
    <>
      {depositInfo && !!depositInfo.length && (
        <div className='deposit-crypto-info'>
          <p>
            {t('Page.Deposit.crypto_address')}
            <b> {depositInfo[0].crypto_wallet}</b>
          </p>
          <p>{depositInfo[0].note}</p>
          <button className='button btn-crypto-copy' onClick={copyClipboard}>
            {t('Page.Deposit.crypto_address_btn')}
          </button>
        </div>
      )}
    </>
  );
};

export default DepositInfo;
