import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { endpoint } from 'services/endpoint';
import requestService from 'services/http/interceptor/request-service';
import { showError } from 'utils/showError';
import { popupOpen } from '../popup/popup-slice';
import { t } from 'i18next';
import { IReferralsRequest } from './referral-types';

export const getReferralBalance = createAsyncThunk(
  'referral/balance',
  async () => {
    try {
      const response = await requestService.get(endpoint.referral.BALANCE);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const transferReferralToBalance = createAsyncThunk(
  'referral/transfer-to-balance',
  async (payload: string, thunkApi) => {
    try {
      const response = await requestService.put(
        endpoint.referral.TRANSFER_TO_BALANCE
      );
      thunkApi.dispatch(
        popupOpen({ text: `${payload} USD ${t('Page.Referral.success_text')}` })
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getReferrals = createAsyncThunk(
  'referral/get-referrals',
  async (payload: IReferralsRequest) => {
    try {
      const response = await requestService.get(
        endpoint.referral.GET_REFERRALS,
        payload
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getRewards = createAsyncThunk(
  'referral/get-rewards',
  async (payload: IReferralsRequest) => {
    try {
      const response = await requestService.get(
        endpoint.referral.GET_REFERRAL_COMMISSIONS,
        payload
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getTransferHistory = createAsyncThunk(
  'referral/get-history',
  async (payload: IReferralsRequest) => {
    try {
      const response = await requestService.get(
        endpoint.referral.GET_REFERRAL_TRANSFER_HISTORY,
        { per_page: 8, ...payload }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
