import React from 'react';
import CashTransaction from 'components/TradingHistorys/CashTransaction';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';

const CashTransactionPage = () => {
  const appTitle = APP_NAME || '';
  return (
    <>
      <Helmet>
        <title>{`${appTitle} | Deposit`}</title>
      </Helmet>
      <CashTransaction />
    </>
  );
};

export default CashTransactionPage;
