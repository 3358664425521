import { ERoutes } from '../enum/types';
import PublicLayout from '../layouts/PublicLayout';
import EmailConfirm from '../pages/Auth/EmailConfirm';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import Login from '../pages/Auth/Login';
import Registration from '../pages/Auth/Registration';

const PublicRoutes = {
  path: ERoutes.home,
  element: <PublicLayout />,
  children: [
    {
      element: <Login />,
      path: ERoutes.login,
    },
    {
      element: <Registration />,
      path: ERoutes.registration,
    },
    {
      element: <EmailConfirm />,
      path: ERoutes.emailConfirm,
    },
    {
      element: <ForgotPassword />,
      path: ERoutes.forgotPass,
    },
  ],
};

export default PublicRoutes;
