import { useTranslation } from 'react-i18next';
import TradeHistoryTable from './TradeHistoryTable';

const TradingHistorys = () => {
  const { t } = useTranslation();
  return (
    <div className='content'>
      <div className='content-inside'>
        <div className='content-title'>
          <p>{t('Page.TradeHistory.title')}</p>
        </div>
        <TradeHistoryTable />
      </div>
    </div>
  );
};

export default TradingHistorys;
