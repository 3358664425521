import i18n from 'i18next';

export const moneyFormat = (
  value: number | string | null | undefined,
  minDecimals?: number,
  maxDecimals?: number,
  compact?: boolean
) => {
  if (value) {
    const money = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: minDecimals || 0,
      maximumFractionDigits: maxDecimals || 2,
      notation: compact ? 'compact' : 'standard',
      compactDisplay: compact ? 'short' : 'long',
    });

    const valueNum = Number(
      Number(value)
        .toFixed((maxDecimals || 2) + 1)
        .slice(0, -1)
    );

    if (valueNum === 0) return 0;
    return money.format(valueNum).toLowerCase();
  }
  return 0;
};
