import { Link, useLocation } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../assets/svg-icon';
import { ERoutes } from '../../../enum/types';
import {
  changePassword,
  forgotPassword,
} from '../../../store/redux-toolkit/auth/auth-thunks';
import { useTranslation } from 'react-i18next';
import { IForgotSubmitValue } from '../../../components/Forms/ForgotPassForm/types';
import ForgotPassForm from '../../../components/Forms/ForgotPassForm';
import ChangePassForm from '../../../components/Forms/ChangePassForm';
import { IChangeSubmitValue } from '../../../components/Forms/ChangePassForm/types';
import { useAppDispatch } from 'hooks/redux';

const ForgotPassword = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const handleSubmit = (values: IForgotSubmitValue) => {
    dispatch(forgotPassword(values));
  };

  const handleChangeSubmit = (values: IChangeSubmitValue) => {
    if (location.search) {
      dispatch(
        changePassword({
          password: values.password,
          token: location.search.slice(1),
        })
      );
    }
  };

  return (
    <div className='authorization-section'>
      <div className='authorization-section__container'>
        <div className='authorization'>
          <div className='back-page'>
            <Link to={ERoutes.login}>
              <span className='back-page__icon'>
                <ArrowLeftIcon />
              </span>
              <span>{t('ForgotPassword.back')}</span>
            </Link>
          </div>
          {location.search ? (
            <ChangePassForm changePassSubmit={handleChangeSubmit} />
          ) : (
            <ForgotPassForm forgotPassSubmit={handleSubmit} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
