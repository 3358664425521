import { ERoutes } from '../../../enum/types';
import { Link } from 'react-router-dom';
import { IRegisterSubmitValue } from '../../../components/Forms/RegisterForm/types';
import { RegisterForm } from '../../../components/Forms/RegisterForm';
import { registration } from '../../../store/redux-toolkit/auth/auth-thunks';

import ModalWrapper from '../../../ui/ModalWrapper';
import { useEffect, useState } from 'react';
import { BigCrossIcon } from '../../../assets/svg-icon';
import { clearErrors } from '../../../store/redux-toolkit/auth/auth-slice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

const Registration = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { errors } = useAppSelector((store) => store.auth);
  const { isDemo } = useAppSelector((state) => state.account);
  const [openModal, setOpenModal] = useState(false);

  const handleRegisterSubmit = (values: IRegisterSubmitValue) => {
    dispatch(registration(values));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    dispatch(clearErrors());
  };

  useEffect(() => {
    if (
      errors.email &&
      errors.email[0] === 'the_email_has_already_been_taken'
    ) {
      setOpenModal(true);
    }
  }, [errors]);

  return (
    <div className='authorization-section'>
      <div className='authorization-section__container'>
        <div className='authorization'>
          <p className='authorization__title '>
            {t('Registration.title', { demo: isDemo ? 'Demo' : '' })}
          </p>
          <div className='authorization__details'>
            <p>
              {t('Registration.question')}{' '}
              <Link to={ERoutes.login} className='link'>
                {t('Login.link')}
              </Link>
            </p>
          </div>
          <RegisterForm registerSubmit={handleRegisterSubmit} />
        </div>
      </div>
      <ModalWrapper openModal={openModal} setOpenModal={handleCloseModal}>
        <div className='popup-header'>
          <p className='popup-header__title'>
            {t('Registration.email_is_busy')}
          </p>
        </div>
        <div className='popup-body'>
          <div className='form-icon form-icon--error'>
            <div className='form-icon__item'>
              <BigCrossIcon />
            </div>
          </div>
          <div className='popup-text'>
            <p> {t('Registration.please_another')}</p>
          </div>
        </div>
        <div className='popup-footer'>
          <div className='popup-submit '>
            <button
              type='button'
              className='button button--size2 button--full-width button--column'
              onClick={handleCloseModal}
            >
              {t('Button.confirm')}
            </button>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default Registration;
