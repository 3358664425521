import { CloseIcon } from 'assets/svg-icon';
import { ERoutes } from 'enum/types';
import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { Popup } from 'reactjs-popup';

export interface IPopupProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  children?: ReactNode;
  customClass?: string;
  typePopup?: 'info' | 'error' | 'success';
}

const ModalWrapper: FC<IPopupProps> = ({
  openModal,
  setOpenModal,
  children,
  typePopup,
  customClass,
}) => {
  const closeModal = () => setOpenModal(false);
  const location = useLocation();
  let insideClass = '';
  let overlayStyle = {};

  if (location.pathname === ERoutes.trade) {
    insideClass = 'popup-window--black';
    overlayStyle = { background: 'rgba(18, 20, 22, 0.9)' };
  }

  return (
    <Popup
      open={openModal}
      closeOnDocumentClick
      onClose={closeModal}
      closeOnEscape
      lockScroll
      overlayStyle={overlayStyle || undefined}
    >
      <div className={`popup-window__inside ${insideClass}`}>
        <div className={`popup ${customClass}`}>
          <button type='button' onClick={closeModal} className='popup__close'>
            <CloseIcon />
          </button>
          {children}
        </div>
      </div>
    </Popup>
  );
};

export default ModalWrapper;
