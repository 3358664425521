import {
  getAssetsToWithdrawal,
  getEstimateAmount,
  checkValidAddress,
  getWithdrawalFee,
  getDepositInfo,
} from './depWith-thunk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DepositWithdrawalState,
  IAssetWithdraw,
  ICheckWithdrawAddressResponse,
  IDepositInfo,
  IErrorsText,
  IEstimateAmountResponse,
  IWithdrawalFee,
} from './depWith-type';

const initialState: DepositWithdrawalState = {
  depositLoading: false,
  assets: null,
  estimateAmount: null,
  errors: {},
  withdrawalFee: null,
  depositInfo: null,
};

export const depositWithdrawalSlice = createSlice({
  name: 'deposit-withdrawal',
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<IErrorsText>) => {
      state.errors = payload;
    },
    clearErrors: (state) => {
      state.errors = {};
    },
    clearEstimateAmount: (state) => {
      state.estimateAmount = null;
      state.withdrawalFee = null;
    },
    clearFee: (state) => {
      state.withdrawalFee = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAssetsToWithdrawal.fulfilled,
        (state, { payload }: PayloadAction<IAssetWithdraw[]>) => {
          state.assets = payload;
        }
      )
      .addCase(
        getEstimateAmount.fulfilled,
        (state, { payload }: PayloadAction<IEstimateAmountResponse>) => {
          state.estimateAmount = payload;
          state.errors = { ...state.errors, amount_usd: '' };
        }
      )
      .addCase(
        getEstimateAmount.rejected,
        (state, { payload }: PayloadAction<any>) => {
          state.estimateAmount = null;
          state.errors = { ...state.errors, ...payload };
        }
      )
      .addCase(
        checkValidAddress.fulfilled,
        (state, { payload }: PayloadAction<ICheckWithdrawAddressResponse>) => {
          state.errors = {
            ...state.errors,
            valid_address: payload.is_valid ? '' : 'address_is_not_valid',
          };
        }
      )
      .addCase(
        getWithdrawalFee.fulfilled,
        (state, { payload }: PayloadAction<IWithdrawalFee>) => {
          state.withdrawalFee = payload;
        }
      )
      .addCase(
        getDepositInfo.fulfilled,
        (state, { payload }: PayloadAction<IDepositInfo | undefined>) => {
          state.depositInfo = payload || null;
        }
      );
  },
});
export const { setErrors, clearErrors, clearEstimateAmount, clearFee } =
  depositWithdrawalSlice.actions;
export default depositWithdrawalSlice.reducer;
