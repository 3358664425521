import { showOpenPositions } from './../position/position-thunks';
import {
  IOpenOrdersRequest,
  IUpdateTpSlRequest,
  ICreateOrderRequest,
  IDeleteTpSlRequest,
  ICancelOrderRequest,
  ICancelAllOrdersRequest,
  IAllOrdersRequest,
  IUpdateDateExpirationRequest,
} from './order-types';
import { endpoint } from '../../../services/endpoint';
import requestService from 'services/http/interceptor/request-service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { showError } from 'utils/showError';
import { pendingOrderSuccess } from './utils';
import { getTradeData } from '../account/account-thuks';

export const getAllOrders = createAsyncThunk(
  'order/all-orders',
  async (params: IAllOrdersRequest, thunkApi) => {
    try {
      const response = await requestService.get(
        endpoint.order.GET_ALL_ORDERS,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getOpenOrders = createAsyncThunk(
  'order/open-orders',
  async (params: IOpenOrdersRequest) => {
    try {
      const response = await requestService.get(
        endpoint.order.GET_OPEN_ORDERS,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const updateTpSlOrder = createAsyncThunk(
  'order/update-tp-sl',
  async (params: IUpdateTpSlRequest) => {
    try {
      const response = await requestService.post(
        endpoint.order.UPDATE_TP_SL,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const createOrder = createAsyncThunk(
  'order/create',
  async (params: ICreateOrderRequest, thunkApi) => {
    try {
      const response = await requestService.post(
        endpoint.order.CREATE_ORDER,
        params
      );
      if (params.trigger_price) {
        thunkApi.dispatch(getOpenOrders({ is_demo: params.is_demo }));
      } else {
        thunkApi.dispatch(showOpenPositions({ is_demo: params.is_demo }));
      }
      thunkApi.dispatch(getTradeData({ is_demo: params.is_demo }));

      pendingOrderSuccess(response.data, params.asset_code);
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const deleteTpSlOrder = createAsyncThunk(
  'order/delete-tp-sl',
  async (params: IDeleteTpSlRequest, thunkApi) => {
    try {
      const response = await requestService.put(
        endpoint.order.DELETE_TP_SL,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const cancelOrder = createAsyncThunk(
  'order/cancel-order',
  async (params: ICancelOrderRequest, thunkApi) => {
    try {
      await requestService.put(endpoint.order.CANCEL_ORDER, params);
      thunkApi.dispatch(getOpenOrders({ is_demo: params.is_demo }));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const cancelAllOrders = createAsyncThunk(
  'order/cancel-orders',
  async (params: ICancelAllOrdersRequest, thunkApi) => {
    try {
      await requestService.put(endpoint.order.CANCEL_ALL_ORDER, params);
      thunkApi.dispatch(getOpenOrders({ is_demo: params.is_demo }));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const updateDateExpirationOrder = createAsyncThunk(
  'order/update-expiration',
  async (params: IUpdateDateExpirationRequest, thunkApi) => {
    try {
      const response = await requestService.put(
        endpoint.order.UPDATE_EXPIRATION,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
