import { useAppSelector } from 'hooks/redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Footer from '../../layouts-element/Footer';
import Header from '../../layouts-element/Header';
import Sidebar from '../../layouts-element/Sidebar';
import { useEffect } from 'react';
import { setDemo } from '../../store/redux-toolkit/account/account-slice';
import { useDispatch } from 'react-redux';

const AuthLayout = () => {
  const { isAuth } = useAppSelector((store) => store.auth);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDemo(0));
  }, []);

  if (!isAuth)
    return <Navigate to='/login' state={{ from: location }} replace />;

  return (
    <div className='wrapper'>
      <Header hideDemo />
      <main>
        <section className='setting-section'>
          <div className='page-wrap'>
            <Sidebar />
            <Outlet />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AuthLayout;
