import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { INetwork } from 'store/redux-toolkit/depositWithdrawal/depWith-type';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';

interface IConfirmWithdrawalProps {
  confirm: () => void;
  estimateAmount: string;
  fee: string;
  address: string;
  network: INetwork | null;
  code: string;
}

const ConfirmWithdrawal: FC<IPopupProps & IConfirmWithdrawalProps> = ({
  openModal,
  setOpenModal,
  confirm,
  address,
  fee,
  network,
  estimateAmount,
  code,
}) => {
  const { t } = useTranslation();
  return (
    <ModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      customClass='popup--width-580'
    >
      <div className='popup-header'>
        <p className='popup-header__title'>{t('Page.WithdrawCrypto.title')}</p>
      </div>
      <div className='popup-body'>
        <div className='popup-body-item'>
          <div className='withdrawal-info-pop'>
            <p>
              {t('Page.WithdrawCrypto.amount')}{' '}
              <span>
                Receive {estimateAmount} {code} (Platform fee 0.0005 {code})
              </span>
            </p>
            <p>
              {t('Page.WithdrawCrypto.address')} <span>{address}</span>
            </p>
            <p>
              {t('Page.WithdrawCrypto.network')}{' '}
              <span>
                {network?.network.toUpperCase()} (
                {network?.network_name.toUpperCase()})
              </span>
            </p>
          </div>
          <div className='withdrawal-info-list-pop'>
            <ul>
              <li>{t('Page.WithdrawCrypto.pop_up_notation_1')}</li>
              <li>{t('Page.WithdrawCrypto.pop_up_notation_2')}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='popup-footer'>
        <button
          className='button button--size2 button--full-width'
          onClick={confirm}
        >
          {t('Button.confirm')}
        </button>
      </div>
    </ModalWrapper>
  );
};

export default ConfirmWithdrawal;
