import {
  IDepositTransactionsRequest,
  IDemoRequest,
  IWithdrawalListRequest,
  ITradeBalanceTransactionsRequest,
  IGetDepositStatusPayload,
} from './account-types';
import { endpoint } from './../../../services/endpoint';
import requestService from 'services/http/interceptor/request-service';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { showError } from 'utils/showError';

export const getDepositsTransactions = createAsyncThunk(
  'account/deposit-transactions',
  async (params: IDepositTransactionsRequest) => {
    try {
      const response = await requestService.get(
        endpoint.account.GET_USER_DEPOSITS_LIST,
        { per_page: 8, ...params }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getAccountData = createAsyncThunk(
  'account/data',
  async (params: IDemoRequest) => {
    try {
      const response = await requestService.get(
        endpoint.account.GET_ACCOUNT_DATA,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getTradeData = createAsyncThunk(
  'account/trade-data',
  async (params: IDemoRequest) => {
    try {
      const response = await requestService.get(
        endpoint.account.GET_TRADE_DATA,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getWithdrawalTransactions = createAsyncThunk(
  'account/withdrawal-transactions',
  async (params: IWithdrawalListRequest) => {
    try {
      const response = await requestService.get(
        endpoint.account.GET_USER_WITHDRAWAL_LIST,
        { per_page: 8, ...params }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getTradeBalanceTransactions = createAsyncThunk(
  'account/trade-balance-transactions',
  async (params: ITradeBalanceTransactionsRequest) => {
    try {
      const response = await requestService.get(
        endpoint.GET_TRADE_BALANCE_TRANSACTIONS,
        { per_page: 8, ...params }
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const getDepositStatus = createAsyncThunk(
  'account/get-deposit-status',
  async (payload: IGetDepositStatusPayload) => {
    const { apiParams, onSuccess } = payload;
    try {
      const response = await requestService.get(
        endpoint.account.DEPOSIT_STATUS,
        apiParams
      );
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
