import {
  getNotifications,
  toggleNotification,
  lockUnlockAllNotifications,
  getSettingBySlug,
} from './setting-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Theme } from 'react-toastify';
import { ISettingsState, ISettingsResponse } from './settings-types';

export const initialState: ISettingsState = {
  success: '',
  error: '',
  errors: {},
  theme: 'light',
  notifications: null,
  settingList: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTheme: (state, { payload }: PayloadAction<Theme>) => {
      state.theme = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getNotifications.fulfilled,
        (store, { payload }: PayloadAction<[string]>) => {
          store.notifications = payload;
        }
      )
      .addCase(
        toggleNotification.fulfilled,
        (store, { payload }: PayloadAction<[string]>) => {
          store.notifications = payload;
        }
      )
      .addCase(
        lockUnlockAllNotifications.fulfilled,
        (store, { payload }: PayloadAction<[string]>) => {
          store.notifications = payload;
        }
      )
      .addCase(
        getSettingBySlug.fulfilled,
        (state, { payload }: PayloadAction<ISettingsResponse>) => {
          state.settingList = payload;
        }
      );
  },
});
export const { setTheme } = settingsSlice.actions;
export default settingsSlice.reducer;
