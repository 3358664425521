import { FC, useEffect } from 'react';
import {
  socketSendMessage,
  socketRemoveFromQueue,
} from 'store/redux-toolkit/sockets/socket-slice';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { ISocketWrapper } from './types';
import { ESocketEvent } from 'enum/types';

const SocketWrapper: FC<ISocketWrapper> = ({ children }) => {
  const { isConnecting, subscriptionsQueue } = useAppSelector(
    (state) => state.sockets
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    const messageType = subscriptionsQueue[0];
    if (!isConnecting && messageType) {
      dispatch(socketRemoveFromQueue(messageType));
      dispatch(socketSendMessage([5, messageType as ESocketEvent]));
    }
  }, [isConnecting, subscriptionsQueue]);

  return <>{children}</>;
};

export default SocketWrapper;
