import { store } from 'store/store';
import { resetAuthState } from '../../../store/redux-toolkit/auth/auth-slice';
import { IAxiosError } from './types';
import { NotificationContainer } from 'ui/notificationContainer';
import { t } from 'i18next';

const onError = (error: IAxiosError) => {
  const status = error.response ? error.response.status : null;
  const errorResponse: any = error.config;

  if (status === 401 && !errorResponse._retry) {
    if (store.getState().auth.isAuth) {
      store.dispatch(resetAuthState());
      NotificationContainer(t(`Errors.unauthenticated`), 'info');
    }
  }
  return Promise.reject(error);
};
export default onError;
