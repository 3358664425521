import { ERoutes } from 'enum/types';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowBorderLeft, ArrowBorderRight } from 'assets/svg-icon';
import useInputNum from 'hooks/useInputNum';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { depositRequest } from 'store/redux-toolkit/depositWithdrawal/depWith-thunk';
import DepositInfo from './DepositInfo';

const Deposit = () => {
  const { t } = useTranslation();
  const amount = useInputNum('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleContinue = () => {
    const value = Number(amount.value);
    if (value > 0) {
      dispatch(depositRequest({ amount: value }));
      navigate(`${ERoutes.account}/${ERoutes.depositHistory}`);
    }
  };

  return (
    <div className='deposit-content'>
      <DepositInfo />
      <div className='deposit-content-inside'>
        <div className='deposit-content-header'>
          <div className='deposit-content-header-left'>
            <Link to={ERoutes.account} className='deposit-content-header__back'>
              <ArrowBorderLeft />
            </Link>
          </div>
          <div className='deposit-content-header-right'>
            <Link
              to={ERoutes.withdrawCrypto}
              className='button button--type3 button--size4 button--width-auto'
            >
              {t('Page.WithdrawCrypto.title')}
            </Link>
            <Link
              to={ERoutes.withdrawCrypto}
              className='deposit-content-header__back'
            >
              <ArrowBorderRight />
            </Link>
          </div>
        </div>
        <div className='deposit-content-item'>
          <div className='deposit-content-item__title'>
            <p>{t('Page.Deposit.enter_amount')}</p>
          </div>
          <div className='input'>
            <div className='input__name'>
              <p>{t('Global.amount')} USD</p>
            </div>
            <div className='input-wrapper'>
              <input
                className='input-item'
                type='text'
                placeholder={t('Page.Deposit.enter_amount') || ''}
                {...amount}
              />
            </div>
          </div>
        </div>

        <div className='deposit-content-item'>
          <button
            className='button button--size2 button--full-width'
            disabled={!amount.value}
            onClick={handleContinue}
          >
            {t('Button.continue')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
