import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../enum/types';
import { AuthState, IUserResponse, IUserRegisterResponse } from './auth-types';

import { confirmEmail, login, registration, logout } from './auth-thunks';

const initialState: AuthState = {
  user: undefined,
  loadingState: LoadingStatus.NEVER,
  success: '',
  error: '',
  errors: {},
  isAuth: false,
  token: null,
  token_expired_at: null,
  socketToken: null,
  registerData: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => {
      return initialState;
    },
    clearErrors: (state) => {
      state.error = '';
      state.errors = {};
    },
    clearSuccess: (state) => {
      state.success = '';
    },
    resetRegisterState: (state) => {
      state.registerData = undefined;
    },
    updateSelectedAssetState: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      // @ts-ignore
      state.user.data.selected_asset = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(
        login.fulfilled,
        (state, { payload }: PayloadAction<IUserResponse>) => {
          state.user = payload.user_data;
          state.isAuth = true;
          state.token = payload.token;
          state.loadingState = LoadingStatus.LOADED;
          state.token_expired_at = payload.token_expired_at;
          state.socketToken = payload.socket_token;
        }
      )
      .addCase(login.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.error = payload;
      })
      .addCase(registration.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(
        registration.fulfilled,
        (state, { payload }: PayloadAction<IUserRegisterResponse>) => {
          state.loadingState = LoadingStatus.LOADED;
          state.registerData = payload;
        }
      )
      .addCase(confirmEmail.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(confirmEmail.fulfilled, (state) => {
        state.registerData = undefined;
        state.success = 'email-confirm';
        state.loadingState = LoadingStatus.LOADED;
      })
      .addCase(
        confirmEmail.rejected,
        (state, { payload }: PayloadAction<any>) => {
          state.error = payload;
          state.loadingState = LoadingStatus.LOADED;
        }
      )
      .addCase(logout.fulfilled, (state) => {
        state.success = 'logout-confirmed';
      });
  },
});

export const {
  resetAuthState,
  clearErrors,
  resetRegisterState,
  clearSuccess,
  updateSelectedAssetState,
} = authSlice.actions;
export default authSlice.reducer;
