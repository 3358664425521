import { CloseIcon } from 'assets/svg-icon';
import classNames from 'classnames';
import { ERoutes, ETransactionType } from 'enum/types';
import { useAppSelector } from 'hooks/redux';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IPosition } from 'store/redux-toolkit/position/position-types';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';
import { addZeros } from 'utils/integers';

interface IPositionInfoProps {
  position: IPosition;
}

const PositionInfo: FC<IPopupProps & IPositionInfoProps> = ({
  position,
  openModal,
  setOpenModal,
}) => {
  const { t } = useTranslation();
  const moneyFormat = useMoneyFormat();
  const location = useLocation();
  const {
    asset,
    transaction_type,
    amount,
    margin_amount,
    open_price,
    swap,
    order_id,
    id,
    created_at,
    leverage,
  } = position;

  const { assets } = useAppSelector((state) => state.asset);
  const foundAsset = assets?.find((el) => el.id === asset.id);
  const currentPrice =
    transaction_type === ETransactionType.buy
      ? foundAsset?.bid
      : foundAsset?.ask;

  const profit = currentPrice
    ? (transaction_type === ETransactionType.buy
        ? currentPrice - open_price
        : open_price - currentPrice) *
      amount *
      leverage
    : 0;

  const netProfit = profit - swap;

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <ModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      customClass={`popup--width-690${
        location.pathname === ERoutes.trade ? ' popup-tool-information' : ''
      }`}
    >
      <div className='popup-header'>
        <p className='popup-header__title'>
          {asset.code}{' '}
          <span className='value-text-icon'>{asset.market.name}</span>
        </p>
        <div className='popup-text'>
          <p>{asset.name} to American Dollar</p>
        </div>
      </div>
      <div className='popup-body'>
        <div className='popup-position-information popup-position-information--type2'>
          <div className='popup-position-information__item'>
            <div className='popup-position-information__title'>
              <p>{t('Table.type')}</p>
            </div>
            <div className='popup-position-information__text'>
              <p className={transaction_type}>
                {t(`Order.${transaction_type}`)}
              </p>
            </div>
          </div>
          <div className='popup-position-information__item'>
            <div className='popup-position-information__title'>
              <p>{t('Table.volume')}</p>
            </div>
            <div className='popup-position-information__text'>
              <p>{amount}</p>
            </div>
          </div>
          <div className='popup-position-information__item'>
            <div className='popup-position-information__title'>
              <p>{t('Table.margin')}</p>
            </div>
            <div className='popup-position-information__text'>
              <p>{margin_amount}</p>
            </div>
          </div>
          <div className='popup-position-information__item popup-position-information__item--ta-r'>
            <div className='popup-position-information__title'>
              <p>{t('Table.net')} P/L</p>
            </div>
            <div className='popup-position-information__text'>
              <p>
                <span
                  className={classNames({
                    red: netProfit < 0,
                    green: netProfit > 0,
                  })}
                >
                  {moneyFormat(netProfit, 2, 2)}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className='popup-position-information-title'>
          <p>{t('Table.tp_sl')}</p>
          <p>{t('Table.detail')}</p>
        </div>
        <div className='popup-body-item'>
          <div className='withdrawal-info-pop withdrawal-info-pop--type5'>
            <div className='withdrawal-info-pop--type3'>
              <div>
                <p>
                  {t('Table.opening_price')} <br /> <span>{open_price}</span>
                </p>
                <p>
                  {t('Table.market_price')} <br />{' '}
                  <span>
                    {transaction_type === ETransactionType.buy
                      ? foundAsset?.bid
                      : foundAsset?.ask}
                  </span>
                </p>
              </div>
              <div>
                <p>
                  {t('Table.rollover')}
                  <br />{' '}
                  <span>{swap > 0.01 ? moneyFormat(swap, 0, 2) : '-'}</span>
                </p>
              </div>
            </div>

            <div className='withdrawal-info-pop--type3'>
              <div>
                <p>
                  {t('Table.order')} <br /> <span>{addZeros(order_id)}</span>
                </p>
                <p>
                  {t('Table.id_position')} <br /> <span>{addZeros(id)}</span>
                </p>
              </div>
              <div>
                <p>
                  {t('Table.time_open')} <br />{' '}
                  <span>
                    {moment(created_at).format('DD-MM-YYYY')} <br />
                    {moment(created_at).format('HH:mm:ss')}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default PositionInfo;
