import React from 'react';
import logo from '../../assets/images/logo_white.png';
import { COMPANY_NAME } from '../../services/constants/env';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-container'>
        <div className='footer-top'>
          <a href='#' className='logo'>
            <img className='footer-logo' src={logo} alt='' />
          </a>
          <div className='copyright'>
            <p>{COMPANY_NAME} © 2023. All Rights Reserved.</p>
            <p>Общая информация: support@cryptoforex.trade</p>
            <p>Техническая поддержка: support@cryptoforex.trade</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
