import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import PendingOrderRow from './PendingOrderRow';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  cancelAllOrders,
  getOpenOrders,
  updateDateExpirationOrder,
} from 'store/redux-toolkit/order/order-thunks';
import { IOrder } from 'store/redux-toolkit/order/order-types';
import { useLocation } from 'react-router-dom';
import { ERoutes, ESocketEvent, ETransactionType } from 'enum/types';
import UpdateTpSl from 'components/Modals/UpdateTpSl';
import TablePlug from 'layouts-element/TablePlug';
import UpdateExpiration from 'components/Modals/UpdateExpiration';
import { socketSendMessage } from 'store/redux-toolkit/sockets/socket-slice';

const PendingOrdersTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { isDemo } = useAppSelector((state) => state.account);
  const { isAuth, user } = useAppSelector((state) => state.auth);
  const { openOrders } = useAppSelector((state) => state.order);
  const { reconnectCount } = useAppSelector((state) => state.sockets);
  const [curOrder, setCurOrder] = useState<IOrder | null>(null);
  const [typeTransaction, setTypeTransaction] = useState(ETransactionType.tp);

  const [openSlModal, setOpenSlModal] = useState(false);
  const [openExpModal, setOpenExpModal] = useState(false);

  const handleUpdateTpSl = (order: IOrder, type: ETransactionType) => {
    setOpenSlModal(true);
    setCurOrder(order);
    setTypeTransaction(type);
  };

  const updateDateExpiration = (order: IOrder) => {
    setOpenExpModal(true);
    setCurOrder(order);
  };

  const handleCancelAllOrders = () => {
    if (user) dispatch(cancelAllOrders({ is_demo: isDemo, user_id: user?.id }));
  };

  useEffect(() => {
    if (isAuth) {
      dispatch(getOpenOrders({ is_demo: isDemo }));
    }
  }, [isDemo]);

  useEffect(() => {
    if (isAuth) {
      dispatch(socketSendMessage([5, ESocketEvent.orders]));
    }
    return () => {
      dispatch(socketSendMessage([6, ESocketEvent.orders]));
    };
  }, [isAuth, reconnectCount]);

  return (
    <div
      className={`table ${
        location.pathname === ERoutes.trade
          ? 'table--type2 table--size3 table--black table--market table--market-pending-orders'
          : 'table--pending-orders'
      }`}
    >
      <div className='table-header'>
        <div className='tr'>
          <div className='td'>
            <p>{t('Table.order')}</p>
          </div>
          <div className='td'>
            <p>{t('Table.type')}</p>
          </div>
          <div className='td'>
            <p>{t('Table.volume')}</p>
          </div>
          <div className='td'>
            <p>{t('Table.opening_price')}</p>
          </div>
          <div className='td'>
            <p>S/L</p>
          </div>
          <div className='td'>
            <p>T/P</p>
          </div>
          <div className='td'>
            <p>{t('Table.market_price')}</p>
          </div>
          <div className='td'>
            <p>{t('Table.expiration')}</p>
          </div>
          <div className='td td--right'>
            <div className='select select--type4'>
              <button
                className='select__current'
                onClick={handleCancelAllOrders}
              >
                <p>{t('Table.cancel_all')}</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='table-body'>
        {isAuth ? (
          openOrders &&
          Object.keys(openOrders).map((item) => (
            <PendingOrderRow
              key={item}
              items={openOrders[item]}
              handleUpdateTpSl={handleUpdateTpSl}
              updateDateExpiration={updateDateExpiration}
            />
          ))
        ) : (
          <TablePlug />
        )}
      </div>

      {curOrder && (
        <>
          <UpdateTpSl
            order={curOrder}
            type={typeTransaction}
            openModal={openSlModal}
            setOpenModal={setOpenSlModal}
          />
          <UpdateExpiration
            order={curOrder}
            openModal={openExpModal}
            setOpenModal={setOpenExpModal}
          />
        </>
      )}
    </div>
  );
};

export default PendingOrdersTable;
