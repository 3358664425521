import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Validation.email_format')
    .required('Validation.required_email'),
  password: Yup.string().required('Validation.password_required'),
});

export const resetSchema = Yup.object().shape({
  email: Yup.string()
    .email('Validation.email_format')
    .required('Validation.required_email'),
  captcha: Yup.string().required('Validation.captcha_required'),
});

export const changePassShema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      'Validation.password_not_secure'
    )
    .required('Validation.password_required')
    .max(25, 'Validations.password_max_length'),
  confirmPassword: Yup.string()
    .required(String('Validation.password_confirm'))
    .oneOf([Yup.ref('password'), null], String('Validation.password_match')),
});

export const changeSettingPassShema = Yup.object().shape({
  old_password: Yup.string().required('Validation.password_required'),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
      'Validation.password_validation'
    )
    .required('Validation.password_required')
    .max(25, 'Validations.password_max_length'),
  confirmPassword: Yup.string()
    .required(String('Validation.password_confirm'))
    .oneOf([Yup.ref('password'), null], String('Validation.password_match')),
});
