import React, { useEffect, useState } from 'react';

import { SearchIcon } from 'assets/svg-icon';
import { ECashTransactionType } from 'enum/types';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import CustomInput from 'ui/CustomInput';
import CustomSelect from 'ui/CustomSelect';
import SelectCalendarRange from 'ui/SelectCalendarRange';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { getTradeBalanceTransactions } from 'store/redux-toolkit/account/account-thuks';
import CashTransactionRow from './CashTransactionRow';
import Pagination from 'ui/Pagination';
import moment from 'moment';

const CashTransaction = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { tradeBalanceTransactions, isDemo } = useAppSelector(
    (state) => state.account
  );
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [transactionType, setTransactionType] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const debounceValue = useDebounce(searchValue, 500);

  useEffect(() => {
    dispatch(
      getTradeBalanceTransactions({
        is_demo: isDemo,
        transaction_type: transactionType || undefined,
        asset_code: debounceValue || undefined,
        date_from: startDate
          ? moment(startDate).format('YYYY-MM-DD')
          : undefined,
        date_to: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
        current_page: currentPage,
      })
    );
  }, [currentPage, debounceValue, startDate, endDate, transactionType, isDemo]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tradeBalanceTransactions?.last_page]);

  return (
    <div className='content'>
      <div className='content-inside'>
        <div className='content-title'>
          <p>{t('Page.CashTransaction.title')}</p>
        </div>
        <div className='table-filter-wrap'>
          <div className='table-filter'>
            <SelectCalendarRange
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              setStartDate={setStartDate}
            />
            <CustomSelect
              obj={ECashTransactionType}
              element={transactionType}
              setElement={setTransactionType}
              placeholder={t('Table.all_types')}
            />
            <CustomInput
              value={searchValue}
              onChange={setSearchValue}
              addlClass='input--size2  input--filter-width2'
              iconRight={<SearchIcon />}
              placeholder={t('Table.search_by_symbol')}
            />
          </div>
        </div>
        <div className='table table--cash-operation'>
          <div className='table-header'>
            <div className='tr'>
              <div className='td'>
                <p>ID</p>
              </div>
              <div className='td'>
                <p>{t('Table.type')}</p>
              </div>
              <div className='td'>
                <p>{t('Table.id_transaction')}</p>
              </div>
              <div className='td'>
                <p>{t('Table.time')}</p>
              </div>
              <div className='td'>
                <p>{t('Table.asset')}</p>
              </div>
              <div className='td'>
                <p>{t('Table.comment')}</p>
              </div>
              <div className='td td--right'>
                <p>{t('Table.amount')}</p>
              </div>
            </div>
          </div>
          <div className='table-body'>
            {tradeBalanceTransactions?.data.map((item) => (
              <CashTransactionRow key={item.id} item={item} />
            ))}
          </div>
        </div>
        {tradeBalanceTransactions && tradeBalanceTransactions.last_page > 1 && (
          <Pagination
            pageCount={tradeBalanceTransactions.last_page}
            forcePage={tradeBalanceTransactions.current_page}
            onPageChange={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default CashTransaction;
