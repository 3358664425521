import { FC } from 'react';
import { Formik, Form, Field } from 'formik';
import { IRegisterForm, IRegisterSubmitValue } from './types';

import { useLocation } from 'react-router-dom';
import PasswordField from '../../../ui/Formik/PasswordField';
import { registerSchema } from '../../../form-validations/register-validation';
import Input from '../../../ui/Formik/Input';
import CheckBox from '../../../ui/Formik/Checkbox';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';

export const RegisterForm: FC<IRegisterForm> = ({ registerSubmit }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const initialValues = {
    email: '',
    password: '',
    referral: search?.slice(1) || '',
    acceptTerms: false,
    cookiePolicy: false,
    captcha: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerSchema}
      onSubmit={(values: IRegisterSubmitValue, { setSubmitting }) => {
        registerSubmit(values);
        setSubmitting(false);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, setFieldValue }) => {
        return (
          <Form className='form'>
            <div className='authorization__form'>
              <Field
                type='email'
                name='email'
                required
                component={Input}
                placeholder={t('Forms.Register.email')}
              />

              <Field
                name='password'
                component={PasswordField}
                placeholder={t('Forms.Register.password')}
              />

              <Field
                name='referral'
                component={Input}
                placeholder={t('Forms.Register.referral')}
              />

              <ReCAPTCHA
                style={{ display: 'inline-block' }}
                sitekey='6LddGigpAAAAAMvn6gwu0YAZCZ47LlJeQRN5f9Y5'
                onChange={(response: any) => {
                  setFieldValue('captcha', '00000000');
                }}
              />
              <div className='authorization__options'>
                <Field name='acceptTerms' component={CheckBox} />
              </div>
              <div className='authorization__options'>
                <Field name='cookiePolicy' component={CheckBox} />
              </div>
            </div>
            <div className='form-submit'>
              {isSubmitting ? (
                <button
                  type='button'
                  className='button button--size2 button--loaded'
                >
                  <span className='circle-load'>
                    <svg
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12 24C11.5481 24 11.1818 23.6337 11.1818 23.1818C11.1818 22.7299 11.5481 22.3636 12 22.3636C17.7237 22.3636 22.3636 17.7237 22.3636 12C22.3636 6.27632 17.7237 1.63636 12 1.63636C6.27632 1.63636 1.63636 6.27632 1.63636 12C1.63636 13.2045 1.84158 14.3798 2.23821 15.4893C2.39032 15.9148 2.16869 16.3831 1.7432 16.5352C1.3177 16.6873 0.849455 16.4657 0.697344 16.0402C0.237738 14.7545 0 13.3929 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24Z'
                        fill='white'
                      />
                    </svg>
                  </span>
                </button>
              ) : (
                <button
                  disabled={!isValid}
                  type='submit'
                  className='button button--size2'
                >
                  {t('Forms.Register.btn')}
                </button>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
