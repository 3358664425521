import React, { ChangeEvent, useEffect, useState } from 'react';

import { ENameNotify } from 'enum/types';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslation } from 'react-i18next';
import {
  getNotifications,
  lockUnlockAllNotifications,
  toggleNotification,
} from 'store/redux-toolkit/settings/setting-thunks';
import CustomSwitch from 'ui/CustomSwitch';

const Notifications = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((store) => store.settings);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleNotification({ name_notification: e.target.name }));
  };

  const handleChangeAllLock = () => {
    dispatch(lockUnlockAllNotifications());
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  return (
    <div className='setting-content'>
      <div className='setting-content-header flex flex-sb'>
        <div className='setting-content-header__title setting-content-header__title--type2'>
          <p>{t('Page.Notifications.title')}</p>
        </div>
      </div>
      <div className='setting-content-body setting-content-body--type2'>
        <div className='notifications'>
          <div className='notifications-list'>
            <div className='notifications-list__item'>
              <div className='notifications-list__item-title'>
                <p>{t('Page.Notifications.enable_notifications')}</p>
              </div>
              <CustomSwitch
                checked={!!notifications?.length}
                onChange={handleChangeAllLock}
              />
            </div>
            {Object.values(ENameNotify).map((el) => (
              <div key={el} className='notifications-list__item'>
                <div className='notifications-list__item-title'>
                  <p>{t(`Page.Notifications.${el}`)}</p>
                </div>
                <CustomSwitch
                  checked={!!notifications?.includes(el)}
                  onChange={handleChange}
                  name={el}
                />
              </div>
            ))}
            {/* <div className='notifications-list__item'>
              <div className='notifications-list__item-title'>
                <p>Уведомления о марже</p>
              </div>
              <div className='switch switch--type2'>
                <label className='switch__label'>
                  <input type='checkbox' className='hidden' />
                  <span className='switch__toggler'></span>
                </label>
              </div>
            </div> */}

            {/* <div className='notifications-list__item'>
              <div className='notifications-list__item-title'>
                <p>Требование торговой маржи</p>
              </div>
              <CustomSwitch checked={marg} onChange={setMarg} />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
