import React, { FC, SyntheticEvent, useRef, useState } from 'react';

import { ArrowDropIcon, EllipsisIcon } from 'assets/svg-icon';
import { useTranslation } from 'react-i18next';
import useOutsideClick from 'hooks/useOutsideClick';
import classNames from 'classnames';
import { ERoutes } from 'enum/types';

interface IClosedDropdownProps {
  label?: string | null;
  closeAll: () => void;
  closeProfitLoss: (val: boolean) => void;
}

const ClosedDropdown: FC<IClosedDropdownProps> = ({
  label,
  closeAll,
  closeProfitLoss,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));

  const handleOpenClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const handleProfitPositions = () => {
    closeProfitLoss(true);
  };
  const handleLossPositions = () => {
    closeProfitLoss(false);
  };

  return (
    <div
      ref={ref}
      className={classNames('select select--type4', {
        active: open,
        'select--black': location.pathname === ERoutes.trade,
      })}
    >
      <button className='select__current' onClick={handleOpenClick}>
        {label ? (
          <>
            <p>{label}</p>
            <span className='select__current-arrow'>
              <ArrowDropIcon />
            </span>
          </>
        ) : (
          <EllipsisIcon />
        )}
      </button>
      <div className='select__drop'>
        <div className='select__drop-scroll'>
          <div className='select__drop-item'>
            <ul>
              <li>
                <button type='button' onClick={closeAll}>
                  {t('Table.all')}
                </button>
              </li>
              <li>
                <button type='button' onClick={handleProfitPositions}>
                  {t('Table.close_profit')}
                </button>
              </li>
              <li>
                <button type='button' onClick={handleLossPositions}>
                  {t('Table.close_losing')}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClosedDropdown;
