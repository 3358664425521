import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IToastifyComponent } from './types';

const ToastifyComponent: FC<IToastifyComponent> = ({ message, toastProps }) => {
  const { t } = useTranslation();
  // console.log(toastProps?.type);
  return (
    // className='notification'
    <div className='notification__info'>
      <div className='notification__title'>
        <p className={`notification__${toastProps?.type}`}>
          {toastProps?.type === 'error' && t('Global.error')}
          {toastProps?.type === 'success' && t('Global.success')}
          {toastProps?.type === 'info' && 'information'}
          {toastProps?.type === 'default' && t('Trade.trade_notif')}
        </p>
      </div>
      <div className='notification__text notification__text--custom'>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default ToastifyComponent;
