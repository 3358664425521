export const BASE_URL = 'back.cryptoforex.trade';

export const API_BASE_URL = `https://${BASE_URL}/api`;

export const WEBSOCKET_URL = `wss://${BASE_URL}:443`;

export const endpoint = {
  auth: {
    LOGIN: '/login',
    REGISTRATION: '/register',
    EMAIL_CONFIRMATION: '/email_confirmation',
    PASSWORD_RESET: '/password_reset',
    PASSWORD_CHANGE: '/user/password',
    RESET_TOKEN: '/reset-token',
    LOGOUT: '/logout',
  },
  settings: {
    SEND_EMAIL_CODE: '/user-settings/send-email-code',
    CHANGE_PASSWORD: '/user-settings/change-password',
    GET_NOTIFICATIONS: '/get-user-notification',
    UPDATE_NOTIFICATIONS: '/update-notifications',
    LOCK_UNLOCK_AII_NOTIFICATIOS: '/lock-unlock-all-user-notification',
    KYC: '/kyc-verification',
    GET_SETTING_BY_SLUG: '/get-setting-by-slug',
  },
  order: {
    GET_ALL_ORDERS: '/get-all-orders',
    GET_OPEN_ORDERS: '/get-open-orders',
    CREATE_ORDER: '/create-order',
    CANCEL_ORDER: '/cancel-order',
    CANCEL_ALL_ORDER: '/cancel-all-order',
    UPDATE_TP_SL: '/create-update-tp-sl-order',
    DELETE_TP_SL: '/delete-tp-sl-order',
    UPDATE_EXPIRATION: '/update-delete-date-expiration-order',
  },
  position: {
    SHOW_OPEN_POSITIONS: '/show-open-positions',
    SHOW_CLOSE_POSITIONS: '/show-close-positions',
    UPDATE_TP_SL_POSITION: '/create-update-tp-sl-position',
    DELETE_TP_SL_POSITION: '/delete-tp-sl-order-by-position',
    CLOSE_ONE_POSITION: '/close-one-position',
    CLOSE_ALL_POSITIONS: '/close-all-positions',
    CLOSE_POSITION_BY_PARAMS: '/close-position-by-params',
  },
  asset: {
    GET_ASSETS: '/assets',
    GET_ACTIVE_MARKETS: '/get-active-markets',
    SET_USER_SELECTED_ASSET: '/set-user-selected-asset',
  },
  DEPOSIT: '/deposit',
  GET_MANUAL_DEPOSIT: '/admin/manual-deposit',
  GET_TRADE_BALANCE_TRANSACTIONS: '/get-trade-balance-transactions',
  referral: {
    BALANCE: '/get-referral-balance',
    TRANSFER_TO_BALANCE: '/transfer-referral-to-balance',
    GET_REFERRALS: '/get-my-referrals',
    GET_REFERRAL_COMMISSIONS: '/get-my-referral-commissions',
    GET_REFERRAL_TRANSFER_HISTORY: '/get-referral-transfer-history',
  },
  account: {
    GET_USER_DEPOSITS_LIST: '/deposit-list',
    GET_USER_WITHDRAWAL_LIST: '/withdrawal-list',
    GET_ACCOUNT_DATA: '/get-account-data',
    GET_UNREALISED_PNL: '/get-unrealised-pnl-by-user',
    GET_TRADE_DATA: '/get-trade-data',
    GET_WITHDRAWAL_FEE: '/withdrawal-fee',
    DEPOSIT_STATUS: '/deposit-status',
  },
  withdrawal: {
    GET_ASSETS: '/currencies',
    GET_ESTIMATED_AMOUNT: '/withdrawal-estimated-amount',
    CHECK_VALID_ADDRESS: '/check-address',
    CREATE_WITHDRAWAL: '/withdrawal',
  },
};
