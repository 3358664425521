import { useEffect, useState } from 'react';
import TradeHistoryRow from './TradeHistoryRow';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import TablePlug from 'layouts-element/TablePlug';
import { useTranslation } from 'react-i18next';
import { showClosedPositions } from 'store/redux-toolkit/position/position-thunks';
import AssetsSelect from 'ui/AssetsSelect';
import { ERoutes } from 'enum/types';
import Pagination from 'ui/Pagination';
import { IPosition } from 'store/redux-toolkit/position/position-types';
import PositionInfoHistory from 'components/Modals/PositionInfoHistory';
import SelectCalendarRange from 'ui/SelectCalendarRange';
import { format } from 'date-fns';

const TradeHistoryTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { closePositions } = useAppSelector((state) => state.position);
  const { isAuth } = useAppSelector((state) => state.auth);
  const { isDemo } = useAppSelector((state) => state.account);

  const [assetId, setAssetId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [curPosition, setCurPosition] = useState<IPosition | null>(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const showInfo = (position: IPosition) => {
    setOpenInfoModal(true);
    setCurPosition(position);
  };

  useEffect(() => {
    if (isAuth) {
      dispatch(
        showClosedPositions({
          is_demo: isDemo,
          asset_id: assetId || undefined,
          current_page: currentPage,
          per_page: 8,
          date_from: startDate ? format(startDate, 'y-MM-dd') : undefined,
          date_to: endDate ? format(endDate, 'y-MM-dd') : undefined,
        })
      );
    }
  }, [isDemo, assetId, currentPage, endDate]);

  useEffect(() => {
    setCurrentPage(1);
  }, [closePositions?.last_page]);

  return (
    <>
      <div
        className={`table-filter ${
          location.pathname === ERoutes.trade
            ? ' table-filter--mt-16 table-filter--mb-0'
            : ''
        }`}
      >
        <div className='select-block'>
          <SelectCalendarRange
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
        </div>

        <AssetsSelect setElement={setAssetId} />
      </div>

      <div
        className={`table ${
          location.pathname === ERoutes.trade
            ? 'table--height-type2 table--type2 table--size3 table--black table--market table--market-trading-history'
            : 'table--trade-history'
        }`}
      >
        <div className='table-header'>
          <div className='tr'>
            <div className='td'>
              <p>{t('Table.simbol')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.order')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.type')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.time_open')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.opening_price')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.time_close')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.closed_price')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.profit')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.net')} P/L</p>
            </div>
            <div className='td'>
              <p>{t('Table.rollover')}</p>
            </div>
            <div className='td'>
              <p></p>
            </div>
          </div>
        </div>
        <div className='table-body'>
          {isAuth ? (
            closePositions &&
            closePositions.data.map((item) => (
              <TradeHistoryRow key={item.id} item={item} showInfo={showInfo} />
            ))
          ) : (
            <TablePlug />
          )}
        </div>
      </div>
      {closePositions && closePositions.last_page > 1 && (
        <Pagination
          pageCount={closePositions?.last_page}
          forcePage={closePositions.current_page}
          onPageChange={setCurrentPage}
        />
      )}
      {curPosition && (
        <PositionInfoHistory
          position={curPosition}
          openModal={openInfoModal}
          setOpenModal={setOpenInfoModal}
        />
      )}
    </>
  );
};

export default TradeHistoryTable;
