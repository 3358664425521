import { useCallback, useEffect, useMemo, useState } from 'react';
import { BigInfoIcon, CopyIcon } from 'assets/svg-icon';

import { useTranslation } from 'react-i18next';

import CopyToClipboard from 'react-copy-to-clipboard';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getReferralBalance,
  transferReferralToBalance,
} from 'store/redux-toolkit/referral/referral-thunks';
import ModalWrapper from 'ui/ModalWrapper';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { Navigate, useParams } from 'react-router';
import RewardsTab from './RewardsTab';
import TransactionHistoryTab from './TransactionHistoryTab';
import ReferralsTab from './ReferralsTab';
import { NavLink } from 'react-router-dom';
import { ERoutes } from 'enum/types';
import { NotificationContainer } from 'ui/notificationContainer';

const Referral = () => {
  const { t } = useTranslation();
  const { tab } = useParams();
  const dispatch = useAppDispatch();
  const { balance } = useAppSelector((state) => state.referral);
  const { user } = useAppSelector((state) => state.auth);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const moneyFormat = useMoneyFormat();

  const handleCopy = () => {
    NotificationContainer(t('Page.Referral.link_copied'), 'info');
  };

  const handleOpenInfoModal = () => {
    setOpenInfoModal(true);
  };

  const handleConfirmTransfer = () => {
    dispatch(transferReferralToBalance(String(balance?.balance)));
    setOpenInfoModal(false);
  };

  const getTabContent = useCallback(() => {
    switch (tab) {
      case 'my-rewards':
        return <RewardsTab />;
      case 'transaction-history':
        return <TransactionHistoryTab />;
      case 'referrals':
        return <ReferralsTab />;
      default:
        return (
          <Navigate to={`${ERoutes.referrals}/my-rewards`} replace={true} />
        );
    }
  }, [tab]);

  useEffect(() => {
    dispatch(getReferralBalance());
  }, []);

  return (
    <div className='account-referral-section'>
      <div className='container container--full-width'>
        <div className='account-referral'>
          <div className='account-referral-title'>
            {t('Page.Referral.copy_tytle')}
          </div>
          <div className='account-referral-link'>
            <CopyToClipboard text={user?.invite_url || ''} onCopy={handleCopy}>
              <div className='copy-button copy-button--type2 copy-button--center'>
                {user?.invite_url}
                <span>
                  <CopyIcon />
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className='account-referral-statistics'>
            <div className='account-referral-title'>
              {t('Page.Referral.statistic_title')}
            </div>
            <div className='account-referral-statistics-list'>
              <div className='account-referral-statistics-list__item'>
                <div className='account-referral-statistics-list__item-title'>
                  <p>{t('Page.Referral.balance')}</p>
                </div>
                <div className='account-referral-statistics-list__item-info'>
                  <div className='account-referral-statistics-list__item-info-counter'>
                    <p>{moneyFormat(balance?.balance || 0)}</p>
                  </div>
                </div>
              </div>
              <div className='account-referral-statistics-list__item'>
                <div className='account-referral-statistics-list__item-title'>
                  <p>{t('Page.Referral.referral_users')}</p>
                </div>
                <div className='account-referral-statistics-list__item-info'>
                  <div className='account-referral-statistics-list__item-info-counter'>
                    <p>{moneyFormat(balance?.total_referral_users || 0)}</p>
                  </div>
                </div>
              </div>
              <div className='account-referral-statistics-list__item'>
                <div className='account-referral-statistics-list__item-title'>
                  <p>{t('Page.Referral.referral_profit')}</p>
                </div>
                <div className='account-referral-statistics-list__item-info'>
                  <div className='account-referral-statistics-list__item-info-counter'>
                    <p>{moneyFormat(balance?.total_referral_profit || 0)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type='button'
            className='button button--size2 button--regular'
            onClick={handleOpenInfoModal}
            disabled={!!balance && balance.balance <= 0}
          >
            {t('Page.Referral.transfer_to_balance')}
          </button>
          <div className='account-referral-table'>
            <div className='table-filter'>
              <NavLink
                to={`${ERoutes.referrals}/my-rewards`}
                className={({ isActive }) =>
                  `button button--size4 button--width-auto ${
                    !isActive && 'button--type3'
                  }`
                }
              >
                {t('Page.Referral.tab_rewards')}
              </NavLink>
              <NavLink
                to={`${ERoutes.referrals}/referrals`}
                className={({ isActive }) =>
                  `button button--size4 button--width-auto ${
                    !isActive && 'button--type3'
                  }`
                }
              >
                {t('Page.Referral.tab_referrals')}
              </NavLink>
              <NavLink
                to={`${ERoutes.referrals}/transaction-history`}
                className={({ isActive }) =>
                  `button button--size4 button--width-auto ${
                    !isActive && 'button--type3'
                  }`
                }
              >
                {t('Page.Referral.tab_history')}
              </NavLink>
            </div>
            {getTabContent()}
          </div>
        </div>
      </div>
      <ModalWrapper
        openModal={openInfoModal}
        setOpenModal={setOpenInfoModal}
        customClass='popup--width-400'
      >
        <div className='popup-body popup-body--mt-0 popup-body--mb-16'>
          <div className='form-icon'>
            <BigInfoIcon />
          </div>
        </div>
        <div className='popup-header'>
          <p className='popup-header__title'>
            {t('Page.Referral.info_text')} {moneyFormat(balance?.balance)} USD ?
          </p>
        </div>
        <div className='popup-footer'>
          <div className='popup-submit'>
            <button
              className='button button--size2 button--full-width button--column'
              onClick={handleConfirmTransfer}
            >
              {t('Button.confirm')}
            </button>
          </div>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default Referral;
