import { FC, useRef, useState } from 'react';

import { ArrowDropIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import { IAssetWithdraw } from 'store/redux-toolkit/depositWithdrawal/depWith-type';
import classNames from 'classnames';

interface IAssetsWithdrawSelectProps {
  asset: IAssetWithdraw | undefined;
  assets: IAssetWithdraw[] | null;
  setElement: (el: IAssetWithdraw) => void;
  title?: string;
}

const AssetsWithdrawSelect: FC<IAssetsWithdrawSelectProps> = ({
  asset,
  assets,
  setElement,
  title,
}) => {
  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const handleChange = (el: IAssetWithdraw) => {
    setElement(el);
    setOpen(false);
  };

  return (
    <div className='select-block'>
      {title && (
        <div className='select-block__name'>
          <p>{title}</p>
        </div>
      )}
      <div ref={ref} className={classNames('select', { active: open })}>
        <button type='button' className='select__current' onClick={handleClick}>
          {asset && (
            <span className='select-current-currensy select-current-currensy--type2'>
              <span className='select-current-currensy__icon'>
                <img src={asset?.img_path} alt='' />
              </span>
              <span className='select-current-currensy__name'>
                {asset?.code.toUpperCase()}
                <span className='select-current-currensy__name-text'>
                  {asset?.name}
                </span>
              </span>
            </span>
          )}

          <span className='select__current-arrow'>
            <ArrowDropIcon />
          </span>
        </button>
        {assets && (
          <div className='select__drop'>
            <div className='select__drop-scroll'>
              <div className='select__drop-item'>
                <ul>
                  {assets.map((el) => (
                    <li key={el.id}>
                      <button type='button' onClick={() => handleChange(el)}>
                        <span className='select-current-currensy select-current-currensy--type2'>
                          <span className='select-current-currensy__icon'>
                            <img src={el.img_path} alt='' />
                          </span>
                          <span className='select-current-currensy__name'>
                            {el.code.toUpperCase()}
                            <span className='select-current-currensy__name-text'>
                              {el.name}
                            </span>
                          </span>
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetsWithdrawSelect;
