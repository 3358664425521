import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface CustomInputProps {
  text?: string;
}

const CheckBox: FC<CustomInputProps & FieldProps> = ({
  field,
  form: { touched, errors },
  text,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className='checkbox checkbox--no-mb'>
      <label className='checkbox__label'>
        <input type='checkbox' {...field} {...props} className='hidden' />
        <span className=' checkbox__item'>
          <span className='checkbox__item-icon'>
            <svg
              width='10'
              height='8'
              viewBox='0 0 10 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1 4L3.66353 6.5L9 1.5'
                stroke='#1D92A6'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              ></path>
            </svg>
          </span>
        </span>
        <span className='checkbox__text'>
          {field.name === 'acceptTerms' && (
            <>
              {t('Forms.Register.agree_terms')}{' '}
              <Link to='/'>Условиями использования</Link>{' '}
              {t('Forms.Register.agree_terms_and')}{' '}
              <Link to='/'>{t('Forms.Register.agree_terms_privat')}</Link>
            </>
          )}
          {field.name === 'cookiePolicy' && (
            <>
              {t('Forms.Register.agree_terms')}{' '}
              <Link to='/'>{t('Forms.Register.agree_width_cookie')}</Link>
            </>
          )}
          {text}
        </span>
        {touched[field.name] && errors[field.name] && (
          <p className='input-notification--error'>
            {t(String(errors[field.name]))}
          </p>
        )}
      </label>
    </div>
  );
};

export default CheckBox;
