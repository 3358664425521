import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { useTranslation } from 'react-i18next';
import { getTradeData } from 'store/redux-toolkit/account/account-thuks';
import { socketSendMessage } from 'store/redux-toolkit/sockets/socket-slice';
import { ESocketEvent } from 'enum/types';

const TradeTopLine = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const moneyFormat = useMoneyFormat();
  const { tradeData, isDemo } = useAppSelector((state) => state.account);
  const { isAuth } = useAppSelector((state) => state.auth);
  const { reconnectCount } = useAppSelector((state) => state.sockets);

  // Control
  useEffect(() => {
    if (isAuth) {
      dispatch(getTradeData({ is_demo: isDemo }));
    }
  }, [isDemo, isAuth]);

  useEffect(() => {
    dispatch(socketSendMessage([5, ESocketEvent.userTradeData]));

    return () => {
      dispatch(socketSendMessage([6, ESocketEvent.userTradeData]));
    };
  }, [reconnectCount]);

  return (
    <div className='market-top-line'>
      <div className='market-top-line__item'>
        <p>
          {t('Page.Account.final_balance')}{' '}
          <span>
            {!isAuth ? 0 : moneyFormat(tradeData?.balance)}
            <span className='grey'>USD</span>{' '}
          </span>
        </p>
      </div>
      <div className='market-top-line__item'>
        <p>
          {t('Page.Account.available_balance')}{' '}
          <span>
            {!isAuth ? 0 : moneyFormat(tradeData?.capital)}
            <span className='grey'>USD</span>{' '}
          </span>
        </p>
      </div>
      <div className='market-top-line__item'>
        <p>
          {t('Page.Account.used_margin')}{' '}
          <span>
            {!isAuth ? 0 : moneyFormat(tradeData?.used_margin)}{' '}
            <span className='grey'>USD</span>{' '}
          </span>
        </p>
      </div>
      <div className='market-top-line__item'>
        <p>
          {t('Page.Account.available_margin')}{' '}
          <span>
            {!isAuth ? 0 : moneyFormat(tradeData?.available_margin)}{' '}
            <span className='grey'>USD</span>{' '}
          </span>
        </p>
      </div>
      <div className='market-top-line__item'>
        <p>
          {t('Page.Account.level_margin')}{' '}
          <span>
            {!isAuth ? 0 : moneyFormat(tradeData?.level_margin_percent)}%
          </span>
        </p>
      </div>
      <div className='market-top-line__item'>
        <div className='tooltip-wrap tooltip-wrap--center-align'>
          <div className='tooltip-item'>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clipPath='url(#clip0_1148_104023)'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M2.93241 2.93256C4.22879 1.6362 6.02114 0.833496 8.00001 0.833496C9.97888 0.833496 11.7712 1.6362 13.0676 2.93256L12.714 3.28612L13.0676 2.93256C14.364 4.22894 15.1667 6.02129 15.1667 8.00016C15.1667 9.97903 14.364 11.7714 13.0676 13.0678C11.7712 14.3641 9.97888 15.1668 8.00001 15.1668C6.02114 15.1668 4.22879 14.3641 2.93241 13.0678L3.28596 12.7142L2.93241 13.0677C1.63605 11.7714 0.833344 9.97903 0.833344 8.00016C0.833344 6.0213 1.63605 4.22894 2.93241 2.93257L2.93241 2.93256ZM8.00001 1.8335C6.29701 1.8335 4.75603 2.52318 3.63951 3.63967C2.52302 4.75619 1.83334 6.29716 1.83334 8.00016C1.83334 9.70316 2.52302 11.2441 3.63952 12.3606C4.75603 13.4771 6.29701 14.1668 8.00001 14.1668C9.70301 14.1668 11.244 13.4771 12.3605 12.3606C13.477 11.2441 14.1667 9.70316 14.1667 8.00016C14.1667 6.29717 13.477 4.75618 12.3605 3.63967C11.244 2.52318 9.70301 1.8335 8.00001 1.8335Z'
                  fill='#65717D'
                ></path>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M7.99999 3.6665C8.46022 3.6665 8.83332 4.0396 8.83332 4.49984C8.83332 4.96007 8.46022 5.33317 7.99999 5.33317C7.53976 5.33317 7.16666 4.96007 7.16666 4.49984C7.16666 4.0396 7.53976 3.6665 7.99999 3.6665Z'
                  fill='#65717D'
                ></path>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M7 6.6665C7 6.39036 7.22386 6.1665 7.5 6.1665H8.16667C8.44281 6.1665 8.66667 6.39036 8.66667 6.6665V11.3332C8.66667 11.6093 8.44281 11.8332 8.16667 11.8332C7.89052 11.8332 7.66667 11.6093 7.66667 11.3332V7.1665H7.5C7.22386 7.1665 7 6.94265 7 6.6665Z'
                  fill='#65717D'
                ></path>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M6.5 11.3335C6.5 11.0574 6.72386 10.8335 7 10.8335H9.33333C9.60948 10.8335 9.83333 11.0574 9.83333 11.3335C9.83333 11.6096 9.60948 11.8335 9.33333 11.8335H7C6.72386 11.8335 6.5 11.6096 6.5 11.3335Z'
                  fill='#65717D'
                ></path>
              </g>
              <defs>
                <clipPath id='clip0_1148_104023'>
                  <rect width='16' height='16' fill='white'></rect>
                </clipPath>
              </defs>
            </svg>
            <span className='tooltip tooltip--bottom tooltip--center tooltip--arrow-top-center'>
              <span className='tooltip__text tooltip__text--ta-c'>
                <span>{t('Page.Account.unrealized')}</span>
              </span>
            </span>
          </div>
        </div>
        <p>
          {t('Page.Account.unrealized')}{' '}
          <span className={Number(tradeData?.unrealised_pnl) < 0 ? 'red' : ''}>
            {!isAuth ? 0 : moneyFormat(tradeData?.unrealised_pnl)}{' '}
            <span className='grey'>USD</span>{' '}
          </span>
        </p>
      </div>
    </div>
  );
};

export default TradeTopLine;
