import { useEffect, useState } from 'react';
import { ERoutes, ESocketEvent, ETransactionType } from 'enum/types';

import { useTranslation } from 'react-i18next';
import {
  closeAllPositions,
  closePositionProfitLoss,
  showOpenPositions,
} from 'store/redux-toolkit/position/position-thunks';
import OpenPositionRow from './OpenPositionRow';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import TablePlug from 'layouts-element/TablePlug';
import { IPosition } from 'store/redux-toolkit/position/position-types';
import UpdatePositionTpSl from 'components/Modals/UpdatePositionTpSl';
import ClosedDropdown from 'ui/ClosedDropdown';
import PositionInfo from 'components/Modals/PositionInfo';
import { socketSendMessage } from 'store/redux-toolkit/sockets/socket-slice';

const OpenPositionTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isDemo } = useAppSelector((state) => state.account);
  const { isAuth } = useAppSelector((state) => state.auth);
  const { openPositions } = useAppSelector((state) => state.position);
  const { reconnectCount } = useAppSelector((state) => state.sockets);

  const [openSlModal, setOpenSlModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [curPosition, setCurPosition] = useState<IPosition | null>(null);
  const [typeTransaction, setTypeTransaction] = useState(ETransactionType.tp);

  const handleUpdateTpSl = (position: IPosition, type: ETransactionType) => {
    setOpenSlModal(true);
    setCurPosition(position);
    setTypeTransaction(type);
  };

  const showInfo = (position: IPosition) => {
    setOpenInfoModal(true);
    setCurPosition(position);
  };

  const handleCloseAllPositions = () => {
    dispatch(closeAllPositions({ is_demo: isDemo }));
  };

  const handleCloseProfitLoss = (profit: boolean) => {
    dispatch(
      closePositionProfitLoss({
        is_demo: isDemo,
        is_close_profitable_positions: profit,
      })
    );
  };

  useEffect(() => {
    if (isAuth) {
      dispatch(showOpenPositions({ is_demo: isDemo }));
      setInterval(() => {
        dispatch(showOpenPositions({ is_demo: isDemo }));
      }, 10000);
    }
  }, [isDemo]);

  useEffect(() => {
    if (isAuth) {
      dispatch(socketSendMessage([5, ESocketEvent.positions]));
    }
    return () => {
      dispatch(socketSendMessage([6, ESocketEvent.positions]));
    };
  }, [isAuth, reconnectCount]);

  return (
    <div
      className={`table ${
        location.pathname === ERoutes.trade
          ? 'table--type2 table--size2 table--black table--market table--market-open-positions'
          : 'table--open-orders'
      }`}
    >
      <div className='table-header'>
        <div className='tr'>
          <div className='td'>
            <p>{t('Table.order')}</p>
          </div>
          <div className='td'>
            <p>{t('Table.type')}</p>
          </div>
          <div className='td'>
            <p>{t('Table.volume')}</p>
          </div>
          <div className='td td--center'>
            <p>S/L</p>
          </div>
          <div className='td td--center'>
            <p>T/P</p>
          </div>
          <div className='td'>
            <p>{t('Table.opening_price')}</p>
          </div>
          <div className='td'>
            <p>{t('Table.market_price')}</p>
          </div>
          <div className='td'>
            <p>{t('Table.profit')}</p>
          </div>
          <div className='td'>
            <p>{t('Table.net_profit')}</p>
          </div>
          <div className='td'>
            <p>
              {t('Table.net')} <br /> P/L %
            </p>
          </div>
          <div className='td'>
            <p>{t('Table.rollover')}</p>
          </div>
          <div className='td td--right'>
            <ClosedDropdown
              label={t('Table.close_all')}
              closeAll={handleCloseAllPositions}
              closeProfitLoss={handleCloseProfitLoss}
            />
          </div>
        </div>
      </div>
      <div className='table-body'>
        {isAuth ? (
          openPositions &&
          Object.keys(openPositions).map((item) => (
            <OpenPositionRow
              key={item}
              items={openPositions[item]}
              handleUpdateTpSl={handleUpdateTpSl}
              showInfo={showInfo}
            />
          ))
        ) : (
          <TablePlug />
        )}
      </div>
      {curPosition && (
        <>
          <UpdatePositionTpSl
            position={curPosition}
            type={typeTransaction}
            openModal={openSlModal}
            setOpenModal={setOpenSlModal}
          />
          <PositionInfo
            position={curPosition}
            openModal={openInfoModal}
            setOpenModal={setOpenInfoModal}
          />
        </>
      )}
    </div>
  );
};

export default OpenPositionTable;
