import classNames from 'classnames';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowDropIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import imageRu from '../../assets/images/ru.png';
import imageUa from '../../assets/images/ua.png';
import imageGb from '../../assets/images/gb.png';
import imageEs from '../../assets/images/es.png';
import imagePt from '../../assets/images/pt.png';
import imageFr from '../../assets/images/fr.png';
import { useAppDispatch } from 'hooks/redux';

interface ILanguageDropdownProps {
  additionalClass?: string;
}

const LanguageDropdown: FC<ILanguageDropdownProps> = ({ additionalClass }) => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    setOpen(false);
  };

  const getImgLang: { img: string; title: string } = useMemo(() => {
    switch (i18n.language) {
      case 'ru':
        return { img: imageRu, title: 'Рус' };
        break;
      case 'ua':
        return { img: imageUa, title: 'Укр' };
        break;
      case 'en':
        return { img: imageGb, title: 'Eng' };
        break;
      case 'es':
        return { img: imageEs, title: 'Esp' };
        break;
      case 'pt':
        return { img: imagePt, title: 'Por' };
        break;
      case 'fr':
        return { img: imageFr, title: 'Fra' };
        break;
      default:
        return { img: imageRu, title: 'Рус' };
    }
  }, [i18n.language]);

  return (
    <div ref={ref} className={`lang ${additionalClass}`}>
      <div
        className={classNames('nav-item nav-item--drop nav-item--lang', {
          active: open,
        })}
      >
        <button type='button' className='nav-item__link' onClick={handleClick}>
          <span className='dropdown-icon'>
            <img src={getImgLang.img} alt='' />
          </span>
          {getImgLang.title}
          <span className='nav-item__link-arrow'>
            <ArrowDropIcon />
          </span>
        </button>
        <div className='dropdown dropdown--lang '>
          <ul className='dropdown-list'>
            <li>
              <button type='button' onClick={() => changeLanguage('ru')}>
                <span className='dropdown-icon'>
                  <img src={imageRu} alt='' />
                </span>
                Русский
              </button>
            </li>
            <li>
              <button type='button' onClick={() => changeLanguage('en')}>
                <span className='dropdown-icon'>
                  <img src={imageGb} alt='' />
                </span>
                English
              </button>
            </li>
            <li>
              <button type='button' onClick={() => changeLanguage('es')}>
                <span className='dropdown-icon'>
                  <img src={imageEs} alt='' />
                </span>
                Español
              </button>
            </li>
            <li>
              <button type='button' onClick={() => changeLanguage('pt')}>
                <span className='dropdown-icon'>
                  <img src={imagePt} alt='' />
                </span>
                Português
              </button>
            </li>
            <li>
              <button type='button' onClick={() => changeLanguage('fr')}>
                <span className='dropdown-icon'>
                  <img src={imageFr} alt='' />
                </span>
                Français
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LanguageDropdown;
