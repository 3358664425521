import React from 'react';
import { Helmet } from 'react-helmet';
import WithdrawCrypto from '../../components/WithdrawCrypto';
import { APP_NAME } from '../../services/constants/env';

const WithdrawCryptoPage = () => {
  const appTitle = APP_NAME || '';
  return (
    <>
      <Helmet>
        <title>{`${appTitle} | Withdrawal Crypto`}</title>
      </Helmet>
      <WithdrawCrypto />
    </>
  );
};

export default WithdrawCryptoPage;
