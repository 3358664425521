import React, { FC, useState } from 'react';

import { EllipsisIcon } from 'assets/svg-icon';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { setChartAssetKey } from 'store/redux-toolkit/asset/asset-slice';
import { IPosition } from 'store/redux-toolkit/position/position-types';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ERoutes, ETransactionType } from 'enum/types';
import ClosedDropdown from 'ui/ClosedDropdown';
import { closePositionProfitLoss } from 'store/redux-toolkit/position/position-thunks';

interface IRowParentProps {
  toggleList: () => void;
  items: IPosition[];
  open: boolean;
}

interface IAverage {
  value: number;
  profit: number;
  swap: number;
}

const RowParent: FC<IRowParentProps> = ({ items, toggleList, open }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const moneyFormat = useMoneyFormat();
  const { asset } = items[0];
  const { assets } = useAppSelector((state) => state.asset);
  const { isDemo } = useAppSelector((state) => state.account);
  const foundAsset = assets?.find((el) => el.id === asset.id);

  const average = items.reduce(
    (average: IAverage, position: IPosition) => {
      position.transaction_type === ETransactionType.buy
        ? (average.value += position.amount)
        : (average.value -= position.amount);
      average.profit += foundAsset
        ? (position.transaction_type === ETransactionType.buy
            ? foundAsset?.bid - position.open_price
            : position.open_price - foundAsset.ask) * position.amount
        : // * position.leverage
          0;
      average.swap += position.swap;
      return average;
    },
    { value: 0, profit: 0, swap: 0 }
  );

  const netProfit = average.profit - average.swap;
  const isSwap = items.some((el) => el.swap > 0.01);

  const handleCloseAllPositionsById = () => {
    // dispatch(
    //   closePositionProfitLoss({
    //     is_demo: isDemo,
    //     asset_id: asset.id,
    //   })
    // );
    dispatch(
      closePositionProfitLoss({
        is_demo: isDemo,
        is_close_profitable_positions: true,
        asset_id: asset.id,
      })
    );
    dispatch(
      closePositionProfitLoss({
        is_demo: isDemo,
        is_close_profitable_positions: false,
        asset_id: asset.id,
      })
    );
  };

  const handleCloseProfitLoss = (profit: boolean) => {
    dispatch(
      closePositionProfitLoss({
        is_demo: isDemo,
        is_close_profitable_positions: profit,
        asset_id: asset.id,
      })
    );
  };

  return (
    <div className={classNames('tr', { active: open })} onClick={toggleList}>
      <div
        className='td cursor--pointer'
        onClick={() => dispatch(setChartAssetKey(asset.widget_code))}
      >
        <div className='td-hidden-name'>{t('Table.order')}</div>
        <div className='position-info'>
          <p>
            {asset.code}{' '}
            <span className='symbol-info'>{asset.market.name}</span>
          </p>
          {location.pathname === ERoutes.trade && (
            <>
              <div className='position-info__number'>
                <p>{items.length}</p>
              </div>
              {isSwap && (
                <div className='position-info__status'>
                  <p>R</p>
                </div>
              )}
            </>
          )}
        </div>
        <span className='td-text-small'>
          {asset.name}{' '}
          {asset.market.name.toUpperCase() !== 'FX' && 'to American Dollar'}
        </span>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.type')}</div>
        {average.value > 0 && <p className='green'>{t('Order.buy')}</p>}
        {average.value < 0 && <p className='red'>{t('Order.sell')}</p>}
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.volume')}</div>
        <p>{String(average.value.toFixed(2)).replace('-', '')}</p>
      </div>
      <div className='td' />
      <div className='td' />
      <div className='td' />
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.market_price')}</div>
        <p>{average.value > 0 ? foundAsset?.bid : foundAsset?.ask}</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.profit')}</div>
        <p
          className={classNames({
            red: average.profit < 0,
            green: average.profit > 0,
          })}
        >
          {moneyFormat(average.profit, 2, 2)}
        </p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.net_profit')}</div>
        <p className={classNames({ red: netProfit < 0, green: netProfit > 0 })}>
          {moneyFormat(netProfit, 2, 2)}
        </p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>
          {t('Table.net')} <br /> P/L %
        </div>
        <p className='red'>-</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.rollover')}</div>
        <p>{average.swap > 0.01 ? moneyFormat(average.swap, 0, 2) : '-'}</p>
      </div>
      <div className='td td--right'>
        {open && (
          <ClosedDropdown
            closeAll={handleCloseAllPositionsById}
            closeProfitLoss={handleCloseProfitLoss}
          />
        )}
      </div>
    </div>
  );
};

export default RowParent;
