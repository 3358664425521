import { getAsset, getMarkets } from './asset-thunks';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAsset, AssetState, IMarket } from './asset-types';

const initialState: AssetState = {
  assets: null,
  minLot: 0.01,
  markets: [],
  chartAssetCode: null,
};

export const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    getAssetsSuccess: (state, { payload }: PayloadAction<IAsset[]>) => {
      state.assets = payload;
    },
    setChartAssetKey: (state, { payload }: PayloadAction<string>) => {
      state.chartAssetCode = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getMarkets.fulfilled,
        (state, { payload }: PayloadAction<IMarket[]>) => {
          state.markets = payload;
        }
      )
      .addCase(
        getAsset.fulfilled,
        (state, { payload }: PayloadAction<IAsset[]>) => {
          state.assets = payload;
        }
      );
  },
});

export const { getAssetsSuccess, setChartAssetKey } = assetSlice.actions;

export default assetSlice.reducer;
