import { FC, useRef, forwardRef, useEffect, useCallback } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ISelectCalendarProps } from './types';

import { ru, enGB, uk } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

registerLocale('ru', ru);
registerLocale('en', enGB);
registerLocale('ua', uk);

interface IProps {
  value: string;
  onClick: () => void;
}
export type Ref = HTMLButtonElement;

const SelectCalendar: FC<ISelectCalendarProps> = ({
  startDate,
  setStartDate,
  setCloseCalendar,
  ...rest
}) => {
  const { i18n } = useTranslation();
  const handleChangeDate = (date: Date) => {
    setStartDate(date);
    setCloseCalendar?.(false);
  };

  // eslint-disable-next-line react/display-name
  // const ReactDatePickerInput = forwardRef<
  //   HTMLInputElement,
  //   React.DetailedHTMLProps<
  //     React.InputHTMLAttributes<HTMLInputElement>,
  //     HTMLInputElement
  //   >
  // >((props, ref) => (
  //   <input
  //     type='text'
  //     // placeholder={String(t('Forms.Verification.date_of_birth'))}
  //     ref={ref}
  //     {...props}
  //     className='input-item input-item--pr125'
  //   />
  // ));

  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={handleChangeDate}
        locale={i18n.language}
        calendarClassName='calendar--dropdown'
        {...rest}
      />
    </>
  );
};

export default SelectCalendar;
