import { combineReducers } from '@reduxjs/toolkit';
import auth from './redux-toolkit/auth/auth-slice';
import settings from './redux-toolkit/settings/settings-slice';
import account from './redux-toolkit/account/account-slice';
import order from './redux-toolkit/order/order-slice';
import position from './redux-toolkit/position/position-slice';
import asset from './redux-toolkit/asset/asset-slice';
import sockets from './redux-toolkit/sockets/socket-slice';
import referral from './redux-toolkit/referral/referral-slice';
import popup from './redux-toolkit/popup/popup-slice';
import depositWithdrawal from './redux-toolkit/depositWithdrawal/depWith-slice';

export const rootReducer = combineReducers({
  auth,
  settings,
  account,
  order,
  position,
  asset,
  sockets,
  referral,
  popup,
  depositWithdrawal,
});
