import { createBrowserRouter, Navigate } from 'react-router-dom';
import ErrorRoutes from './ErrorRoutes';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import TradeRoutes from './TradeRoutes';

export const router = createBrowserRouter([
  PrivateRoutes,
  PublicRoutes,
  TradeRoutes,
  ErrorRoutes,
]);
