import { FC } from 'react';
import classNames from 'classnames';
import { ILoginForm, ILoginSubmitValue } from './types';
import { loginSchema } from '../../../form-validations/login-validation';
import { Link } from 'react-router-dom';
import Input from '../../../ui/Formik/Input';
import PasswordField from '../../../ui/Formik/PasswordField';
import { ERoutes, LoadingStatus } from '../../../enum/types';
import { useAppSelector } from 'hooks/redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { COMPANY_NAME } from '../../../services/constants/env';

const initialValues = {
  email: '',
  password: '',
  totp: '',
  captcha: '00000000',
};

export const LoginForm: FC<ILoginForm> = ({ loginSubmit }) => {
  const { t } = useTranslation();
  const { error, loadingState } = useAppSelector((store) => store.auth);

  const isPending = loadingState === LoadingStatus.LOADING;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={(values: ILoginSubmitValue) => {
        loginSubmit(values);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isValid }) => {
        return (
          <Form className='form'>
            <div
              className={classNames('authorization__form', {
                'input--error': error === 'user_not_registered',
              })}
            >
              <Field
                type='email'
                name='email'
                component={Input}
                placeholder={t('Forms.Register.email')}
              />
              <Field
                name='password'
                component={PasswordField}
                placeholder={t('Forms.Register.password')}
              />
              {error && error === 'user_not_registered' && (
                <p className='input-notification input-notification--error'>
                  {t('Login.not_registered', { company: COMPANY_NAME })}
                  <Link to={ERoutes.registration}>
                    {t('Registration.link')}
                  </Link>
                </p>
              )}
              <div className='authorization__options'>
                <Link to={ERoutes.forgotPass} className='link link--fw-500'>
                  {' '}
                  {t('ForgotPassword.link')}
                </Link>
              </div>
            </div>
            <div className='form-submit'>
              <button
                disabled={!isValid || isPending}
                type='submit'
                className='button button--size2'
              >
                {t('Login.btn')}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
