import { useAppDispatch } from 'hooks/redux';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';
import { APP_NAME } from '../../services/constants/env';

const TradingHistorysPage = () => {
  const appTitle = APP_NAME || '';
  const dispatch = useAppDispatch();

  return (
    <>
      <Helmet>
        <title>{`${appTitle} | Trading History`}</title>
      </Helmet>
      <Outlet />
    </>
  );
};

export default TradingHistorysPage;
