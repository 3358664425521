import { createAsyncThunk } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { endpoint } from 'services/endpoint';
import requestService from 'services/http/interceptor/request-service';
import { showError } from 'utils/showError';
import {
  IChartPositionsRequest,
  ICloseAllPositionsRequest,
  IClosePositionByParamsRequest,
  IClosePositionRequest,
  IDeleteTpSlRequest,
  IGetClosePositionsRequest,
  IOpenPositionsRequest,
  IUpdateTpSlRequest,
} from './position-types';

export const showOpenPositions = createAsyncThunk(
  'position/open-positions',
  async (params: IOpenPositionsRequest) => {
    try {
      const response = await requestService.get(
        endpoint.position.SHOW_OPEN_POSITIONS,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const showChartPositions = createAsyncThunk(
  'position/chart-positions',
  async (params: IChartPositionsRequest) => {
    try {
      const response = await requestService.get(
        endpoint.position.SHOW_OPEN_POSITIONS,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const showClosedPositions = createAsyncThunk(
  'position/closed-positions',
  async (params: IGetClosePositionsRequest) => {
    try {
      const response = await requestService.get(
        endpoint.position.SHOW_CLOSE_POSITIONS,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const updateTpSlPosition = createAsyncThunk(
  'position/update-tp-sl',
  async (params: IUpdateTpSlRequest, thunkApi) => {
    try {
      const response = await requestService.put(
        endpoint.position.UPDATE_TP_SL_POSITION,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const deleteTpSlPosition = createAsyncThunk(
  'position/delete-tp-sl',
  async (params: IDeleteTpSlRequest, thunkApi) => {
    try {
      const response = await requestService.put(
        endpoint.position.DELETE_TP_SL_POSITION,
        params
      );
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const closePosition = createAsyncThunk(
  'position/close',
  async (params: IClosePositionRequest, thunkApi) => {
    try {
      await requestService.put(endpoint.position.CLOSE_ONE_POSITION, params);
      thunkApi.dispatch(showOpenPositions({ is_demo: params.is_demo }));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const closePositionProfitLoss = createAsyncThunk(
  'position/close',
  async (params: IClosePositionByParamsRequest, thunkApi) => {
    try {
      await requestService.put(
        endpoint.position.CLOSE_POSITION_BY_PARAMS,
        params
      );
      thunkApi.dispatch(showOpenPositions({ is_demo: params.is_demo }));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);

export const closeAllPositions = createAsyncThunk(
  'position/close-all',
  async (params: ICloseAllPositionsRequest, thunkApi) => {
    try {
      await requestService.put(endpoint.position.CLOSE_ALL_POSITIONS, params);
      thunkApi.dispatch(showOpenPositions({ is_demo: params.is_demo }));
    } catch (error) {
      if (isAxiosError(error)) {
        showError(error);
      }
    }
  }
);
