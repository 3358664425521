import React, { FC } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IPosition } from 'store/redux-toolkit/position/position-types';
import { CloseTableIcon, InfoCircleIcon, PlusIcon } from 'assets/svg-icon';
import { ETransactionType } from 'enum/types';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  closePosition,
  deleteTpSlPosition,
} from 'store/redux-toolkit/position/position-thunks';
import { setChartAssetKey } from 'store/redux-toolkit/asset/asset-slice';
import { IAsset } from 'store/redux-toolkit/asset/asset-types';
import { addZeros } from 'utils/integers';
import { useMoneyFormat } from 'hooks/useMoneyFormat';

interface IRowChild {
  item: IPosition;
  childs?: number;
  openList: boolean;
  handleUpdateTpSl: (position: IPosition, type: ETransactionType) => void;
  showInfo: (position: IPosition) => void;
}

const RowChild: FC<IRowChild> = ({
  item,
  childs = 1,
  openList,
  handleUpdateTpSl,
  showInfo,
}) => {
  const {
    id,
    asset,
    asset: { market },
    amount,
    transaction_type,
    stop_loss,
    take_profit,
    open_price,
    open_price_usd,
    swap,
    leverage,
    volume,
  } = item;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const moneyFormat = useMoneyFormat();

  const { isDemo } = useAppSelector((state) => state.account);

  const { assets } = useAppSelector((state) => state.asset);
  const foundAsset = assets?.find((el) => el.id === asset.id);

  const getPrice = (_asset: IAsset | undefined) =>
    transaction_type === ETransactionType.buy
      ? Number(_asset?.bid)
      : Number(_asset?.ask);

  const getProfit = (_currentPrice: number) =>
    _currentPrice
      ? (transaction_type === ETransactionType.buy
          ? _currentPrice - open_price_usd
          : open_price_usd - _currentPrice) * volume
      : 0;

  const currentPrice = getPrice(foundAsset);
  let profit = getProfit(currentPrice);

  if (market.name === 'FX' && !asset.code.endsWith('USD')) {
    const assetCodeEnd = asset.code.slice(asset.code.length - 3);
    const externalCode = `${assetCodeEnd}USD`;
    const externalAsset = assets?.find((item) => item.code === externalCode);
    const externalPrice = getPrice(externalAsset);
    const fxCurrentPrice = currentPrice * externalPrice;
    profit = getProfit(fxCurrentPrice);
  }

  const netProfit = profit - swap;
  const marketPrice =
    transaction_type === ETransactionType.buy
      ? foundAsset?.bid
      : foundAsset?.ask;
  let plNet = 0;
  if (marketPrice) {
    const commission = market.fee;
    const openCloseDiff = open_price - marketPrice;
    const openClosePercentDiff = openCloseDiff / open_price;
    const openCloseProfit = leverage * openClosePercentDiff;
    plNet = openCloseProfit * leverage - commission;
  }

  const handleUpdateTp = () => {
    handleUpdateTpSl(item, ETransactionType.tp);
  };

  const handleUpdateSl = () => {
    handleUpdateTpSl(item, ETransactionType.sl);
  };

  const handleShowInfo = () => {
    showInfo(item);
  };

  const handleDeleteSlTp = (type: ETransactionType) => {
    dispatch(
      deleteTpSlPosition({
        is_demo: isDemo,
        position_id: id,
        is_delete_sl_order: type === ETransactionType.sl || undefined,
        is_delete_tp_order: type === ETransactionType.tp || undefined,
      })
    );
  };

  const handleClosePosition = () => {
    dispatch(
      closePosition({
        is_demo: isDemo,
        position_id: id,
      })
    );
  };

  if (!openList && childs > 1) {
    return null;
  }

  return (
    <div
      className={classNames('tr', {
        'tr-child': childs > 1,
        active: openList && childs > 1,
      })}
    >
      {childs && childs > 1 ? (
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.order')}</div>
          <div className='flex flex-m'>
            <span className='td-text-small'>{addZeros(id)}</span>
            <div className='tooltip-wrap tooltip-wrap--center-align'>
              <button
                type='button'
                className='tooltip-item'
                onClick={handleShowInfo}
              >
                <InfoCircleIcon />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className='td'>
          <div className='td-hidden-name'>{t('Table.order')}</div>
          <div className='position-info'>
            <p
              className='cursor--pointer'
              onClick={() => dispatch(setChartAssetKey(asset.widget_code))}
            >
              {asset.code} <span className='symbol-info'>{market.name}</span>
            </p>
          </div>
          <span className='td-text-small'>
            {asset.name}{' '}
            {market.name.toUpperCase() !== 'FX' && 'to American Dollar'}
          </span>
        </div>
      )}

      <div className='td'>
        <div className='td-hidden-name'>{t('Table.type')}</div>
        <p className={transaction_type}>{t(`Order.${transaction_type}`)}</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.volume')}</div>
        <p>{amount}</p>
      </div>

      <div className='td td--center'>
        <div className='td-hidden-name'>S/L</div>
        {stop_loss ? (
          <div className='table--group flex-m'>
            <button
              type='button'
              className='color--inherit'
              onClick={handleUpdateSl}
            >
              {stop_loss}
            </button>
            <button
              type='button'
              onClick={() => handleDeleteSlTp(ETransactionType.sl)}
            >
              <CloseTableIcon />
            </button>
          </div>
        ) : (
          <button
            type='button'
            className='button button--type-icon'
            onClick={handleUpdateSl}
          >
            <PlusIcon />
          </button>
        )}
      </div>
      <div className='td td--center'>
        <div className='td-hidden-name'>T/P</div>
        {take_profit ? (
          <div className='table--group flex-m'>
            <button
              type='button'
              className='color--inherit'
              onClick={handleUpdateTp}
            >
              {take_profit}
            </button>
            <button
              type='button'
              onClick={() => handleDeleteSlTp(ETransactionType.tp)}
            >
              <CloseTableIcon />
            </button>
          </div>
        ) : (
          <button
            type='button'
            className='button button--type-icon'
            onClick={handleUpdateTp}
          >
            <PlusIcon />
          </button>
        )}
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.opening_price')}</div>
        <p>{open_price}</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.market_price')}</div>
        <p>{marketPrice}</p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.profit')}</div>
        <p className={classNames({ red: profit < 0, green: profit > 0 })}>
          {moneyFormat(profit, 2, 2)}
        </p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.net_profit')}</div>
        <p className={classNames({ red: netProfit < 0, green: netProfit > 0 })}>
          {moneyFormat(netProfit, 2, 2)}
        </p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>
          {t('Table.net')} <br /> P/L %
        </div>
        <p className={classNames({ red: plNet < 0, green: plNet > 0 })}>
          {plNet ? moneyFormat(plNet, 2) : '-'}
        </p>
      </div>
      <div className='td'>
        <div className='td-hidden-name'>{t('Table.rollover')}</div>
        <p>{swap > 0.01 ? moneyFormat(swap, 0, 2) : '-'}</p>
      </div>
      <div className='td td--right'>
        <button
          type='button'
          className='green2 fw-700'
          onClick={handleClosePosition}
        >
          {t('Button.close')}
        </button>
      </div>
    </div>
  );
};

export default RowChild;
