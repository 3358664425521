import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import TablePlug from 'layouts-element/TablePlug';
import { useTranslation } from 'react-i18next';
import { getAllOrders } from 'store/redux-toolkit/order/order-thunks';
import OrderHistoryRow from './OrderHistoryRow';
import { useLocation } from 'react-router-dom';
import { EOrderStatus, ERoutes } from 'enum/types';
import CustomSelect from 'ui/CustomSelect';
import AssetsSelect from 'ui/AssetsSelect';
import Pagination from 'ui/Pagination';
import 'react-datepicker/dist/react-datepicker.css';
import SelectCalendarRange from 'ui/SelectCalendarRange';
import { format } from 'date-fns';

const OrdersHistoryTable = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { allOrders } = useAppSelector((state) => state.order);
  const { isDemo } = useAppSelector((state) => state.account);
  const { isAuth } = useAppSelector((state) => state.auth);

  const [status, setStatus] = useState<string>('');
  const [assetId, setAssetId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (isAuth) {
      dispatch(
        getAllOrders({
          is_demo: isDemo,
          status: status || undefined,
          asset_id: assetId || undefined,
          current_page: currentPage,
          per_page: 8,
          date_from: startDate ? format(startDate, 'y-MM-dd') : undefined,
          date_to: endDate ? format(endDate, 'y-MM-dd') : undefined,
        })
      );
    }
  }, [isDemo, status, assetId, currentPage, endDate]);

  useEffect(() => {
    setCurrentPage(1);
  }, [allOrders?.last_page]);

  return (
    <>
      <div
        className={`table-filter ${
          location.pathname === ERoutes.trade
            ? 'table-filter--mt-16 table-filter--mb-0'
            : ''
        }`}
      >
        <div className='select-block'>
          <SelectCalendarRange
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
          />
        </div>

        <CustomSelect
          obj={EOrderStatus}
          element={status}
          setElement={setStatus}
          placeholder={t('Table.all_status')}
        />

        <AssetsSelect setElement={setAssetId} />
      </div>
      <div
        className={`table ${
          location.pathname === ERoutes.trade
            ? 'table--height-type2 table--type2 table--size3 table--black table--market table--market-history-orders'
            : 'table--order-history'
        }`}
      >
        <div className='table-header'>
          <div className='tr'>
            <div className='td'>
              <p>{t('Table.order')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.time')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.simbol')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.status')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.volume')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.closed_price')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.type')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.margin')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.sistem_comment')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.id_position')}</p>
            </div>
            <div className='td'>
              <p>{t('Table.time_complet')}</p>
            </div>
            <div className='td td--right'>
              <p>{t('Table.expiration')}</p>
            </div>
          </div>
        </div>
        <div className='table-body'>
          {isAuth ? (
            allOrders &&
            allOrders.data.map((order) => (
              <OrderHistoryRow key={order.id} {...order} />
            ))
          ) : (
            <TablePlug />
          )}
        </div>
      </div>
      {allOrders && allOrders.last_page > 1 && (
        <Pagination
          pageCount={allOrders?.last_page}
          forcePage={allOrders.current_page}
          onPageChange={setCurrentPage}
        />
      )}
    </>
  );
};

export default OrdersHistoryTable;
