import { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './routes';
import './languages/i18n';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { startConnecting } from 'store/redux-toolkit/sockets/socket-slice';
import { ToastContainer } from 'react-toastify';
import SocketWrapper from 'ui/SocketWrapper';

export function App() {
  const dispatch = useAppDispatch();
  const { theme } = useAppSelector((state) => state.settings);

  useEffect(() => {
    dispatch(startConnecting());
  }, []);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <SocketWrapper>
          <RouterProvider router={router} />
        </SocketWrapper>
      </Suspense>
      <ToastContainer theme={theme} />
    </>
  );
}
