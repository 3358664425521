import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { BigCheckIcon, CloseIcon } from 'assets/svg-icon';
import { popupInitState } from 'store/redux-toolkit/popup/popup-slice';
import { Popup } from 'reactjs-popup';

const PopUp = () => {
  const dispatch = useAppDispatch();
  const { type, text } = useAppSelector((state) => state.popup);

  const closeModal = () => {
    dispatch(popupInitState());
  };

  return (
    <Popup
      open={!!text}
      closeOnDocumentClick
      onClose={closeModal}
      closeOnEscape
      lockScroll
    >
      <div className='popup-window__inside'>
        <div className='popup popup--width-400'>
          <button type='button' onClick={closeModal} className='popup__close'>
            <CloseIcon />
          </button>
          <div className='popup-body popup-body--mt-0 popup-body--mb-16'>
            <div className='form-icon'>
              <BigCheckIcon />
            </div>
          </div>
          <div className='popup-header'>
            <p className='popup-header__title'>{text}</p>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default PopUp;
