import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import InputNum from 'ui/InputNum';
import { ETransactionType } from 'enum/types';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';
import { IPosition } from 'store/redux-toolkit/position/position-types';
import { updateTpSlPosition } from 'store/redux-toolkit/position/position-thunks';
import { addZeros } from 'utils/integers';
import { useTranslation } from 'react-i18next';

interface IUpdateTpSl {
  position: IPosition;
  type: ETransactionType;
}

const UpdatePositionTpSl: FC<IUpdateTpSl & IPopupProps> = ({
  position,
  type,
  openModal,
  setOpenModal,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isDemo } = useAppSelector((state) => state.account);
  const { stop_loss, take_profit, asset, id } = position;
  const [takeProfit, setTakeProfit] = useState<number>(0);
  const [stopLoss, setStopLoss] = useState<number>(0);
  const { assets } = useAppSelector((state) => state.asset);
  const curAsset = assets?.find((el) => el.id === asset.id);

  const closeModal = () => {
    setOpenModal(false);
  };

  const sendUpdateSlTp = () => {
    if (position) {
      dispatch(
        updateTpSlPosition({
          is_demo: isDemo,
          position_id: id,
          stop_loss: type === ETransactionType.sl ? stopLoss : undefined,
          take_profit: type === ETransactionType.tp ? takeProfit : undefined,
        })
      );
      closeModal();
    }
  };

  return (
    <ModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      customClass='popup--width-370'
    >
      <div className='popup-header'>
        <div className='popup-text popup-text--type3 popup-text--ta-l'>
          <p>{`${t('Order.change_position')} #${addZeros(position.id)}`}</p>
        </div>
      </div>
      <div className='popup-body'>
        {type === ETransactionType.tp ? (
          <InputNum
            value={String(take_profit || curAsset?.ask)}
            changeCount={setTakeProfit}
            title={type}
          />
        ) : (
          <InputNum
            value={String(stop_loss || curAsset?.bid)}
            changeCount={setStopLoss}
            title={type}
          />
        )}
      </div>
      <div className='popup-footer'>
        <div className='popup-button'>
          <button
            type='button'
            className='button button--min-width-auto button--type3 button--full-width'
            onClick={closeModal}
          >
            {t('Button.cancel')}
          </button>
          <button
            type='button'
            className='button button--min-width-auto button--type4 button--full-width'
            onClick={sendUpdateSlTp}
          >
            {t('Button.add')}
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UpdatePositionTpSl;
