export enum LoadingStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NEVER = 'NEVER',
  SUCCESS = 'SUCCESS',
}

export enum ERoutes {
  home = '/',
  login = '/login',
  registration = '/signup',
  emailConfirm = '/email-confirm',
  forgotPass = '/create-new-password',
  //Privat routes
  account = '/account',
  generalSettings = '/general-settings',
  deposit = '/deposit',
  depositHistory = 'deposit-history',
  withdrawHistory = 'withdraw-history',
  transactionHistory = 'transfer-history',
  withdrawCrypto = '/withdraw-crypto',
  referrals = '/account-referral',
  notifications = '/notifications',
  //trade history
  tradeHistory = '/trade-history',
  history = 'trade',
  openOrders = 'open-orders',
  ordersHistory = 'orders-history',
  cashTransaction = 'cash-history',
  //trade
  trade = '/',
}

export enum EOrderType {
  market = 'market',
}

export enum ETransactionType {
  buy = 'buy',
  sell = 'sell',
  tp = 'T/P',
  sl = 'S/L',
}

export enum EOrderStatus {
  open = 'open',
  filled = 'filled',
  expired = 'expired',
  canceled = 'canceled',
}

export enum EMarket {
  fx = 'FX',
  stc = 'STC',
  ind = 'IND',
  cmd = 'CMD',
  crt = 'CRT',
  fav = 'FAV',
}

export enum ETableTab {
  open = 'open',
  pending = 'pending',
  history_orders = 'history_orders',
  trade_history = 'trade_history',
}

export enum ESocketEvent {
  markets = 'markets',
  orders = 'open-orders',
  positions = 'open-positions',
  userTradeData = 'user-trade-data',
}

export enum ETypePopup {
  success = 'success',
  error = 'error',
  info = 'info',
}

export enum EStatus {
  completed = 'completed',
  sent = 'sent',
}

export enum ETimePeriod {
  week = '7',
  month = '30',
  quarter = '90',
}

export enum ETransactionStatus {
  waiting = 'waiting',
  success = 'success',
  failed = 'failed',
  expired = 'expired',
  new = 'new',
}

export enum ETransactionWithdrawStatus {
  approved = 'Successful',
  new = 'New',
  canceled = 'Canceled',
}

export enum ECashTransactionType {
  realized_pnl = 'realized_pnl',
  swap = 'swap',
}

export enum ENameNotify {
  liquidationPosition = 'liquidation_position_notify',
}
