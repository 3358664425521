import { FC, useRef, useState } from 'react';
import { ERoutes } from 'enum/types';
import { useAppSelector } from 'hooks/redux';

import { useTranslation } from 'react-i18next';
import { IAsset } from 'store/redux-toolkit/asset/asset-types';
import { ArrowDropIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';

interface IAssetsSelectProps {
  setElement: (id: number | null) => void;
}

const AssetsSelect: FC<IAssetsSelectProps> = ({ setElement }) => {
  const { t } = useTranslation();
  const { assets } = useAppSelector((state) => state.asset);
  const [asset, setAsset] = useState<IAsset | null>(null);
  const [open, setOpen] = useState(false);
  const placeholder = t('Table.all_simbols');

  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const handleChange = (el: IAsset | null) => {
    setAsset(el);
    setElement(el?.id || null);
    setOpen(false);
  };

  return (
    <div className='select-block'>
      <div
        ref={ref}
        className={`select ${
          location.pathname === ERoutes.trade
            ? 'select--type5 select--black'
            : 'select--type3'
        }${open ? ' active' : ''}`}
      >
        <button type='button' className='select__current' onClick={handleClick}>
          {asset?.code || placeholder}
          <span className='select__current-arrow'>
            <ArrowDropIcon />
          </span>
        </button>
        {assets && (
          <div className='select__drop'>
            <div className='select__drop-scroll'>
              <div className='select__drop-item'>
                <ul>
                  <li>
                    <button type='button' onClick={() => handleChange(null)}>
                      {placeholder}
                    </button>
                  </li>
                  {assets.map((el) => (
                    <li key={el.id}>
                      <button type='button' onClick={() => handleChange(el)}>
                        {el.code}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetsSelect;
