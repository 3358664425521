import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ETypePopup } from 'enum/types';
import { IPopupPayload, PopupState } from './popup-types';

const initialState: PopupState = {
  type: ETypePopup.success,
  text: '',
};

const popup = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    popupOpen: (state, { payload }: PayloadAction<IPopupPayload>) => {
      state.text = payload.text;
      if (payload.type) state.type = payload.type;
    },
    popupInitState: () => initialState,
  },
});

export default popup.reducer;
export const { popupOpen, popupInitState } = popup.actions;
