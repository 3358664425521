import React, { useEffect } from 'react';

import Header from 'layouts-element/Header';
import { Outlet } from 'react-router-dom';
import TradeTopLine from 'components/TradeTopLine';
import { useAppDispatch } from 'hooks/redux';
import { setTheme } from 'store/redux-toolkit/settings/settings-slice';

const TradeLayout = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(setTheme('dark'));
    return () => {
      dispatch(setTheme('light'));
    };
  }, []);
  return (
    <div className='wrapper'>
      <Header addClass='header--type2' />
      <main className='main main--dark'>
        <TradeTopLine />
        <section className='market-section'>
          <Outlet />
        </section>
      </main>
    </div>
  );
};

export default TradeLayout;
