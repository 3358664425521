import i18n from 'i18next';

export const useMoneyFormat =
  () =>
  (
    value: number | string | null | undefined,
    minDecimals?: number,
    maxDecimals?: number,
    compact?: boolean
  ) => {
    if (value) {
      const money = new Intl.NumberFormat(i18n.language, {
        minimumFractionDigits: minDecimals || 0,
        maximumFractionDigits: maxDecimals || 2,
        notation: compact ? 'compact' : 'standard',
        compactDisplay: compact ? 'short' : 'long',
      });

      const valueNum = Number(value);

      return money.format(valueNum).toLowerCase();
    }
    return 0;
  };
