import { FC, useState } from 'react';
import { CalendarIcon, ClockIcon, CloseIcon } from 'assets/svg-icon';
import { IOrder } from 'store/redux-toolkit/order/order-types';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { updateDateExpirationOrder } from 'store/redux-toolkit/order/order-thunks';
import ModalWrapper, { IPopupProps } from 'ui/ModalWrapper';
import { addZeros } from 'utils/integers';
import { useTranslation } from 'react-i18next';
import SelectCalendar from 'ui/SelectCalendar';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ERoutes } from 'enum/types';

interface IUpdateExpirationProps {
  order: IOrder;
}

const UpdateExpiration: FC<IUpdateExpirationProps & IPopupProps> = ({
  order,
  openModal,
  setOpenModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isDemo } = useAppSelector((state) => state.account);
  const { date_expiration, id } = order;
  const [startDate, setStartDate] = useState<Date>(
    date_expiration ? new Date(date_expiration) : new Date()
  );
  const [openCalendar, setOpenCalendar] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleOpenCalendar = () => {
    setOpenCalendar(true);
  };

  const sendUpdateDate = () => {
    if (order) {
      dispatch(
        updateDateExpirationOrder({
          is_demo: isDemo,
          main_order_id: id,
          date_expiration: format(startDate, 'yyyy-MM-d HH:mm:ss'),
        })
      );
      closeModal();
    }
  };

  return (
    <ModalWrapper
      openModal={openModal}
      setOpenModal={setOpenModal}
      customClass='popup--width-490'
    >
      <div className='popup-header'>
        <div className='popup-text popup-text--type3 popup-text--ta-l'>
          <p>{`${t('Order.change_position')} #${addZeros(order.id)}`}</p>
        </div>
      </div>
      <p className='popup-header__title popup-header__title--type2'>
        {t('Order.date_expired')}
      </p>
      <div className='popup-body'>
        <div className='input-line'>
          <div
            className={classNames('input input--size3 input--mb-0', {
              'input--black': location.pathname === ERoutes.trade,
            })}
          >
            <div className='input-wrapper'>
              <input
                className='input-item'
                type='text'
                placeholder='date'
                readOnly
                onClick={handleOpenCalendar}
                value={format(startDate, 'd / MM / yyyy')}
              />

              {openCalendar && (
                <SelectCalendar
                  startDate={startDate}
                  setStartDate={setStartDate}
                  setCloseCalendar={setOpenCalendar}
                  minDate={new Date()}
                  inline
                  showTimeSelect
                />
              )}
              <div className='input-icon input-icon--right'>
                <CalendarIcon />
              </div>
            </div>
          </div>
          <div
            className={classNames('input input--size3 input--mb-0', {
              'input--black': location.pathname === ERoutes.trade,
            })}
          >
            <div className='input-wrapper'>
              <input
                className='input-item'
                type='text'
                placeholder='time'
                readOnly
                onClick={handleOpenCalendar}
                value={format(startDate, 'hh : mm : ss')}
              />
              <div className='input-icon input-icon--right'>
                <ClockIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='popup-footer'>
        <div className='popup-button'>
          <button
            type='button'
            className='button button--type3 button--size2 button--full-width'
            onClick={closeModal}
          >
            {t('Button.cancel')}
          </button>
          <button
            type='button'
            className='button button--full-width button--size2'
            onClick={sendUpdateDate}
          >
            {t('Button.add')}
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UpdateExpiration;
