import React, { FC } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { ru, enGB, uk } from 'date-fns/locale';
import { CalendarIcon } from 'assets/svg-icon';
import { useTranslation } from 'react-i18next';
import { ERoutes } from 'enum/types';
import { useLocation } from 'react-router-dom';

registerLocale('ru', ru);
registerLocale('en', enGB);
registerLocale('ua', uk);

interface ISelectCalendarProps {
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
}

const SelectCalendarRange: FC<ISelectCalendarProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const handleChangeDate = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div
      className={`input ${
        location.pathname === ERoutes.trade ? 'input--size5' : 'input--size2'
      }`}
    >
      <div className='input-wrapper'>
        <DatePicker
          selectsRange
          startDate={startDate}
          endDate={endDate}
          dateFormat='yyyy.MM.dd'
          onChange={handleChangeDate}
          maxDate={new Date()}
          isClearable
          placeholderText={t('Table.from_to') || ''}
          className={`input-item ${
            location.pathname === ERoutes.trade
              ? 'select--type5 select--black'
              : ''
          }`}
          locale={i18n.language}
        />
        {!startDate && !endDate && (
          <div className='input-icon input-icon--right'>
            <CalendarIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectCalendarRange;
