import { Field, Form, Formik } from 'formik';
import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { resetSchema } from '../../../form-validations/login-validation';
import Input from '../../../ui/Formik/Input';

import { IForgotPassFormProps } from './types';
import openedImage from '../../../assets/images/opened.png';
// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from 'react-google-recaptcha';
import ModalWrapper from 'ui/ModalWrapper';

const ForgotPassForm: FC<IForgotPassFormProps> = ({ forgotPassSubmit }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <p className='authorization__title '>{t('ForgotPassword.title')}</p>
      <div className='authorization__details'>
        <p>{t('ForgotPassword.text')}</p>
      </div>

      <Formik
        initialValues={{ email: '', captcha: '' }}
        validationSchema={resetSchema}
        onSubmit={(values, { setSubmitting }) => {
          forgotPassSubmit(values);
          setSubmitting(false);
          setOpenModal(true);
        }}
        validateOnBlur
        enableReinitialize
      >
        {({ isSubmitting, isValid, setFieldValue, values }) => {
          return (
            <>
              <Form className='authorization__form'>
                <Field
                  type='email'
                  name='email'
                  component={Input}
                  placeholder={t('Forms.Register.email')}
                />
                <div className='authorization__options '>
                  <ReCAPTCHA
                    style={{ display: 'inline-block' }}
                    sitekey='6LddGigpAAAAAMvn6gwu0YAZCZ47LlJeQRN5f9Y5'
                    onChange={(response: any) => {
                      setFieldValue('captcha', '00000000');
                    }}
                  />
                </div>
                <div className='form-submit'>
                  <button
                    type='submit'
                    className='button button--size2'
                    disabled={!isValid || isSubmitting}
                  >
                    {t('Button.next')}
                  </button>
                </div>
              </Form>
              <ModalWrapper
                openModal={openModal}
                setOpenModal={handleCloseModal}
              >
                <div className='popup-body'>
                  <div className='email-sent-icon'>
                    <img src={openedImage} alt='' />
                  </div>
                  <p className='email-sent-text'>
                    {t('ForgotPassword.follow_this_link', {
                      email: values.email,
                    })}
                  </p>
                </div>
              </ModalWrapper>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default ForgotPassForm;
