import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ERoutes } from '../../../../enum/types';
import OpenPositionTable from './OpenPositionTable';

const OpenPositionsContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='content-title'>
        <p>{t('Page.TradeHistory.open_orders_title')}</p>
      </div>

      <div className='table-filter'>
        <Link
          to={`${ERoutes.tradeHistory}/${ERoutes.openOrders}/open`}
          className='button button--size4 '
        >
          {t('Page.TradeHistory.open_position_title')}
        </Link>
        <Link
          to={`${ERoutes.tradeHistory}/${ERoutes.openOrders}/pending`}
          className='button button--size4 button--type3'
        >
          {t('Page.TradeHistory.pending_orders')}
        </Link>
      </div>
      <OpenPositionTable />
    </>
  );
};

export default OpenPositionsContent;
