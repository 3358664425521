import { FieldProps } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomInputProps {
  type?: string;
}

const Input: FC<CustomInputProps & FieldProps> = ({
  field,
  form: { touched, errors },
  type = 'text',
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div className='input'>
      <div className='input-wrapper'>
        <input type={type} {...field} {...props} className='input-item' />
        {touched[field.name] && errors[field.name] && (
          <p className='input-notification--error'>
            {t(String(errors[field.name]))}
          </p>
        )}
      </div>
    </div>
  );
};

export default Input;
