import { lazy } from 'react';

import { ERoutes } from 'enum/types';
import TradeLayout from 'layouts/TradeLayout';

const TradePage = lazy(() => import('pages/TradePage'));

const TradeRoutes = {
  path: ERoutes.home,
  element: <TradeLayout />,
  children: [
    {
      element: <TradePage />,
      index: true,
    },
  ],
};

export default TradeRoutes;
