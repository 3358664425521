import { t } from 'i18next';
import { NotificationContainer } from 'ui/notificationContainer';
import { ICreateOrderResponse } from './order-types';

export const pendingOrderSuccess = (
  response: ICreateOrderResponse,
  assetCode: string | undefined
) => {
  const {
    main_order: { status, transaction_type, amount, trigger_price },
  } = response;

  const texts = {
    type: transaction_type.toUpperCase(),
    asset: assetCode,
    lot: amount,
    price: trigger_price,
  };
  if (status === 'filled') {
    NotificationContainer(t('Trade.create_position', texts), 'default');
  } else if (status === 'open') {
    NotificationContainer(t('Trade.create_order', texts), 'default');
  }
};
