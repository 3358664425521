import {
  IOrder,
  OrderState,
  IOpenOrdersResponse,
  IAllOrdersResponse,
} from 'store/redux-toolkit/order/order-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getOpenOrders,
  updateTpSlOrder,
  getAllOrders,
  deleteTpSlOrder,
  updateDateExpirationOrder,
} from './order-thunks';

const initialState: OrderState = {
  allOrders: null,
  openOrders: null,
};

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    updateOpenOrdersList: (
      state,
      { payload }: PayloadAction<IOpenOrdersResponse>
    ) => {
      state.openOrders = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getOpenOrders.fulfilled,
        (state, { payload }: PayloadAction<IOpenOrdersResponse>) => {
          state.openOrders = payload;
        }
      )
      .addCase(
        getAllOrders.fulfilled,
        (state, { payload }: PayloadAction<IAllOrdersResponse>) => {
          state.allOrders = payload;
        }
      )
      .addCase(
        updateTpSlOrder.fulfilled,
        (state, { payload }: PayloadAction<IOrder>) => {
          const needState = state.openOrders;
          if (needState) {
            Object.entries(needState).map(([key, value]) => {
              const newArr = value.map((el) =>
                el.id === payload.id ? { ...el, ...payload } : el
              );
              needState[key] = newArr;
            });
          }
          state.openOrders = needState;
        }
      )
      .addCase(
        deleteTpSlOrder.fulfilled,
        (state, { payload }: PayloadAction<IOrder>) => {
          const needState = state.openOrders;
          if (needState) {
            Object.entries(needState).map(([key, value]) => {
              const newArr = value.map((el) =>
                el.id === payload.id ? { ...el, ...payload } : el
              );
              needState[key] = newArr;
            });
          }
          state.openOrders = needState;
        }
      )
      .addCase(
        updateDateExpirationOrder.fulfilled,
        (state, { payload }: PayloadAction<IOrder>) => {
          const needState = state.openOrders;
          if (needState) {
            Object.entries(needState).map(([key, value]) => {
              const newArr = value.map((el) =>
                el.id === payload.id ? { ...el, ...payload } : el
              );
              needState[key] = newArr;
            });
          }
          state.openOrders = needState;
        }
      );
  },
});

export const { updateOpenOrdersList } = orderSlice.actions;
export default orderSlice.reducer;
