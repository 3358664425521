import { FC, useRef, useState } from 'react';
import { ERoutes } from 'enum/types';

import { useTranslation } from 'react-i18next';
import { ArrowDropIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ICustomSelectProps } from './types';

const CustomSelect: FC<ICustomSelectProps> = ({
  obj,
  element,
  setElement,
  placeholder,
  label,
  errorMsg,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const list = Object.keys(obj);
  const handleChange = (el: string) => {
    setElement(el);
    setOpen(false);
  };

  return (
    <div className='select-block'>
      {label && <p className='select-block__name'>{label}</p>}
      <div
        ref={ref}
        className={`select ${
          location.pathname === ERoutes.trade
            ? 'select--type5 select--black'
            : 'select--type3'
        }${open ? ' active' : ''}`}
      >
        <button type='button' className='select__current' onClick={handleClick}>
          {element ? t(`Select.${element}`) : placeholder}
          <span className='select__current-arrow'>
            <ArrowDropIcon />
          </span>
        </button>
        {errorMsg && <p className='input-notification--error'>{errorMsg}</p>}
        {list && (
          <div className='select__drop'>
            <div className='select__drop-scroll'>
              <div className='select__drop-item'>
                <ul>
                  <li>
                    <button type='button' onClick={() => handleChange('')}>
                      {placeholder}
                    </button>
                  </li>
                  {list.map((el) => (
                    <li key={el}>
                      <button type='button' onClick={() => handleChange(el)}>
                        {t(`Select.${el}`)}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
