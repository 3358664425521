import classNames from 'classnames';
import { ERoutes } from 'enum/types';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useMoneyFormat } from 'hooks/useMoneyFormat';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { setDemo } from 'store/redux-toolkit/account/account-slice';
import { getAccountData } from 'store/redux-toolkit/account/account-thuks';
import { ArrowDropIcon, PowerForkIcon, PowerIcon } from '../../assets/svg-icon';
import useOutsideClick from '../../hooks/useOutsideClick';
import { ISettingDropdown } from './types';
import { getSettingBySlug } from 'store/redux-toolkit/settings/setting-thunks';

import { logout } from '../../store/redux-toolkit/auth/auth-thunks';

const SettingDropdown: FC<ISettingDropdown> = ({
  closeMobileMenu,
  hideDemo,
}) => {
  const dispatch = useAppDispatch();
  const { isDemo, accountData, tradeData } = useAppSelector(
    (state) => state.account
  );
  const { t } = useTranslation();
  const moneyFormat = useMoneyFormat();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const { settingList } = useAppSelector((state) => state.settings);
  const ref = useRef<HTMLButtonElement | null>(null);
  useOutsideClick(ref, () => setOpen(false));
  const handleClick = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    dispatch(logout());
    closeMobileMenu();
  };

  const toggleDemo = (val: 0 | 1) => {
    dispatch(setDemo(val));
  };

  useEffect(() => {
    dispatch(getAccountData({ is_demo: isDemo }));
    setInterval(() => {
      dispatch(getAccountData({ is_demo: isDemo }));
    }, 30000);
  }, [isDemo]);

  useEffect(() => {
    dispatch(getSettingBySlug({ slug: 'demo-account' }));
  }, []);

  let showDemo = false;
  if (settingList) {
    try {
      showDemo = !!settingList[0]?.is_active;
    } catch (e) {
      showDemo = true;
    }
  }

  return (
    <div className={classNames('account-select', { active: open })}>
      <button
        ref={ref}
        className='account-select__current'
        onClick={handleClick}
      >
        <span className='account-select__current-status'>
          {isDemo ? 'DEMO' : 'Live'}
        </span>
        <span className='account-select__current-value'>
          {moneyFormat(accountData?.total_balance, 0, 2)}
        </span>
        <span className='account-select__current-value-currency'>USD</span>

        <span className='account-select__current-arrow'>
          <ArrowDropIcon />
        </span>
      </button>
      <div className='dropdown dropdown--account '>
        <ul className='dropdown-list'>
          {!hideDemo && showDemo && (
            <>
              <li>
                <button
                  type='button'
                  onClick={() => {
                    toggleDemo(1);
                    closeMobileMenu();
                  }}
                >
                  <span className='drop-demo'>DEMO</span>
                  {/* {moneyFormat(100000, 0, 2)}
                <span className='drop-extra-text'>USD</span> */}
                </button>
              </li>
              <li>
                <button
                  type='button'
                  onClick={() => {
                    toggleDemo(0);
                    closeMobileMenu();
                  }}
                >
                  <span className='drop-icon'>
                    <PowerForkIcon />
                  </span>
                  Live
                  {/* <span className='drop-extra-text'>
                {moneyFormat(0, 0, 2)} USD
              </span> */}
                </button>
              </li>
            </>
          )}
          <li>
            <NavLink
              to={ERoutes.account}
              target={pathname === '/' ? '_blank' : ''}
              onClick={closeMobileMenu}
            >
              <span className='drop-icon'>
                <svg
                  width='16'
                  height='17'
                  viewBox='0 0 16 17'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10.3449 1.40008C10.5841 1.26201 10.8899 1.34395 11.0279 1.58309L12.5654 4.24606C12.6548 4.40084 12.6547 4.59156 12.5653 4.74629C12.4758 4.90103 12.3106 4.99625 12.1319 4.99606L5.99352 4.98939C5.76732 4.98915 5.56946 4.83706 5.51103 4.61854C5.4526 4.40001 5.54817 4.16948 5.74406 4.05638L10.3449 1.40008ZM7.85659 3.99141L11.2658 3.99512L10.4119 2.5161L7.85659 3.99141Z'
                    fill='#1D92A6'
                  ></path>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2.00004 5C1.90799 5 1.83337 5.07461 1.83337 5.16667V14.5C1.83337 14.5921 1.90799 14.6667 2.00004 14.6667H14C14.0921 14.6667 14.1667 14.5921 14.1667 14.5V5.16667C14.1667 5.07461 14.0921 5 14 5H2.00004ZM0.833374 5.16667C0.833374 4.52232 1.35571 4 2.00004 4H14C14.6444 4 15.1667 4.52232 15.1667 5.16667V14.5C15.1667 15.1443 14.6444 15.6667 14 15.6667H2.00004C1.35571 15.6667 0.833374 15.1443 0.833374 14.5V5.16667Z'
                    fill='#1D92A6'
                  ></path>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M9.5 9.83366C9.5 8.61434 10.5306 7.66699 11.75 7.66699H14.6667C14.9428 7.66699 15.1667 7.89085 15.1667 8.16699V11.5003C15.1667 11.7765 14.9428 12.0003 14.6667 12.0003H11.75C10.5306 12.0003 9.5 11.053 9.5 9.83366ZM11.75 8.66699C11.0364 8.66699 10.5 9.21204 10.5 9.83366C10.5 10.4553 11.0364 11.0003 11.75 11.0003H14.1667V8.66699H11.75Z'
                    fill='#1D92A6'
                  ></path>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.6666 5.5C14.9428 5.5 15.1666 5.72386 15.1666 6V14C15.1666 14.2761 14.9428 14.5 14.6666 14.5C14.3905 14.5 14.1666 14.2761 14.1666 14V6C14.1666 5.72386 14.3905 5.5 14.6666 5.5Z'
                    fill='#1D92A6'
                  ></path>
                </svg>
              </span>
              {t('Page.Account.title')}
            </NavLink>
          </li>
          <li>
            <NavLink to={ERoutes.generalSettings} onClick={closeMobileMenu}>
              <span className='drop-icon'>
                <svg
                  width='16'
                  height='17'
                  viewBox='0 0 16 17'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M4.23581 2.57949C4.32564 2.42678 4.48959 2.33301 4.66677 2.33301H11.3335C11.5107 2.33301 11.6746 2.42678 11.7644 2.5795L15.0977 8.24617C15.1897 8.40264 15.1897 8.59671 15.0977 8.75318L11.7644 14.4198C11.6746 14.5726 11.5107 14.6663 11.3335 14.6663H4.66677C4.48959 14.6663 4.32564 14.5726 4.23581 14.4199L0.902409 8.75319C0.810362 8.59671 0.810362 8.40264 0.902409 8.24616L4.23581 2.57949ZM4.95274 3.33301L1.91347 8.49967L4.95274 13.6663H11.0475L14.0866 8.49967L11.0475 3.33301H4.95274Z'
                    fill='#1D92A6'
                  ></path>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M5.83337 8.49967C5.83337 7.30307 6.80343 6.33301 8.00004 6.33301C9.19665 6.33301 10.1667 7.30307 10.1667 8.49967C10.1667 9.69628 9.19665 10.6663 8.00004 10.6663C6.80343 10.6663 5.83337 9.69628 5.83337 8.49967ZM8.00004 7.33301C7.35572 7.33301 6.83337 7.85535 6.83337 8.49967C6.83337 9.144 7.35572 9.66634 8.00004 9.66634C8.64437 9.66634 9.16671 9.144 9.16671 8.49967C9.16671 7.85535 8.64437 7.33301 8.00004 7.33301Z'
                    fill='#1D92A6'
                  ></path>
                </svg>
              </span>
              {t('Page.General.menu_title')}
            </NavLink>
          </li>
          <li>
            <button type='button' onClick={handleLogout}>
              <span className='drop-icon'>
                <PowerIcon />
              </span>
              {t('Login.log_out')}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SettingDropdown;
